import MatchModel from '../models/MatchModel';
import MatchUserModel from "../models/MatchUserModel";
import Config from "../constants/Config";
import SkillsModel from "../models/SkillsModel";
import type {BotType} from "../typedefs/BotType";
import Bot from "../helpers/Bot";
import GameConstants from "../constants/GameConstants";
import UserModel from '../models/UserModel';
import {MatchService} from "./MatchService";

export default class LocalMatchService implements MatchService {

    // hold match and ping in local state
    match: MatchModel = MatchModel.createFromObject(GameConstants.ASYNC_RACE_MATCH);
    lastPings: { homeUser: number, guestUser: number } = {homeUser: Date.now(), guestUser: Date.now()};

    // handlers
    matchChangeHandler: (match: MatchModel) => mixed = () => {
    };
    lastPingChangeHandler: (lastPings: { homeUser: number, guestUser: number }) => mixed = () => {
    };

    updateMatch = async (match: MatchModel) => {
        this.match = MatchModel.getByValue(match);
        this.callMatchChangeHandler();
    };

    addMatchChangeHandler = (matchChangeHandler: (match: MatchModel) => mixed) => {
        this.matchChangeHandler = matchChangeHandler;
    };

    removeMatchChangeHandler = () => {
        this.matchChangeHandler = () => {};
    };

    callMatchChangeHandler = () => {
        this.matchChangeHandler(this.match);
    };

    addLastPingChangeHandler = (lastPingsChangeHandler: (lastPings: { homeUser: number, guestUser: number }) => mixed) => {
        this.lastPingChangeHandler = lastPingsChangeHandler;
    };

    callLastPingsChangeHandler = () => {
        this.lastPingChangeHandler(this.lastPings);
    };

    setLastPing = async (userType: string): Promise<void> => {
        let lastPings = JSON.parse(JSON.stringify(this.lastPings));
        lastPings[userType] = Date.now();
        this.lastPings = lastPings;
        this.callLastPingsChangeHandler();
    };

    joinMatch = async (user: UserModel): Promise<{ user: UserModel, match: MatchModel }> => {
        // create new match
        let homeUser: MatchUserModel = MatchUserModel.createFromParams(0, 0, 0, user.getLocale(), 0, 0, SkillsModel.createFromParams(0, 0), 0, Config.TITLE, user.getCharacter(), "", user.getName(), 0, 0, 0, 0, false, [], false);
        let bot: BotType = Bot.createRandom();
        let guestUser: MatchUserModel = MatchUserModel.createFromParams(0, 0, bot.fitness, Config.LANGUAGE, 0, 0, SkillsModel.createFromParams(bot.gainedPoints, bot.totalPoints), 0, Config.TITLE, bot.trikot, "", bot.name, bot.score, 0, 0, 0, false, [], true);
        let match = MatchModel.createFromParams(0, guestUser, homeUser, "", GameConstants.MATCH_VERSION);

        // store match locally and call change handler
        this.updateMatch(match);

        // return user and match
        return {user: user, match: match};
    };

    syncMatch = async (match: MatchModel, userType: string, opponentType: string, timeToEndMatch: number): Promise<MatchModel> => {
        // check if match already ended
        if (match.getUserByType(userType).getUserDeviceStartTime() + timeToEndMatch < Date.now()) {
            // check if we should collect points (opponent has written a lastPing > end time - or already collected points | this is for the bot handling)
            if (this.lastPings[opponentType] > match.getServerStartTime() + timeToEndMatch|| match.getUserByType(opponentType).hasCollectedPoints()) {
                if (!match.getUserByType(userType).hasCollectedPoints()) {
                    try {
                        match = await this.collectPoints(match, userType, opponentType);
                    } catch (e) {
                        // here we could do some rollback things. For the moment we just monitor if this happens
                        console.log(e);
                    }
                }
            }
        }

        this.updateMatch(match);
        return this.match;
    };

    collectPoints = async (match: MatchModel, userType: string, opponentType: string) => {
        match.getUserByType(userType).setCollectedPoints(true);
        // set points to collect and collect points locally
        let meMatchUser = match.getUserByType(userType);
        let opponentMatchUser = match.getUserByType(opponentType);
        // I won
        if (meMatchUser.getScore() > opponentMatchUser.getScore() || (meMatchUser.getScore() === opponentMatchUser.getScore() && meMatchUser.getErrors() < opponentMatchUser.getErrors())) {
            // set result to won and get doubled points
            match.getUserByType(userType).setResult(GameConstants.RESULTS.WON);
            match.getUserByType(userType).setPointsToCollect(match.getUserByType(userType).getScore() * 2);
        }
        // I lost
        else if (meMatchUser.getScore() < opponentMatchUser.getScore() || (meMatchUser.getScore() === opponentMatchUser.getScore() && meMatchUser.getErrors() > opponentMatchUser.getErrors())) {
            // set result to lost and get points
            match.getUserByType(userType).setResult(GameConstants.RESULTS.LOST);
            match.getUserByType(userType).setPointsToCollect(match.getUserByType(userType).getScore());
        }
        // draw
        else {
            // set result to draw and get points
            match.getUserByType(userType).setResult(GameConstants.RESULTS.LOST);
            match.getUserByType(userType).setPointsToCollect(match.getUserByType(userType).getScore());
        }
        this.updateMatch(match);
        return match;
    };

    setScore = async (userType: string, score: number) => {
        this.match.getUserByType(userType).setScore(score);
        await this.updateMatch(this.match);
    };

    setErrors = async (string, userType: string, errors: number) => {
        this.match.getUserByType(userType).setErrors(errors);
        await this.updateMatch(this.match);
    };

    getDeviceStartTime = async (serverStartTime: number) => {
        return serverStartTime;
    };


    matchSetUserReady = async (userType: string, ready: boolean): Promise<void> => {
        this.match.getUserByType(userType).setReady(ready);
        if(this.match.getHomeUser().isReady() && this.match.getGuestUser().isReady() && !this.match.getServerStartTime()){
            this.match.setServerStartTime(Date.now());
        }
        await this.updateMatch(this.match);
    };

}
