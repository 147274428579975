// @flow
import SkillsModel from './SkillsModel';

export default class MatchUserModel {

    _correctAnswers: number = 0;
    _errors: number = 0;
    _fitness: number = 0;
    _language: string = '';
    _result: number = 0;
    _score: number = 0;
    _skills: SkillsModel = SkillsModel.createFromParams(0, 1);
    _sticker: number = 0;
    _title: string = '';
    _userCharacter: string = "0-w-1";
    _userId: string = '';
    _userName: string = '';
    _userScore: number = 0;
    _userState: number = 0;
    _userRejoinState: number = 0;
    _pointsToCollect: number = 0;
    _collectedPoints: boolean = false;
    _notificationTokens: Array<string> = [];
    _ready: boolean = false;
    _userDeviceStartTime: ?number = null;

    static createFromParams = (correctAnswers: number, errors: number, fitness: number, language: string,
                                result: number, score: number, skills: SkillsModel,
                                sticker: number, title: string, userCharacter: string, userId: string,
                                userName: string, userScore: number, userState: number, userRejoinState: number, pointsToCollect: number,
                               collectedPoints: boolean, notificationTokens: Array<string>, ready: boolean, userDeviceStartTime: ?number): MatchUserModel => {
        let matchUser: MatchUserModel = new MatchUserModel();
        matchUser._correctAnswers = correctAnswers;
        matchUser._errors = errors;
        matchUser._fitness = fitness;
        matchUser._language = language;
        matchUser._result = result;
        matchUser._score = score;
        matchUser._skills = skills;
        matchUser._sticker = sticker;
        matchUser._title = title;
        matchUser._userCharacter = userCharacter;
        matchUser._userId = userId;
        matchUser._userName = userName;
        matchUser._userScore = userScore;
        matchUser._userState = userState;
        matchUser._userRejoinState = userRejoinState;
        matchUser._pointsToCollect = pointsToCollect;
        matchUser._collectedPoints = collectedPoints;
        matchUser._notificationTokens = notificationTokens ? notificationTokens : [];
        matchUser._ready = ready;
        matchUser._userDeviceStartTime = userDeviceStartTime;
        return matchUser;
    };

    static createFromObject = (matchUserJsonObject: Object): MatchUserModel => {
        let sticker: number = 0;
        if (typeof matchUserJsonObject.sticker !== 'undefined') {
            sticker = matchUserJsonObject.sticker;
        }
        let skills: SkillsModel = SkillsModel.createFromObject(matchUserJsonObject.skills);

        return MatchUserModel.createFromParams(matchUserJsonObject.correctAnswers, matchUserJsonObject.errors, matchUserJsonObject.fitness,
            matchUserJsonObject.language, matchUserJsonObject.result, matchUserJsonObject.score, skills, sticker,
            matchUserJsonObject.title, matchUserJsonObject.userCharacter, matchUserJsonObject.userID, matchUserJsonObject.userName,
            matchUserJsonObject.userScore, matchUserJsonObject.userState, matchUserJsonObject.userRejoinState,
            matchUserJsonObject.pointsToCollect, matchUserJsonObject.collectedPoints, matchUserJsonObject.notificationTokens,
            matchUserJsonObject.ready, matchUserJsonObject.userDeviceStartTime);
    };

    getCorrectAnswers = (): number => {
        return this._correctAnswers;
    };

    setCorrectAnswers = (correctAnswers: number) => {
        this._correctAnswers = correctAnswers;
    };

    getErrors = (): number => {
        return this._errors;
    };

    setErrors = (errors: number) => {
        this._errors = errors;
    };

    addError = (): number => {
        this._errors += 1;
        return this._errors;
    };

    getFitness = (): number => {
        return this._fitness;
    };

    getLanguage = (): string => {
        return this._language;
    };

    getResult = (): number => {
        return this._result;
    };

    setResult = (result: number) => {
        this._result = result;
    };

    getScore = (): number => {
        return this._score;
    };

    setScore = (score: number) => {
        this._score = score;
    };

    addScore = (): number => {
        this._score += 1;
        return this._score;
    };

    getSkills = (): SkillsModel => {
        return this._skills;
    };

    getSticker = (): number => {
        return this._sticker;
    };

    setSticker = (sticker: number) => {
        this._sticker = sticker;
    };

    getTitle = (): string => {
        return this._title;
    };

    getCleanedTitle = (): string => {
        return this._title.replace(/__[^_]*__/g, "");
    };

    getUserCharacter = (): string => {
        return this._userCharacter;
    };

    getUserId = (): string => {
        return this._userId;
    };

    getUserName = (): string => {
        return this._userName;
    };

    getUserScore = (): number => {
        return this._userScore;
    };

    getUserState = (): number => {
        return this._userState;
    };

    setUserState = (userState: number) => {
        this._userState = userState;
    };

    getUserRejoinState = (): number => {
        return this._userRejoinState;
    };

    setUserRejoinState = (userRejoinState: number) => {
        this._userRejoinState = userRejoinState;
    };

    getPointsToCollect = (): number => {
        return this._pointsToCollect;
    };

    setPointsToCollect = (pointsToCollect: number) => {
        this._pointsToCollect = pointsToCollect;
    };

    setCollectedPoints = (collected: boolean): void => {
        this._collectedPoints = collected;
    };

    hasCollectedPoints = (): boolean => {
        return this._collectedPoints;
    };

    getTitle = (): string => {
        return this._title;
    };

    getNotificationTokens = (): Array<string> => {
        return this._notificationTokens;
    };

    setNotificationTokens = (tokens: Array<string>): void => {
        this._notificationTokens = tokens;
    };

    isReady = (): boolean => {
        return this._ready;
    };

    setReady = (ready: boolean): void => {
        this._ready = ready;
    };

    getUserDeviceStartTime = (): ?number => {
        return this._userDeviceStartTime;
    };

    setUserDeviceStartTime = (userDeviceStartTime: number): void => {
        this._userDeviceStartTime = userDeviceStartTime;
    };

    static serialize = (matchUserModel: MatchUserModel): Object => {
        return {
            correctAnswers: matchUserModel.getCorrectAnswers(),
            errors: matchUserModel.getErrors(),
            fitness: matchUserModel.getFitness(),
            language: matchUserModel.getLanguage(),
            result: matchUserModel.getResult(),
            score: matchUserModel.getScore(),
            skills: SkillsModel.serialize(matchUserModel.getSkills()),
            sticker: matchUserModel.getSticker(),
            title: matchUserModel.getTitle(),
            userCharacter: matchUserModel.getUserCharacter(),
            userID: matchUserModel.getUserId(),
            userName: matchUserModel.getUserName(),
            userScore: matchUserModel.getUserScore(),
            userState: matchUserModel.getUserState(),
            userRejoinState: matchUserModel.getUserRejoinState(),
            pointsToCollect: matchUserModel.getPointsToCollect(),
            collectedPoints: matchUserModel.hasCollectedPoints(),
            ready: matchUserModel.isReady(),
            notificationTokens: matchUserModel.getNotificationTokens(),
            userDeviceStartTime: matchUserModel.getUserDeviceStartTime(),
        }
    };
}
