const LanguageTranslationsMap = {
    "Deutsch": {
        "ar": "الألمانية",
        "bg": "Немски",
        "br": "Alemão",
        "bs": "Njemački",
        "ca": "Alemany",
        "cz": "Německy",
        "de": "Deutsch",
        "default": "German",
        "el": "Γερμανικά",
        "en": "German",
        "er": "Alemán",
        "es": "Alemán",
        "ex": "Alemán",
        "fa": "آلمانی",
        "fr": "Allemand",
        "hi": "जर्मन",
        "hr": "Njemački",
        "hu": "Német",
        "hy": "Գերմաներեն",
        "it": "Tedesco",
        "ja": "ドイツ語",
        "jj": "ドイツご",
        "ko": "독일어",
        "lt": "Vokiečių k.",
        "no": "Tysk",
        "pl": "Niemiecki",
        "ps": "جرمن",
        "pt": "Alemão",
        "ro": "Germană",
        "ru": "Немецкий",
        "rw": "Ikidage",
        "sk": "Nemčina",
        "sl": "Nemščina",
        "sw": "Kijeremani",
        "tr": "Almanca",
        "ur": "جرمن",
        "zh": "德语",
        "zt": "德語"
    },
    "English": {
        "ar": "الإنجليزية",
        "bg": "Английски",
        "br": "Inglês",
        "bs": "Engleski",
        "ca": "Anglès",
        "cz": "English",
        "de": "Englisch",
        "default": "English",
        "el": "Αγγλικά",
        "en": "English",
        "er": "Inglés",
        "es": "Inglés",
        "ex": "Inglés",
        "fa": "انگلیسی",
        "fr": "Anglais",
        "hi": "अंग्रेजी",
        "hr": "Engleski",
        "hu": "Angol",
        "hy": "Անգլերեն",
        "it": "Inglese",
        "ja": "英語",
        "jj": "えいご",
        "ko": "영어",
        "lt": "Anglų k.",
        "no": "Engelsk",
        "pl": "Angielski",
        "ps": "انګريزي",
        "pt": "Inglês",
        "ro": "Engleză",
        "ru": "Английский",
        "rw": "Icyongereza",
        "sk": "Angličtina",
        "sl": "Angleščina",
        "sw": "Kingereza",
        "tr": "İngilizce",
        "ur": "انگریزی",
        "zh": "英语",
        "zt": "英語"
    },
    "French": {
        "ar": "الفرنسية",
        "bg": "Френски",
        "br": "Francês",
        "bs": "Francuski",
        "ca": "Francès",
        "cz": "Francouzsky",
        "de": "Französisch",
        "default": "French",
        "el": "Γαλλικά",
        "en": "French",
        "er": "Francés",
        "es": "Francés",
        "ex": "Francés",
        "fa": "فرانسوی",
        "fr": "Français",
        "hi": "फ्रेंच",
        "hr": "Francuski",
        "hu": "Francia",
        "hy": "Ֆրանսերեն",
        "it": "Francese",
        "ja": "フランス語",
        "jj": "フランスご",
        "ko": "프랑스어",
        "lt": "Prancūzų k.",
        "no": "Fransk",
        "pl": "Francuski",
        "ps": "فرانسيسي",
        "pt": "Francês",
        "ro": "Franceză",
        "ru": "Французский",
        "rw": "Igifaransa",
        "sk": "Francúzština",
        "sl": "Francoščina",
        "sw": "Kifaransa",
        "tr": "Fransızca",
        "ur": "فرانسیسی",
        "zh": "法语",
        "zt": "法語"
    },
    "Italian": {
        "ar": "الإيطالية",
        "bg": "Италиански",
        "br": "Italiano",
        "bs": "Italijanski",
        "ca": "Italià",
        "cz": "Italsky",
        "de": "Italienisch",
        "default": "Italian",
        "el": "Ιταλικά",
        "en": "Italian",
        "er": "Italiano",
        "es": "Italiano",
        "ex": "Italiano",
        "fa": "ایتالیایی",
        "fr": "Italien",
        "hi": "इतालवी",
        "hr": "Talijanski",
        "hu": "Olasz",
        "hy": "Իտալերեն",
        "it": "Italiano",
        "ja": "イタリア語",
        "jj": "イタリアご",
        "ko": "이탈리아어",
        "lt": "Italų k.",
        "no": "Italiensk",
        "pl": "Włoski",
        "ps": "اطالوي",
        "pt": "Italiano",
        "ro": "Italiană",
        "ru": "Итальянский",
        "rw": "Igitaliyani",
        "sk": "Taliančina",
        "sl": "Italijanščina",
        "sw": "Kiitalia",
        "tr": "İtalyanca",
        "ur": "اطالوی",
        "zh": "意大利语",
        "zt": "意大利語"
    },
    "Japanese": {
        "ar": "اليابانية",
        "bg": "японски",
        "br": "japonês",
        "bs": "Japanski",
        "ca": "Japonès",
        "cz": "japonský",
        "de": "Japanisch",
        "default": "Japanese",
        "el": "Ιαπωνικά",
        "en": "Japanese",
        "er": "japonés",
        "es": "japonés",
        "ex": "japonés",
        "fa": "ژاپنی",
        "fr": "japonais",
        "hi": "जापानी",
        "hr": "japanski",
        "hu": "japán",
        "hy": "ճապոնացի",
        "it": "giapponese",
        "ja": "日本の",
        "jj": "日本語",
        "ko": "일본의",
        "lt": "Japonijos",
        "nl": "Japans",
        "no": "japansk",
        "pl": "japoński",
        "ps": "جاپاني",
        "pt": "japonês",
        "ro": "japoneză",
        "ru": "японский",
        "sk": "japonský",
        "sl": "Japonski",
        "sr": "japanski",
        "sw": "Kijapani",
        "tr": "Japon",
        "ur": "جاپانی",
        "zh": "日本",
        "zt": "日語"
    },
    "Portuguese": {
        "ar": "البرتغالية",
        "bg": "Португалски",
        "br": "Português",
        "bs": "Portugalski",
        "ca": "Portuguès",
        "cz": "Portugalsky",
        "de": "Portugiesisch",
        "default": "Portuguese",
        "el": "Πορτογαλικά",
        "en": "Portuguese",
        "er": "Portugués",
        "es": "Portugués",
        "ex": "Portugués",
        "fa": "پرتغالی",
        "fr": "Portugais",
        "hi": "पुर्तगाली",
        "hr": "Portugalski",
        "hu": "Portugál",
        "hy": "Պորտուգալերեն",
        "it": "Portoghese",
        "ja": "ポルトガル語",
        "jj": "ポルトガルご",
        "ko": "포르투갈어",
        "lt": "Portugalų k.",
        "no": "Portugisisk",
        "pl": "Portugalski",
        "ps": "پُرتګيز",
        "pt": "Português",
        "ro": "Portugheză",
        "ru": "Португальский",
        "rw": "Igiporutugali",
        "sk": "Portugalčina",
        "sl": "Portugalščina",
        "sw": "Kireno",
        "tr": "Portekizce",
        "ur": "پرتگالی",
        "zh": "葡萄牙语",
        "zt": "葡萄牙語"
    },
    "Spanish": {
        "ar": "الإسبانية",
        "bg": "Испански",
        "br": "Espanhol",
        "bs": "Španski",
        "ca": "Castellà",
        "cz": "Španělsky",
        "de": "Spanisch",
        "default": "Spanish",
        "el": "Ισπανικά",
        "en": "Spanish",
        "er": "Español",
        "es": "Español",
        "ex": "Español",
        "fa": "اسپانیایی",
        "fr": "Espagnol",
        "hi": "स्पैनिश",
        "hr": "Španjolski",
        "hu": "Spanyol",
        "hy": "Իսպաներեն",
        "it": "Spagnolo",
        "ja": "スペイン語",
        "jj": "スペインご",
        "ko": "스페인어",
        "lt": "Ispanų k.",
        "no": "Spansk",
        "pl": "Hiszpański",
        "ps": "هسپانوي",
        "pt": "Espanhol",
        "ro": "Spaniolă",
        "ru": "Испанский",
        "rw": "Icyesipanyoro",
        "sk": "Španielčina",
        "sl": "Španščina",
        "sw": "Kihispania",
        "tr": "İspanyolca",
        "ur": "ہسپانوی",
        "zh": "西班牙语",
        "zt": "西班牙語"
    }
}

export default LanguageTranslationsMap;
