// @flow
import SkillsModel from '../models/SkillsModel';
import type { BotType } from '../typedefs/BotType';

export default class Bot {

    static createRandom():BotType {
        let names: Array<{name: string, character: string}> = require('../assets/data/botNames');
        let totalPoints = (Math.round(Math.random() * 100) + 200) * 10;
        let gainedPoints = Math.round(Math.random() * totalPoints);
        let score = Math.round(Math.random() * 2000) + gainedPoints;

        let name: {name: string, character: string} = names[(Math.floor(Math.random() * names.length))];
        let character: string = "0-m-0";

        // create bot object for later use
        let bot: BotType = {
            level: Bot.getLevelByScore(score),
            fitness: Math.floor(Math.random() * 100),
            gainedPoints: gainedPoints,
            totalPoints: totalPoints,
            name: name.name,
            trikot: character,
            score: score
        };
        return bot;
    }

    static createFromParams(name: string, character: string, score: number, fitness: number, skills: SkillsModel): BotType{
        let bot: BotType = {
            level: Bot.getLevelByScore(score),
            fitness: fitness,
            gainedPoints: skills.getGainedPoints(),
            totalPoints: skills.getTotalPoints(),
            name: name,
            trikot: character,
            score: score
        };
        return bot;
    }

    static getLevelByScore(score: number): number {
        return Math.round(score / 5000 * 0.56 * 100) / 100 + 0.44;
    }
}
