export default class GameConstants {

}

GameConstants.MATCH_VERSION = 2;

GameConstants.PLAYER_TYPE = {
    HOME_USER: "homeUser",
    GUEST_USER: "guestUser",
};

GameConstants.GAME_TYPE = {
    BOT: "bot",
    PLAYER: "player",
};

GameConstants.ONLINE_STATES = {
    ONLINE: "GLOBAL__ONLINE",
    LOW_CONNECTION: "LOW_CONNECTION",
    OFFLINE: "GLOBAL__OFFLINE",
    DONT_SHOW: "DONT_SHOW",
};

GameConstants.STATE = {
    LOADING: 0,
    OPEN: 1,
    DECLINED: 1.5,
    DECLINED_IN_GAME: 1.6,
    JOINED: 2,
    PLAYING: 3,
    FIRST_HALF: 4,
    HALF_TIME: 5,
    SECOND_HALF: 6,
    FINISHED: 7,
    CLOSED: 8,
};

GameConstants.DECLINE_REASONS = {};
GameConstants.DECLINE_REASONS[GameConstants.STATE.DECLINED] = "OPPONENT_DECLINED";
GameConstants.DECLINE_REASONS[GameConstants.STATE.DECLINED_IN_GAME] = "OPPONENT_DECLINED_IN_GAME";

GameConstants.REMATCH = {
    INVITED: 0,
    ACCEPTED: 1,
    REJECTED: 2
};

GameConstants.DIFFICULTY = {
    EASY: 3,
    MEDIUM: 2,
    HARD: 1
};

GameConstants.RESULTS = {
    NOT_STARTED: 0,
    NO_RESULT: 1,
    LOST: 2,
    WON: 3,
    LEFT: 4,
    TIE: 5,
};

GameConstants.MATCH =
    {
        homeUser: {
            userName: "Player 1",
            score: 0,
            errors: 0,
            correctAnswers: 0,
            userCharacter: 0,
            userState: -1,
            skills: {
                gainedPoints: 0,
                totalPoints: 0
            },
            userScore: 0,
            fitness: 0,
        },
        guestUser: {
            userName: "Player 2",
            score: 0,
            errors: 0,
            correctAnswers: 0,
            userCharacter: 0,
            userState: -1,
            skills: {
                gainedPoints: 0,
                totalPoints: 0
            },
            userScore: 0,
            fitness: 0,
        },
        gameState: -1,
        inviteTime: 0,
    };

GameConstants.ASYNC_RACE_MATCH =
    {
        homeUser: {
            userName: "Player 1",
            score: 0,
            errors: 0,
            correctAnswers: 0,
            userCharacter: 0,
            userState: -1,
            skills: {
                gainedPoints: 0,
                totalPoints: 0
            },
            userScore: 0,
            fitness: 0,
        },
        guestUser: {
            userName: "Player 2",
            score: 0,
            errors: 0,
            correctAnswers: 0,
            userCharacter: 0,
            userState: -1,
            skills: {
                gainedPoints: 0,
                totalPoints: 0
            },
            userScore: 0,
            fitness: 0,
        },
        gameState: -1,
        inviteTime: 0,
    };


GameConstants.READY_PHASE_LENGTH = 5;

GameConstants.TIMEOUT = 30;
GameConstants.BOT_TIMEOUT_SECONDS = 2;
GameConstants.BOT_ERROR_THRESHOLD = 0.3;

GameConstants.MATCH_REFRESH_TIMER_MS = 200;
GameConstants.MATCH_V2_BOT_ERROR_THRESHOLD = 0.4;


GameConstants.INVITE_TYPES = {
    NOW_READY: "NOW_READY",
    INVITES: "INVITES"
};
