// @flow
import React, {Component} from 'react';
import MatchModel from '../models/MatchModel';
import WordModel from '../models/WordModel';
import AudioGivenChooseWord from "../exercises/AudioGivenChooseWord";
import PictureGivenChooseWord from "../exercises/PictureGivenChooseWord";
import TranslationGivenChooseWord from "../exercises/TranslationGivenChooseWord";

type Props = {
    onClick: () => mixed,
    match: MatchModel,
    matchWord: { word: WordModel, possibleAnswers: Array<WordModel> },
    correctAnswerGiven: () => mixed,
    type: string,
    testLanguage: string,
    wrongAnswerGiven: () => mixed
};

type State = {
    exerciseType: string
}

const EXERCISE_TYPES = {
    AUDIO_CHOOSE_WORD: "AUDIO_CHOOSE_WORD",
    PICTURE_CHOOSE_WORD: "PICTURE_CHOOSE_WORD",
    TRANSLATION_CHOOSE_WORD: "TRANSLATION_CHOOSE_WORD",
};

class Match extends Component<Props, State> {

    state = {
        exerciseType: EXERCISE_TYPES.AUDIO_CHOOSE_WORD
    };

    shouldComponentUpdate = (nextProps: Props) => {
        return this.props.matchWord !== nextProps.matchWord;
    };

    componentWillMount = () => {
        this.getSuitableExerciseTypes();
    };

    card = null;
    clickable: boolean = true;

    getSuitableExerciseTypes = () => {
        let random = Math.random();
        let exerciseType = EXERCISE_TYPES.AUDIO_CHOOSE_WORD;
        if (random > 0.6) {
            exerciseType = EXERCISE_TYPES.PICTURE_CHOOSE_WORD;
        } else if(random > 0.3){
            exerciseType = EXERCISE_TYPES.TRANSLATION_CHOOSE_WORD;
        }
        this.setState({exerciseType: exerciseType});
    };

    getSuitableExerciseTypesFull = async (matchWord: { word: WordModel, possibleAnswers: Array<WordModel> }): Promise<Array<number>> => {
        let suitableExercises: Array<number> = [];

        // check for image in all possible answers
        let answersHaveImage = true;
        for (let i = 0; i < matchWord.possibleAnswers.length; i++) {
            if (!matchWord.possibleAnswers[i].getPicture()) {
                answersHaveImage = false;
            }
        }

        let wordImageExists = !!matchWord.word.getPicture();

        // check for multiple choice exercises (only if 4 or more possible answers)
        if (matchWord.possibleAnswers.length >= 4) {
            // check for word choose image
            if (answersHaveImage) {
                suitableExercises.push(1);
            } else {
                // check for translation in all possible answers
                let answersHaveTranslation = true;
                for (let i = 0; i < matchWord.possibleAnswers.length; i++) {
                    if (!matchWord.possibleAnswers[i].getTranslation()) {
                        answersHaveTranslation = false;
                        break;
                    }
                }

                // check for word choose translation (only if not all answers have image)
                if (answersHaveTranslation) {
                    suitableExercises.push(7);
                }
            }

            // check for image choose word
            if (matchWord.word.getPicture() && wordImageExists) {
                suitableExercises.push(2);
            } else {
                // check for translation choose word (only if no picture is available)
                if (matchWord.word.getTranslation()) {
                    suitableExercises.push(8);
                }
            }

            // check for audio exercises
            if (matchWord.word.getSoundfile()) {
                // check for audio choose image
                if (answersHaveImage) {
                    suitableExercises.push(3);
                }

                // check for audio choose word
                if (wordImageExists) {
                    suitableExercises.push(4);
                }
            }
        }

        // check for word parts
        if (matchWord.word.getTranslation()) {
            if (matchWord.word.getTranslation().length > 5 || suitableExercises.length === 0) {
                suitableExercises.push(6);
            }
        } else {
            if (matchWord.word.getWord().length > 5 || suitableExercises.length === 0) {
                suitableExercises.push(5);
            }
        }

        return suitableExercises;
    };

    correctAnswerGiven = () => {
        if (this.card) {
            this.card.swipeCardOut();
        }
        setTimeout(() => {
            if (this.props.correctAnswerGiven) {
                this.props.correctAnswerGiven();
            }
        }, 250);
    };

    wrongAnswerGiven = () => {
        if (this.card) {
            this.card.swipeCardOut();
        }
        setTimeout(() => {
            if (this.props.wrongAnswerGiven) {
                this.props.wrongAnswerGiven();
            }
        }, 250);
    };

    clickAnswer = (id: number) => {
        if (this.clickable) {
            this.clickable = false;
            if (this.props.matchWord.word.getId() === id) {
                this.correctAnswerGiven();
            } else {
                setTimeout(() => {
                    this.wrongAnswerGiven();
                }, 1000);
            }
        }
    };

    render() {
        if (this.state.exerciseType === EXERCISE_TYPES.AUDIO_CHOOSE_WORD) {
            return <AudioGivenChooseWord match={this.props.match} matchWord={this.props.matchWord}
                                         testLanguage={this.props.testLanguage}
                                         correctAnswerGiven={this.props.correctAnswerGiven}
                                         wrongAnswerGiven={this.props.wrongAnswerGiven}/>
        } else if (this.state.exerciseType === EXERCISE_TYPES.PICTURE_CHOOSE_WORD) {
            return <PictureGivenChooseWord match={this.props.match} matchWord={this.props.matchWord}
                                           testLanguage={this.props.testLanguage}
                                           correctAnswerGiven={this.props.correctAnswerGiven}
                                           wrongAnswerGiven={this.props.wrongAnswerGiven}/>
        } else {
            return <TranslationGivenChooseWord match={this.props.match} matchWord={this.props.matchWord}
                                               correctAnswerGiven={this.props.correctAnswerGiven}
                                               wrongAnswerGiven={this.props.wrongAnswerGiven}/>
        }
    }
}

export default Match;
