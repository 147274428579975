import React, {Component} from 'react';
import {Sentry} from "react-activity";
import i18next from "i18next";

class Loading extends Component {

    render() {
        return (
            <div className="content-container">
                <Sentry/>
                <p className="info-text">{i18next.t("LOADING__LOADING_ELLIPSIS")}</p>
            </div>
        )
    }

}

export default Loading;
