// @flow
import React, {Component} from 'react';

type Props = {
    text: string,
    onClick: () => mixed
};

class Button extends Component<Props> {

    render() {
        return (
            <button onClick={this.props.onClick} className="button-container">
                <p className="button-text">
                    {
                        this.props.text
                    }
                </p>
            </button>
        );
    }
}

export default Button;
