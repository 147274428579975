
import React, {Component} from 'react';

import { Line } from 'rc-progress';

type Props = {
    desc: string,
    time: number,
    duration: number
};

class Progress extends Component<Props> {

    render() {
        return (
            <div className="progress-outer-container">
                <Line
                    percent={ (this.props.duration - this.props.time) / this.props.duration * 100 }
                    strokeWidth={1}
                    trailWidth={1}
                    strokeLinecap="square"
                    trailColor='#eeeeee'
                    strokeColor='#64d950'/>
                <div className="progress-bottom-container" />
                <div className="progress-time-container">
                    <p>
                        {
                            this.props.time
                        }
                    </p>
                </div>
            </div>
        );
    }
}

export default Progress;
