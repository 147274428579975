import React from 'react';
import MatchModel from "../models/MatchModel";
import PromiseComponent from "../PromiseComponent";
import Button from "../components/matchComponents/Button";
import i18next from 'i18next';
import RecommendedCoursesMap from "../maps/RecommendedCoursesMap";
import CourseNameTranslationsMap from '../maps/CourseNameTranslationsMap';

type Props = {
    match: MatchModel,
    sponsorID: string,
    testLanguage: string,
    goOnWithTest: (language: string, recommendedCourse: string) => mixed,
    avatarImageSource: string
};

type State = {
    linkUrl: string,
    points: number,
    pointsOpponent: number
}

class MatchEndResult extends PromiseComponent<Props, State> {

    state = {
        linkUrl: "",
        points: 0,
        pointsOpponent: 0
    };

    componentDidMount = async () => {
        this.startCountingPoints();
    };

    startCountingPoints = async () => {
        setTimeout(() => {
            this.countPoints();
        }, 300);
    };

    countPoints = async () => {
        if(this.state.points < this.props.match.getHomeUser().getPointsToCollect()){
            this.setState({points: this.state.points + 1});
            setTimeout(() => {
                this.countPoints();
            }, 25);
        }
    };

    countPointsOpponent = async () => {
        if(this.state.pointsOpponent < this.props.match.getGuestUser().getPointsToCollect()){
            this.setState({pointsOpponent: this.state.pointsOpponent + 1});
            setTimeout(() => {
                this.countPointsOpponent();
            }, 100);
        }
    };

    calculateCourseLevel = () => {
        let score = this.props.match.getHomeUser().getScore();
        let courses = RecommendedCoursesMap[this.props.testLanguage].Courses;
        let startpoints = RecommendedCoursesMap[this.props.testLanguage].Startpoints;
        let courseLevel = "";
        for(let i = 0; i < courses.length; i++) {
            if(i+1 < courses.length) {
                if(score >= startpoints[i] && score < startpoints[i+1]) {
                    courseLevel = courses[i];
                }
            } else {
                if(score >= startpoints[i]) {
                    courseLevel = courses[i];
                }
            }
        }
        return courseLevel;
    };

    getTestLanguage = () => {
        const possibleLanguages = ['English', 'Deutsch', 'French', 'Italian', 'Portuguese', 'Spanish', 'Japanese'];
        let language = '';
        for (let k = 0; k < possibleLanguages.length; k++) {
            if (this.props.testLanguage.includes(possibleLanguages[k])) {
                language = possibleLanguages[k];
                break;
            }
        }
        return language;
    }

    render() {
        let homeUserName = this.props.match.getHomeUser().getUserName() === "You" ? i18next.t('WEB_LIVE_MATCH__YOU') : this.props.match.getHomeUser().getUserName();
        let guestUserName = this.props.match.getGuestUser().getUserName() === "You" ? i18next.t('WEB_LIVE_MATCH__YOU') : this.props.match.getGuestUser().getUserName();
        let homeScore = this.props.match.getHomeUser().getScore();
        let guestScore = this.props.match.getGuestUser().getScore();
        let homePoints = this.state.points;
        let guestPoints = this.props.match.getGuestUser().getPointsToCollect();
        let allAnswersHome = homeScore + this.props.match.getHomeUser().getErrors();
        let allAnswersGuest = guestScore + this.props.match.getGuestUser().getErrors();
        let language = this.getTestLanguage();
        let recommendedCourse = this.calculateCourseLevel();
        let translatedCourseName = CourseNameTranslationsMap[language+recommendedCourse][i18next.language.substr(0, 2)] || CourseNameTranslationsMap[language+recommendedCourse].default;
        return (
            <div className="match-end-result-container">
                <img src={require('../assets/img/ranking.png')} className="success" alt="Trophy" />
                <div className="match-end-image-container">
                    <div className="match-end-image-container-inner">
                        <div className="match-end-trikot-image-container active">
                            <img className="match-end-trikot-image" src={this.props.avatarImageSource}
                                 alt="LearnMatch Trikot"/>
                        </div>
                        <p className="match-end-player-text">{homeUserName}</p>
                        <p className="match-end-player-score"><small>{homeScore}/{allAnswersHome} { i18next.t('DESCRIPTION_MATCH_RESULTS__CORRECT_ANSWERS')}</small></p>
                        <p className="match-end-player-points active">+ {homePoints} {i18next.t("CHALLENGE_MATCHES__POINTS")}</p>
                    </div>
                    <div className="match-end-image-container-inner">
                        <div className="match-end-trikot-image-container">
                            <img className="match-end-trikot-image" src={require('../assets/img/Girl.png')}
                                 alt="LearnMatch Trikot"/>
                        </div>
                        <p className="match-end-player-text">{guestUserName}</p>
                        <p className="match-end-player-score"><small>{guestScore}/{allAnswersGuest} { i18next.t('DESCRIPTION_MATCH_RESULTS__CORRECT_ANSWERS')}</small></p>
                        <p className="match-end-player-points">+ {guestPoints} {i18next.t("CHALLENGE_MATCHES__POINTS")}</p>
                    </div>
                </div>
                <div className="button-with-message-container">
                    <p className="motivating-message-top">{i18next.t('MESSAGE_AFTER_LANGUAGE_TEST_IN_CODE_PORTAL_1')}</p>
                    <p className="motivating-message-course">{translatedCourseName}</p>
                    <p className="motivating-message-bottom">{i18next.t('MESSAGE_AFTER_LANGUAGE_TEST_IN_CODE_PORTAL_2')}</p>
                    <Button text={i18next.t('BUTTON__CONTINUE_TO_DOWNLOAD')} onClick={() => { this.props.goOnWithTest(language, recommendedCourse) }}/>
                </div>
            </div>
        )
    }
}

export default MatchEndResult
