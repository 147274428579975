const CourseNameTranslationsMap = {
    "DeutschBauInnungAU": {
        "ar": "صناعة البناء AT",
        "bg": "Строителна индустрия AT",
        "bs": "Građevinska industrija AT",
        "cz": "Stavebnictví AT",
        "default": "Bau AT",
        "en": "Construction industry AT",
        "fa": "صنعت ساخت و ساز AT",
        "hr": "Građevinska industrija AT",
        "it": "Industria edile AT",
        "pl": "Przemysł budowlany AT",
        "pt": "Indústria de construção AT",
        "ro": "Industria construcțiilor AT",
        "sk": "Stavebníctvo AT",
        "sl": "Gradbena industrija AT",
        "sr": "Građevinska industrija AT",
        "tr": "İnşaat sektörü AT",
        "uk": "Будівельна галузь АТ"
    },
    "DeutschBeginner": {
        "ar": "الألمانية - مُبتدىء 1",
        "bg": "1 Немски - Начинаещ",
        "br": "1 Alemão - Iniciante",
        "ca": "1  Alemany - Principiant",
        "cz": "1 Německy - Začátečník",
        "de": "Deutsch Anfänger",
        "default": "1 German - Beginner",
        "el": "1 Γερμανικά - Αρχάριος",
        "en": "1 German - Beginner",
        "er": "1 Alemán - Principiante",
        "es": "1 Alemán - Principiante",
        "ex": "1 Alemán - Principiante",
        "fa": "1 آلمانی - مبتدی",
        "fr": "1 Allemand - Débutant",
        "hi": "1 जर्मन - नौसिखिया",
        "hr": "1 Njemački - Početnik",
        "hu": "1 Német - Kezdő",
        "hy": "1 Գերմաներեն - Սկսնակ",
        "it": "1 Tedesco - Principiante",
        "ja": "1 ドイツ語 - 初心者",
        "ko": "1 독일어 - 초심자",
        "lt": "1 Vokiečių k. - Pradedantysis",
        "nl": "1 Duitse - Beginner",
        "no": "1 Tysk - Begynner",
        "pl": "1 Niemiecki - Początkujący",
        "pt": "1 Alemão - Principiante",
        "ro": "1 Germană - Începător",
        "ru": "1 Немецкий - Начинающий",
        "rw": "1 Ikidage - Umutangizi",
        "sk": "1 Nemčina - Začiatočník",
        "sl": "1 Nemščina - Začetnik",
        "sr": "1 Nemački  - Početni",
        "sv": "1 Tysk - Nybörjare",
        "sw": "1 Kijeremani - Ayeanza",
        "tr": "1 Almanca - Başlangıç",
        "uk": "1 Німецька - Початківець",
        "ur": "1 جرمن - نوآموز",
        "zh": "1 德语 - 新手"
    },
    "DeutschConyCamBusfahrer": {
        "default": "Fleoo"
    },
    "DeutschFootball": {
        "ar": " الألمانية - كرة القدم",
        "bg": "Немски - Футбол",
        "br": "Alemão - Futebol",
        "cz": "Německy - Fotbal",
        "default": "German - Football",
        "el": "Γερμανικά - Ποδόσφαιρο",
        "en": "German - Football",
        "er": "Alemán - Fútbol",
        "es": "Alemán - Fútbol",
        "ex": "Alemán - Futbol",
        "fa": "آلمانی-  فوتبال",
        "fr": "Allemand - Football",
        "hi": "जर्मन - फुटबॉल",
        "hr": "Njemački - Nogomet",
        "hu": "Német - Futball",
        "hy": "Գերմաներեն - Ֆուտբոլ",
        "it": "Tedesco - Calcio",
        "ja": "ドイツ語 - サッカー",
        "ko": "독일어 - 축구",
        "lt": "Vokiečių k. - Futbolas",
        "no": "Tysk - Fotball",
        "pl": "Niemiecki - piłka nożna",
        "pt": "Alemão - Futebol",
        "ro": "Germană - Fotbal",
        "ru": "Немецкий - Футбол",
        "rw": "Ikidage - Umupira w'amaguru",
        "sk": "Nemčina - Futbal",
        "sl": "Nemščina - Nogomet",
        "sr": "Nemački - fudbal",
        "sv": "Tysk - Fotboll",
        "sw": "Kijeremani - Kandanda",
        "tr": "Almanca - Futbol",
        "ur": "جرمن - فٹ بال",
        "zh": "德语 - 足球"
    },
    "DeutschGruWoCoolista": {
        "ar": "مفردات اساسية - الألمانية",
        "bg": "Основен речник - Немски",
        "ca": "Vocabulari bàsic - Alemany",
        "cz": "Základní slovní zásoba - Německy",
        "default": "Basic vocabulary - German",
        "el": "Βασικό λεξιλόγιο - Γερμανικά",
        "en": "Basic vocabulary - German",
        "es": "Vocabulario básico - Alemán",
        "fa": "واژگان پایه - آلمانی",
        "fr": "Vocabulaire de base - Allemand",
        "hr": "Osnovni vokabular - Njemački",
        "hu": "Alap szókincs - Német",
        "it": "Lessico di base - Tedesco",
        "lt": "Pagrindinis žodynėlis - Vokiečių k.",
        "nl": "Basiswoordenschat - Duits",
        "pl": "Słownictwo podstawowe - Niemiecki",
        "pt": "Vocabulário básico - Alemão",
        "ro": "Vocabularul de bază - Germană",
        "ru": "Базовая лексика - Немецкий",
        "sk": "Základná slovná zásoba - Nemčina",
        "sl": "Osnovni besednjak - Nemščina",
        "sq": "Fjalori Basic - Gjermanisht",
        "sr": "Osnovni rečnik - Nemačko",
        "tr": "Basit kelime bilgisi - Almanca",
        "uk": "Словник базовий - Німецько"
    },
    "DeutschGruWoStarter": {
        "ar": "مفردات اساسية 1 - الألمانية",
        "bg": "Основен речник 1 - Немски",
        "ca": "Vocabulari bàsic 1 - Alemany",
        "cz": "Základní slovní zásoba 1 - Německy",
        "default": "Grundwortschatz 1 - Deutsch",
        "el": "Βασικό λεξιλόγιο 1 - Γερμανικά",
        "en": "Basic vocabulary 1 - German",
        "es": "Vocabulario básico 1 - Alemán",
        "fa": "واژگان پایه 1 - آلمانی",
        "fr": "Vocabulaire de base 1 - Allemand",
        "hr": "Osnovni vokabular 1 - Njemački",
        "hu": "Alap szókincs 1 - Német",
        "it": "Lessico di base 1 - Tedesco",
        "lt": "Pagrindinis žodynėlis 1 - Vokiečių k.",
        "nl": "Basiswoordenschat 1 - Duits",
        "pl": "Słownictwo podstawowe 1 - Niemiecki",
        "pt": "Vocabulário básico 1 - Alemão",
        "ro": "Vocabularul de bază 1 - Germană",
        "ru": "Базовая лексика 1 - Немецкий",
        "sk": "Základná slovná zásoba 1 - Nemčina",
        "sl": "Osnovni besednjak 1 - Nemščina",
        "sq": "Fjalori Basic 1 - Gjermanisht",
        "sr": "Osnovni rečnik 1 - Nemačko",
        "tr": "Basit kelime bilgisi 1 - Almanca",
        "uk": "Словник базовий 1 - Німецько"
    },
    "DeutschGruwo": {
        "ar": "مفردات اساسية - الألمانية",
        "bg": "Основен речник - Немски",
        "br": "Vocabulário básico - Alemão",
        "ca": "Vocabulari bàsic - Alemany",
        "cz": "Základní slovní zásoba - Německy",
        "default": "Basic vocabulary - German",
        "el": "Βασικό λεξιλόγιο - Γερμανικά",
        "en": "Basic vocabulary - German",
        "er": "Vocabulario básico - Alemán",
        "es": "Vocabulario básico - Alemán",
        "ex": "Vocabulario básico - Alemán",
        "fa": "واژگان پایه - آلمانی",
        "fr": "Vocabulaire de base - Allemand",
        "hi": "बुनियादी शब्दावली - जर्मन",
        "hr": "Osnovni vokabular - Njemački",
        "hu": "Alap szókincs - Német",
        "hy": "հիմնական բառապաշար - Գերմաներեն",
        "it": "Lessico di base - Tedesco",
        "ja": "基本語彙 - ドイツ語",
        "ko": "기본 어휘 - 독일어",
        "lt": "Pagrindinis žodynėlis - Vokiečių k.",
        "no": "Grunnleggende ordforråd - Tysk",
        "pl": "Słownictwo podstawowe - Niemiecki",
        "pt": "Vocabulário básico - Alemão",
        "ro": "Vocabularul de bază - Germană",
        "ru": "Базовая лексика - Немецкий",
        "sk": "Základná slovná zásoba - Nemčina",
        "sl": "Osnovni besednjak - Nemščina",
        "sq": "Fjalori Basic - Gjermanisht",
        "sr": "Osnovni rečnik - Nemačko",
        "sw": "Msamiati wa msingi - Kijeremani",
        "tr": "Basit kelime bilgisi - Almanca",
        "ur": "بنیادی الفاظ - جرمن",
        "zh": "基本词汇 - 德语"
    },
    "DeutschGruwoFortgeschritten": {
        "ar": "مفردات اساسية 3 - الألمانية",
        "bg": "Основен речник 3 - Немски",
        "ca": "Vocabulari bàsic 3 - Alemany",
        "cz": "Základní slovní zásoba 3 - Německy",
        "default": "Grundwortschatz 3 - Deutsch",
        "el": "Βασικό λεξιλόγιο 3 - Γερμανικά",
        "en": "Basic vocabulary 3 - German",
        "es": "Vocabulario básico 3 - Alemán",
        "fa": "واژگان پایه 3 - آلمانی",
        "fr": "Vocabulaire de base 3 - Allemand",
        "hr": "Osnovni vokabular 3 - Njemački",
        "hu": "Alap szókincs 3 - Német",
        "it": "Lessico di base 3 - Tedesco",
        "lt": "Pagrindinis žodynėlis 3 - Vokiečių k.",
        "nl": "Basiswoordenschat 3 - Duits",
        "pl": "Słownictwo podstawowe 3 - Niemiecki",
        "pt": "Vocabulário básico 3 - Alemão",
        "ro": "Vocabularul de bază 3 - Germană",
        "ru": "Базовая лексика 3 - Немецкий",
        "sk": "Základná slovná zásoba 3 - Nemčina",
        "sl": "Osnovni besednjak 3 - Nemščina",
        "sq": "Fjalori Basic 3 - Gjermanisht",
        "sr": "Osnovni rečnik 3 - Nemačko",
        "tr": "Basit kelime bilgisi 3 - Almanca",
        "uk": "Словник базовий 3 - Німецько"
    },
    "DeutschGruwoMittel": {
        "ar": "مفردات اساسية 2 - الألمانية",
        "bg": "Основен речник 2 - Немски",
        "ca": "Vocabulari bàsic 2 - Alemany",
        "cz": "Základní slovní zásoba 2 - Německy",
        "default": "Grundwortschatz 2 - Deutsch",
        "el": "Βασικό λεξιλόγιο 2 - Γερμανικά",
        "en": "Basic vocabulary 2 - German",
        "es": "Vocabulario básico 2 - Alemán",
        "fa": "واژگان پایه 2 - آلمانی",
        "fr": "Vocabulaire de base 2 - Allemand",
        "hr": "Osnovni vokabular 2 - Njemački",
        "hu": "Alap szókincs 2 - Német",
        "it": "Lessico di base 2 - Tedesco",
        "lt": "Pagrindinis žodynėlis 2 - Vokiečių k.",
        "nl": "Basiswoordenschat 2 - Duits",
        "pl": "Słownictwo podstawowe 2 - Niemiecki",
        "pt": "Vocabulário básico 2 - Alemão",
        "ro": "Vocabularul de bază 2 - Germană",
        "ru": "Базовая лексика 2 - Немецкий",
        "sk": "Základná slovná zásoba 2 - Nemčina",
        "sl": "Osnovni besednjak 2 - Nemščina",
        "sq": "Fjalori Basic 2 - Gjermanisht",
        "sr": "Osnovni rečnik 2 - Nemačko",
        "tr": "Basit kelime bilgisi 2 - Almanca",
        "uk": "Словник базовий 2 - Німецько"
    },
    "DeutschH2academyprototyp": {
        "default": "Komptech H2’s Technikwortschatz",
        "en": "Komptech H2’s Technical vocabulary"
    },
    "DeutschHotellerieDemocontent": {
        "ar": "الفنادق - التجريبي",
        "default": "Hotels - Demo",
        "en": "Hotels - Demo",
        "fr": "Hôtels - Démo",
        "hr": "Hoteli - Demo",
        "it": "Hotel - Demo"
    },
    "DeutschHotellerieKomplett": {
        "default": "Hotellerie und Gastronomie (Deutsch)",
        "en": "Hotel industry and Gastronomy (German)",
        "es": "Industria hotelera y Gastronomía (Alemán)",
        "fr": "Industrie hôtelière et Gastronomie (Allemand)",
        "it": "Industria alberghiera e Gastronomia (Tedesco)"
    },
    "DeutschHotellerieNurGastronomie": {
        "default": "Gastronomie (Deutsch)",
        "en": "Gastronomy (German)",
        "es": "Gastronomía (Alemán)",
        "fr": "Gastronomie (Allemand)",
        "it": "Gastronomia (Tedesco)"
    },
    "DeutschHotellerieNurHotel": {
        "default": "Hotellerie (Deutsch)",
        "en": "Hotel industry (German)",
        "es": "Industria hotelera (Alemán)",
        "fr": "Industrie hôtelière (Allemand)",
        "it": "Industria alberghiera (Tedesco)"
    },
    "DeutschIntermediate": {
        "ar": "2 الألمانية - اساسي",
        "bg": "2 Немски - Елементарно",
        "br": "2 Alemão - Básico",
        "ca": "2 Alemany - Elemental",
        "cz": "2 Německy - Základní",
        "default": "2 German - Elementary",
        "el": "2 Γερμανικά - Στοιχειώδης",
        "en": "2 German - Elementary",
        "er": "2 Alemán - Elemental",
        "es": "2 Alemán - Elemental",
        "ex": "2 Alemán - Elemental",
        "fa": "2 آلمانی- مقدماتی",
        "fr": "2 Allemand - Élémentaire",
        "hi": "2 जर्मन - प्राथमिक",
        "hr": "2 Njemački - Osnovni",
        "hu": "2 Német - Alapszint",
        "hy": "2 Գերմաներեն - Տարրական",
        "it": "2 Tedesco - Elementare",
        "ja": "2 ドイツ語 - 初級者",
        "ko": "2 독일어 - 초급",
        "lt": "2 Vokiečių k. - Pradinis",
        "nl": "2 Duitse - Elementair",
        "no": "2 Tysk - Lett",
        "pl": "2 Niemiecki - Podstawowy",
        "pt": "2 Alemão - Elementar",
        "ro": "2 Germană - Elementar",
        "ru": "2 Немецкий - Элементарный уровень",
        "rw": "2 Ikidage - Uwo ku rugero rwo hasi",
        "sk": "2 Nemčina - Elementárna úroveň",
        "sl": "2 Nemščina - Osnovno znanje",
        "sr": "2 Nemački - Osnovni",
        "sv": "2 Tysk - Elementärt",
        "sw": "2 Kijeremani - Ya msingi",
        "tr": "2 Almanca - Temel",
        "uk": "2 Німецька - Елементарно",
        "ur": "2 جرمن - ابتدائی",
        "zh": "2 德语 - 初級"
    },
    "DeutschKlinikpflegeDemocontent": {
        "default": "Klinikpflege",
        "en": "Hospital care",
        "pl": "Opieka Szpitalna"
    },
    "DeutschKlinikpflegeHueber": {
        "ar": "الألمانية في الرعاية",
        "bg": "Немски език в сестринството",
        "default": "Deutsch in der Pflege",
        "en": "German in nursing care",
        "es": "Alemán en enfermería",
        "fr": "L'allemand dans les soins infirmiers",
        "hr": "Nijemac u sestrinstvu",
        "hu": " Német az ápolásban",
        "it": "Tedesco in assistenza infermieristica",
        "nl": "Duits in de verpleging",
        "pl": "Niemiecki w opiece pielęgniarskiej",
        "pt": "Alemão em cuidados de enfermagem",
        "ro": "Germană la alăptare",
        "ru": "Немецкий в уходе",
        "sk": "Nemčina v ošetrovateľstve",
        "sr": "Nemac u sestrinstvu"
    },
    "DeutschLinara": {
        "ar": " الألمانية - رعاية منزلية",
        "bg": "Домашни грижи - Немски",
        "cz": "Domácí péče - Německy",
        "default": "Heimpflege - Deutsch",
        "en": "Home care - German",
        "es": "Cuidados en el hogar - Alemán",
        "fr": "Soins à domicile - Allemand",
        "hr": "Kućna njega - Njemački",
        "hu": "Otthoni ápolás - Német",
        "it": "Assistenza domiciliare - Tedesco",
        "lt": "Globos namai - Vokiečių k.",
        "pl": "Opieka domowa - Niemiecki",
        "ro": "Ingrijirea casei - Germană",
        "ru": "Домашний уход - Немецкий",
        "sk": "Domáca starostlivosť - Nemčina",
        "sl": "Nega na domu - Nemščina",
        "sr": "Kućna nega - Nemačko",
        "tr": "Evde bakım - Almanca",
        "uk": "Домашній догляд - Німецько"
    },
    "DeutschPIM": {
        "ar": "3 الألمانية - قبل المتوسط",
        "bg": "3 Немски - Предварителен междинен",
        "br": "3 Alemão - Pré-intermediário",
        "ca": "3 Alemany - Pre-intermedi",
        "cz": "3 Německy - Předběžně",
        "default": "3 German - Pre-intermediate",
        "el": "3 Γερμανικά - Προ-ενδιάμεσο",
        "en": "3 German - Pre-intermediate",
        "er": "3 Alemán - Preintermedio",
        "es": "3 Alemán - Preintermedio",
        "ex": "3 Alemán - Pre-intermedio",
        "fa": "3 آلمانی - متوسط رو به پایین",
        "fr": "3 Allemand - Pré-intermédiaire",
        "hi": "3 जर्मन - पूर्व मध्यवर्ती",
        "hr": "3 Njemački - Pred-srednji",
        "hu": "3 Német - Középhaladó",
        "hy": "3 Գերմաներեն - Նախամիջին",
        "it": "3 Tedesco - Pre-intermedio",
        "ja": "3 ドイツ語 - 中級下",
        "ko": "3 독일어 - 중하급",
        "lt": "3 Vokiečių k. - Žemesnis nei vidutinis",
        "nl": "3 Duitse - Pre-intermediate",
        "no": "3 Tysk - Pre-mellomliggende",
        "pl": "3 Niemiecki - Niższy średnio zaawansowany",
        "pt": "3 Alemão - Pré-intermédio",
        "ro": "3 Germană - Pre-intermediar",
        "ru": "3 Немецкий - Ниже среднего",
        "rw": "3 Ikidage - Ikibanziriza icyiciro cyo hagati",
        "sk": "3 Nemčina - Mierne pokročilý",
        "sl": "3 Nemščina - Nižji-srednji",
        "sr": "3 Nemački - Niži-srednji",
        "sv": "3 Tysk - Pre-mellanprodukt",
        "sw": "3 Kijeremani - Kabla ya ya kati",
        "tr": "3 Almanca - Alt orta seviye",
        "uk": "3 Німецька - Попередній проміжний",
        "ur": "3 جرمن - قبل از وسط",
        "zh": "3 德语 - 预中间"
    },
    "DeutschPersonal": {
        "de": "Mein persönliches Deutsch",
        "default": "Mein persönliches Deutsch"
    },
    "DeutschReinigung": {
        "ar": "خدمات تنظيف المباني - الألمانية",
        "bg": "Почистване на сгради - Немски",
        "cz": "Úklid budov  - Německy",
        "default": "Facility cleaning - German",
        "en": "Facility cleaning - German",
        "es": "Limpieza industrial - Alemán",
        "fa": "نظافت ساختمان - آلمانی",
        "fr": "Nettoyage des bâtiments - Allemand",
        "hr": "Čišćenje zgrada - Njemački",
        "hu": "Épület takarítás - Német",
        "pl": "Sprzątanie budynku - Niemiecki",
        "ro": "Curățenia în clădire - Germană",
        "ru": "Уборка зданий - Немецкий",
        "sk": "Čistenie budov - Nemčina",
        "sl": "Čiščenje zgradb - Nemščina",
        "sr": "Čišćenje zgrada - Nemačko",
        "tr": "Bina temizliği - Almanca"
    },
    "DeutschReinigungAT": {
        "ar": "خدمات تنظيف المباني - الألمانية",
        "bg": "Почистване на сгради - Немски",
        "cz": "Úklid budov  - Německy",
        "default": "Facility cleaning - German",
        "en": "Facility cleaning - German",
        "es": "Limpieza industrial - Alemán",
        "fa": "نظافت ساختمان - آلمانی",
        "fr": "Nettoyage des bâtiments - Allemand",
        "hr": "Čišćenje zgrada - Njemački",
        "hu": "Épület takarítás - Német",
        "pl": "Sprzątanie budynku - Niemiecki",
        "ro": "Curățenia în clădire - Germană",
        "ru": "Уборка зданий - Немецкий",
        "sk": "Čistenie budov - Nemčina",
        "sl": "Čiščenje zgradb - Nemščina",
        "sr": "Čišćenje zgrada - Nemačko",
        "tr": "Bina temizliği - Almanca"
    },
    "DeutschReinigungMyTraining": {
        "default": "Gebäudereinigung - mytraining.at"
    },
    "DeutschTestBauInnungBosnian": {
        "default": "TestBauInnungBosnian"
    },
    "EnglishAwoAufbauwortschatz": {
        "br": "Inglês - Vocabulário avançado",
        "ca": "Anglès - Vocabulari avançat",
        "cz": "Angličtina - Pokročilá slovní zásoba",
        "de": "Englisch - Aufbauwortschatz",
        "default": "English - Advanced Vocabulary",
        "er": "Inglés - Vocabulario avanzado",
        "es": "Inglés - Vocabulario avanzado",
        "ex": "Inglés - Vocabulario avanzado",
        "fr": "Anglais - Vocabulaire avancé",
        "hr": "Engleski - Napredni rječnik",
        "hu": "Angol - Haladó szókincs",
        "it": "Inglese - Vocabolario avanzato",
        "ja": "英語 - 上級者向け語彙",
        "ko": "영어 - 고급 어휘",
        "nl": "Engels - Geavanceerd vocabulaire",
        "pl": "Angielski - Słownictwo zaawansowane",
        "pt": "Inglês - Vocabulário avançado",
        "ro": "Engleză - Vocabular avansat",
        "ru": "Английский - Расширенный словарный запас",
        "sk": "Angličtina - Slovník pre pokročilých",
        "sl": "Angleščina - Napreden besedni zaklad",
        "sr": "Engleski  - Napredni rečnik",
        "tr": "İngilizce - Ileri kelime hazinesi",
        "zh": "英文 - 高级词汇"
    },
    "EnglishBeginner": {
        "ar": "1 الإنجليزية - مُبتدىء",
        "bg": "1 Английски - Начинаещ",
        "br": "1 Inglês - Iniciante",
        "ca": "1  Anglès - Principiant",
        "cz": "1 Angličtina - Začátečník",
        "de": "1 Englisch - Anfänger",
        "default": "1 English - Beginner",
        "el": "1 Αγγλικά - Αρχάριος",
        "er": "1 Inglés - Principiante",
        "es": "1 Inglés - Principiante",
        "ex": "1 Inglés - Principiante",
        "fa": "1 انگلیسی - مبتدی",
        "fr": "1 Anglais - Débutant",
        "hi": "1 अंग्रेज़ी - प्राथमिक",
        "hr": "1 Engleski - Početnik",
        "hu": "1 Angol - Kezdő",
        "hy": "1 Անգլերեն - Սկսնակ",
        "it": "1 Inglese - Principiante",
        "ja": "1 英語 - 初心者",
        "ko": "1 영어 - 초심자",
        "lt": "1 Anglų k. - Pradedantysis",
        "nl": "1 Engels - Beginner",
        "no": "1 Engelsk - Begynner",
        "pl": "1 Angielski - Początkujący",
        "pt": "1 Inglês - Principiante",
        "ro": "1 Engleză - Începător",
        "ru": "1 Английский - Начинающий",
        "rw": "1 Icyongereza - Umutangizi",
        "sk": "1 Angličtina - Začiatočník",
        "sl": "1 Angleščina - Začetnik",
        "sr": "1 Engleski - Početni",
        "sv": "1 Engelsk - Nybörjare",
        "sw": "1 Kingereza - Ayeanza",
        "tr": "1 İngilizce - Başlangıç",
        "uk": "1  Англійська - Початківець",
        "ur": "1 انگریزی - نوآموز",
        "zh": "1 英语 - 新手"
    },
    "EnglishEDVECkentei4": {
        "default": "OK! Kentei Grade4",
        "ja": "OK! Kentei Grade4"
    },
    "EnglishEdvecKNTdemo": {
        "default": "KNT",
        "ja": "KNT"
    },
    "EnglishEdvecOlympicsAdvanced": {
        "ar": "English for the Tokyo Games -Advanced-",
        "de": "English for the Tokyo Games -Advanced-",
        "default": "English for the Tokyo Games -Advanced-",
        "fr": "English for the Tokyo Games -Advanced-",
        "ja": "English for the Tokyo Games -Advanced-"
    },
    "EnglishEdvecOlympicsBasic": {
        "ar": "English for the Tokyo Games -Basic-",
        "de": "English for the Tokyo Games -Basic-",
        "default": "English for the Tokyo Games -Basic-",
        "fr": "English for the Tokyo Games -Basic-",
        "ja": "English for the Tokyo Games -Basic-"
    },
    "EnglishFootball": {
        "ar": "الإنجليزية - كرة القدم",
        "bg": "Английски - Футбол",
        "br": "Inglês - Futebol",
        "cz": "Angličtina - Fotbal",
        "de": "Englisch - Fußball",
        "default": "English - Football",
        "el": "Αγγλικά - Ποδόσφαιρο",
        "er": "Inglés - Fútbol",
        "es": "Inglés - Fútbol",
        "ex": "Inglés - Futbol",
        "fa": "Inglés - فوتبال",
        "fr": "Anglais - Football",
        "hi": "अंग्रेजी - फुटबॉल",
        "hr": "Engleski - Nogomet",
        "hu": "Angol - Futball",
        "hy": "Անգլերեն - Ֆուտբոլ",
        "it": "Inglese - Calcio",
        "ja": "英語 - サッカー",
        "ko": "영어 - 축구",
        "lt": "Anglų k. - Futbolas",
        "no": "Engelsk - Fotball",
        "pl": "Angielski - Piłka nożna",
        "pt": "Inglês - Futebol",
        "ro": "Engleză - Fotbal",
        "ru": "Английский - Футбол",
        "rw": "Icyongereza - Umupira w'amaguru",
        "sk": "Angličtina - Futbal",
        "sl": "Angleščina - Nogomet",
        "sr": "Engleski - fudbal",
        "sv": "Engelska - Fotboll",
        "sw": "Kingereza - Kandanda",
        "tr": "İngilizce - Futbol",
        "ur": "انگریزی - فٹ بال",
        "zh": "英语 - 足球"
    },
    "EnglishGruWoCoolista": {
        "ar": "مفردات اساسية - الإنجليزية",
        "bg": "Основен речник - Английски",
        "ca": "Vocabulari bàsic - Anglès",
        "cz": "Základní slovní zásoba - Česky",
        "de": "Grundwortschatz - Englisch",
        "default": "Basic vocabulary - English",
        "el": "Βασικό λεξιλόγιο - Αγγλικά",
        "es": "Vocabulario básico - Inglés",
        "fa": "واژگان پایه - انگلیسی",
        "fr": "Vocabulaire de base - Anglais",
        "hr": "Osnovni vokabular - Engleski",
        "hu": "Alap szókincs - Angol",
        "it": "Lessico di base - Inglese",
        "lt": "Pagrindinis žodynėlis - Anglų k.",
        "nl": "Basiswoordenschat - Engelsk",
        "pl": "Słownictwo podstawowe - Angielski",
        "pt": "Vocabulário básico - Inglês",
        "ro": "Vocabularul de bază - Engleză",
        "ru": "Базовая лексика - Английский",
        "sk": "Základná slovná zásoba - Angličtina",
        "sl": "Osnovni besednjak - Angleščina",
        "sq": "Fjalori Basic - Anglisht",
        "sr": "Osnovni rečnik - Engleski ",
        "tr": "Basit kelime bilgisi - İngilizce",
        "uk": "Словник базовий - Українсько"
    },
    "EnglishGruwo": {
        "ar": "مفردات اساسية - الإنجليزية",
        "bg": "Основен речник - Английски",
        "ca": "Vocabulari bàsic - Anglès",
        "cz": "Základní slovní zásoba - Angličtina",
        "de": "Grundwortschatz - Englisch 1",
        "default": "Basic vocabulary - English",
        "el": "Βασικό λεξιλόγιο - Αγγλικά",
        "es": "Vocabulario básico - Inglés",
        "fa": "واژگان پایه - انگلیسی",
        "fr": "Vocabulaire de base - Anglais",
        "hr": "Osnovni vokabular - Engleski",
        "hu": "Alap szókincs - Angol",
        "it": "Lessico di base - Inglese",
        "lt": "Pagrindinis žodynėlis - Anglų k.",
        "nl": "Basiswoordenschat - Engelsk",
        "pl": "Słownictwo podstawowe - Angielski",
        "pt": "Vocabulário básico - Inglês",
        "ro": "Vocabularul de bază - Engleză",
        "ru": "Базовая лексика - Английский",
        "sk": "Základná slovná zásoba - Angličtina",
        "sl": "Osnovni besednjak - Angleščina",
        "sq": "Fjalori Basic - Anglisht",
        "sr": "Osnovni rečnik - Engleski ",
        "tr": "Basit kelime bilgisi - İngilizce",
        "uk": "Словник базовий - Українсько"
    },
    "EnglishH2academyprototyp": {
        "de": "Komptech H2’s Technikwortschatz",
        "default": "Komptech H2’s Technical vocabulary"
    },
    "EnglishHotelIndustryComplete": {
        "de": "Hotellerie und Gastronomie (Englisch)",
        "default": "Hotel industry and Gastronomy (English)",
        "es": "Industria hotelera y Gastronomía (Inglés)",
        "fr": "Industrie hôtelière et Gastronomie (Anglais)",
        "it": "Industria alberghiera e Gastronomia (Inglese)"
    },
    "EnglishHotelIndustryOnlyHotel": {
        "de": "Hotellerie (Englisch)",
        "default": "Hotel industry (English)",
        "es": "Industria hotelera (Inglés)",
        "fr": "Industrie hôtelière (Anglais)",
        "it": "Industria alberghiera (Inglese)"
    },
    "EnglishHotelIndustryOnlyRestaurant": {
        "de": "Gastronomie (Englisch)",
        "default": "Gastronomy (English)",
        "es": "Gastronomía (Inglés)",
        "fr": "Gastronomie (Anglais)",
        "it": "Gastronomia (Inglese)"
    },
    "EnglishHotelindustryDemoLearntec": {
        "de": "Hotels - Demo Learntec",
        "default": "Hotels - Demo Learntec"
    },
    "EnglishHotelsDemo": {
        "de": "Hotels - Demo",
        "default": "Hotels - Demo"
    },
    "EnglishIntermediate": {
        "ar": "2 الإنجليزية - اساسي",
        "bg": "2 Английски - Елементарно",
        "br": "2 Inglês - Básico",
        "ca": "2  Anglès - Elemental",
        "cz": "2 Angličtina - Základní",
        "de": "2 Englisch - Grundlagen",
        "default": "2 English - Elementary",
        "el": "2 Αγγλικά - Στοιχειώδης",
        "er": "2 Inglés - Elemental",
        "es": "2 Inglés - Elemental",
        "ex": "2 Inglés - Elemental",
        "fa": "2 انگلیسی - مقدماتی",
        "fr": "2 Anglais - Élémentaire",
        "hi": "2 अंग्रेजी - प्राथमिक",
        "hr": "2 Engleski - Osnovni",
        "hu": "2 Angol - Alapszint",
        "hy": "2 Անգլերեն - Տարրական",
        "it": "2 Inglese - Elementare",
        "ja": "2 英語 - 初級者",
        "ko": "2 영어 - 초급",
        "lt": "2 Anglų k. - Pradinis",
        "nl": "2 Engels - Elementair",
        "no": "2 Engelsk - Lett",
        "pl": "2 Angielski - Podstawowy",
        "pt": "2 Inglês - Elementar",
        "ro": "2 Engleză - Elementar",
        "ru": "2 Английский - Элементарный уровень",
        "rw": "2 Icyongereza - Uwo ku rugero rwo hasi",
        "sk": "2 Angličtina - Elementárna úroveň",
        "sl": "2 Angleščina - Osnovno znanje",
        "sr": "2 Engleski - Osnovni",
        "sv": "2 Engelsk - Elementärt",
        "sw": "2 Kingereza - Ya msingi",
        "tr": "2 İngilizce - Temel",
        "uk": "2  Англійська - Елементарно",
        "ur": "2 انگریزی - ابتدائی",
        "zh": "2 英语 - 初级"
    },
    "EnglishIntermediateB1": {
        "ar": "4 الإنجليزية - متوسّط",
        "br": "4 Inglês - Intermediário",
        "ca": "4 Anglès -  Intermedi",
        "cz": "4 Angličtina - Střední",
        "de": "4 Englisch - Mittelstufe",
        "default": "4 English - Intermediate",
        "er": "4 Inglés - Intermedio",
        "es": "4 Inglés - Intermediario",
        "ex": "4 Inglés - Intermedio",
        "fr": "4 Anglais - Intermédiaire",
        "hr": "4 Engleski - Srednji",
        "hu": "4 Angol - Közepes nyelvi szint",
        "it": "4 Inglese - Intermediario",
        "ja": "4 英語 - 中級",
        "ko": "4 영어 - 중급",
        "nl": "4 Engels - Intermediair",
        "pl": "4 Angielski - Pośredni",
        "pt": "4 Inglês - Intermediário",
        "ro": "4 Engleză - Nivel intermediar",
        "ru": "4 Английский - промежуточный уровень",
        "sk": "4 Angličtina - Slovník pre mierne pokročilých",
        "sl": "4 Angleščina - Srednja",
        "sr": "4 Engleski  - srednji",
        "tr": "4 İngilizce - Orta düzey",
        "zh": "4 英文 - 中级",
        "zt": "4 英文- 中級"
    },
    "EnglishJapanEdvecSampleEnglish": {
        "default": "Edvec",
        "ja": "Edvec"
    },
    "EnglishKERNautomotive": {
        "de": "KERN Automotive",
        "default": "KERN Automotive"
    },
    "EnglishMacMillanBEstarter": {
        "default": "Business English Starter"
    },
    "EnglishMildenbergerHigh5": {
        "de": "high5 - Englisch für Kinder",
        "default": "high5 - Englisch für Kinder"
    },
    "EnglishModelContentGesamtInklEvents": {
        "default": "English4Models, Fashion & Beauty"
    },
    "EnglishModelingContent": {
        "default": "English4Models"
    },
    "EnglishModelingEvents": {
        "default": "English4Models - Events"
    },
    "EnglishModelmanagementDemoKoreanisch": {
        "default": "English4Models - Korean Version"
    },
    "EnglishMusic": {
        "ar": "English 4 MusicFans",
        "br": "English 4 MusicFans",
        "de": "English 4 MusicFans",
        "default": "English 4 MusicFans",
        "es": "English 4 MusicFans",
        "fr": "English 4 MusicFans",
        "it": "English 4 MusicFans",
        "ja": "English 4 MusicFans",
        "pl": "English 4 MusicFans",
        "ru": "English 4 MusicFans",
        "tr": "English 4 MusicFans"
    },
    "EnglishOxfordBusinessEnglishAdvanced": {
        "ar": "Oxford Business English - Advanced",
        "de": "Oxford Business English - Advanced",
        "default": "Oxford Business English - Advanced",
        "es": "Oxford Business English - Advanced",
        "fr": "Oxford Business English - Advanced",
        "it": "Oxford Business English - Advanced",
        "ja": "Oxford Business English - Advanced",
        "pl": "Oxford Business English - Advanced",
        "pt": "Oxford Business English - Advanced",
        "ru": "Oxford Business English - Advanced",
        "tr": "Oxford Business English - Advanced"
    },
    "EnglishOxfordBusinessEnglishElementary": {
        "default": "Oxford Business English - Elementary"
    },
    "EnglishOxfordBusinessEnglishIntermediate": {
        "ar": "Oxford Business English - Intermediate",
        "de": "Oxford Business English - Intermediate",
        "default": "Oxford Business English - Intermediate",
        "es": "Oxford Business English - Intermediate",
        "fr": "Oxford Business English - Intermediate",
        "it": "Oxford Business English - Intermediate",
        "ja": "Oxford Business English - Intermediate",
        "pl": "Oxford Business English - Intermediate",
        "pt": "Oxford Business English - Intermediate",
        "ru": "Oxford Business English - Intermediate",
        "tr": "Oxford Business English - Intermediate"
    },
    "EnglishOxfordBusinessEnglishPreIntermediate": {
        "default": "Oxford Business English - Pre-Intermediate"
    },
    "EnglishOxfordBusinessEnglishStarter": {
        "default": "Oxford Business English - Starter"
    },
    "EnglishOxfordBusinessEnglishUpperIntermediate": {
        "ar": "Oxford Business English - Upper Intermediate",
        "de": "Oxford Business English - Upper Intermediate",
        "default": "Oxford Business English - Upper Intermediate",
        "es": "Oxford Business English - Upper Intermediate",
        "fr": "Oxford Business English - Upper Intermediate",
        "it": "Oxford Business English - Upper Intermediate",
        "ja": "Oxford Business English - Upper Intermediate",
        "pl": "Oxford Business English - Upper Intermediate",
        "pt": "Oxford Business English - Upper Intermediate",
        "ru": "Oxford Business English - Upper Intermediate",
        "tr": "Oxford Business English - Upper Intermediate"
    },
    "EnglishPIM": {
        "ar": "3 الإنجليزية -  قبل االمتوسط ",
        "bg": "3 Английски - Предварителен междинен",
        "br": "3 Inglês - Pré-intermediário",
        "ca": "3 Anglès - Pre-intermedi",
        "cz": "3 Angličtina - Předběžně",
        "de": "3 Englisch - Erweiterte Grundlagen",
        "default": "3 English - Pre-intermediate",
        "el": "3 Αγγλικά - Προ-ενδιάμεσο",
        "er": "3 Inglés - Preintermedio",
        "es": "3 Inglés - Preintermedio",
        "ex": "3 Inglés - Pre-intermedio",
        "fa": "3 انگلیسی - متوسط رو به پایین",
        "fr": "3 Anglais - Pré-intermédiaire",
        "hi": "3 अंग्रेज़ी - पूर्व मध्यवर्ती",
        "hr": "3 Engleski - Pred-srednji",
        "hu": "3 Angol - Középhaladó",
        "hy": "3 Անգլերեն - Նախամիջին",
        "it": "3 Inglese - Pre-intermedio",
        "ja": "3 英語 - 中級下",
        "ko": "3 영어 - 중하급",
        "lt": "3 Anglų k. - Žemesnis nei vidutinis",
        "nl": "3 Engels - Pre-intermediate",
        "no": "3 Engelsk - Pre-mellomliggende",
        "pl": "3 Angielski - Niższy średnio zaawansowany",
        "pt": "3 Inglês - Pré-intermédio",
        "ro": "3 Engleză - Pré-intermédio",
        "ru": "3 Английский - Ниже среднего",
        "rw": "3 Icyongereza - Ikibanziriza icyiciro cyo hagati",
        "sk": "3 Angličtina - Mierne pokročilý",
        "sl": "3 Angleščina - Nižji-srednji",
        "sr": "3 Engleski - Niži-srednji",
        "sv": "3 Engelsk - Pre-mellanprodukt",
        "sw": "3 Kingereza - Kabla ya ya kati",
        "tr": "3 İngilizce - Alt orta seviye",
        "uk": "3 Англійська - попередній проміжний",
        "ur": "3 انگریزی - قبل از وسط",
        "zh": "3 英语 - 预中间"
    },
    "EnglishPersonal": {
        "default": "My personal English"
    },
    "EnglishReadOnSchuenemann": {
        "de": "Die Sprachzeitung",
        "default": "Die Sprachzeitung"
    },
    "EnglishReinigungEN": {
        "ar": "خدمات تنظيف المباني",
        "bg": "Почистване на сгради",
        "cz": "Úklid budov ",
        "de": "Reinigung Gebäude",
        "default": "Facility cleaning",
        "es": "Limpieza industrial",
        "fa": "نظافت ساختمان",
        "fr": "Nettoyage des bâtiments",
        "hr": "Čišćenje zgrada",
        "hu": "Épület takarítás",
        "nl": "Industriële Reiniging",
        "pl": "Sprzątanie budynku",
        "ro": "Curățenia în clădire",
        "ru": "Уборка зданий",
        "sk": "Čistenie budov",
        "sl": "Čiščenje zgradb",
        "sr": "Čišćenje zgrada",
        "tr": " Bina temizliği"
    },
    "EnglishSantillanaPublisherDemo": {
        "default": "Santillana Demo"
    },
    "EnglishTourismB1": {
        "ar": "الإنجليزية - متوسّط السياحة",
        "de": "Englisch - Tourismus Mittelstufe",
        "default": "English - Tourism Intermediate",
        "es": "Inglés - Tourismo Intermediario",
        "fr": "Anglais - Tourisme Intermédiaire",
        "it": "Inglese - Turismo Intermediario",
        "pl": "Angielski - Turystyka Pośredni",
        "pt": "Inglês - Turismo Intermediário",
        "ru": "Английский - туризм промежуточный уровень",
        "tr": "İngilizce - Turizm Orta düzey"
    },
    "EnglishTourismB2": {
        "ar": "الإنجليزية -  فوق المتوسط",
        "de": "Englisch - Tourismus Obere Mittelstufe",
        "default": "English - Tourism Upper Intermediate",
        "es": "Inglés - Tourismo Intermedio alto",
        "fr": "Anglais - Tourisme Intermédiaire supérieur",
        "it": "Inglese - Turismo Intermediario superiore",
        "pl": "Angielski - Turystyka Górny Pośredni",
        "pt": "Inglês - Turismo Intermediário superior",
        "ru": "Английский - туризм Верхний Промежуточный уровень",
        "tr": "İngilizce - Turizm Üst orta düzey"
    },
    "EnglishUpperIntermediateB2": {
        "ar": "5  فوق المتوسط - الإنجليزية",
        "br": "5 Inglês - Intermediário superior",
        "ca": "5  Anglès - Intermedi superior",
        "cz": "5 Angličtina - Horní střední",
        "de": "5 Englisch - Obere Mittelstufe",
        "default": "5 English - Upper Intermediate",
        "er": "5 Inglés - Intermedio alto",
        "es": "5 Inglés - Intermedio alto",
        "ex": "5 Inglés - Intermedio avanzado",
        "fr": "5 Anglais - Intermédiaire supérieur",
        "hr": "5 Engleski - Viši srednji",
        "hu": "5 Angol - Felső középső szint",
        "it": "5 Inglese - Intermediario superiore",
        "ja": "5 英語 - 中上級",
        "ko": "5 영어 - 중고급",
        "nl": "5 Engels - Bovenste Intermediair",
        "pl": "5 Angielski - Górny Pośredni",
        "pt": "5 Inglês - Intermediário superior",
        "ro": "5 Engleză - Nivel intermediar superior",
        "ru": "5 Английский - Верхний Промежуточный уровень",
        "sk": "5 Angličtina - Slovník pre stredne pokročilých",
        "sl": "5 Angleščina - Višja srednja",
        "sr": "5 Engleski  - viši-srednji",
        "tr": "5 İngilizce - Üst orta düzey",
        "zh": "5 英文 - 中高级",
        "zt": "5 英文 - 中高"
    },
    "EnglishZombiesChapter1and2": {
        "br": "English vs. Zombies",
        "de": "English vs. Zombies",
        "default": "English vs. Zombies",
        "es": "English vs. Zombies",
        "ja": "English vs. Zombies",
        "tr": "English vs. Zombies"
    },
    "FrenchBeginner": {
        "ar": "1 الفرنسية - مُبتدىء",
        "bg": "1 Френски - Начинаещ",
        "br": "1 Francês - Iniciante",
        "ca": "1 Francès - Principiant",
        "cz": "1 Francouzsky - Začátečník",
        "de": "1 Französisch - Anfänger",
        "default": "1 French - Beginner",
        "el": "1 Γαλλικά - Αρχάριος",
        "en": "1 French - Beginner",
        "er": "1 Francés - Principiante",
        "es": "1 Francés - Principiante",
        "ex": "1 Francés - Principiante",
        "fa": "1 فرانسوی - مبتدی",
        "hi": "1 फ्रेंच - नौसिखिया",
        "hr": "1 Francuski - Početnik",
        "hu": "1 Francia - Kezdő",
        "hy": "1 Ֆրանսերեն - Սկսնակ",
        "it": "1 Francese - Principiante",
        "ja": "1 フランス語 - 初心者",
        "ko": "1 프랑스어 - 초심자",
        "lt": "1 Prancūzų k. - Pradedantysis",
        "nl": "1  Frans - Beginner",
        "no": "1 Fransk - Begynner",
        "pl": "1 Francuski - Początkujący",
        "pt": "1 Francês - Principiante",
        "ro": "1 Franceză - Începător",
        "ru": "1 Французский - Начинающий",
        "rw": "1 Igifaransa - Umutangizi",
        "sk": "1 Francúzština - Začiatočník",
        "sl": "1 Francoščina - Začetnik",
        "sr": "1 Francuski - Početni",
        "sv": "1 Franska - Nybörjare",
        "sw": "1 Kifaransa - Ayeanza",
        "tr": "1 Fransızca - Başlangıç",
        "uk": "1 Французька - Початківець",
        "ur": "1 فرانسیسی - نوآموز",
        "zh": "1 法语 - 新手"
    },
    "FrenchFootball": {
        "ar": "الفرنسية - كرة القدم",
        "bg": "Френски - Футбол",
        "br": "Francês - Futebol",
        "cz": "Francouzsky - Fotbal",
        "de": "Französisch - Fußball",
        "default": "French - Football",
        "el": "Γαλλικά - Ποδόσφαιρο",
        "en": "French - Football",
        "er": "Francés - Fútbol",
        "es": "Francés - Fútbol",
        "ex": "Francés - Futbol",
        "fa": "فرانسوی - فوتبال",
        "hi": "फ्रेंच - फुटबॉल",
        "hr": "Francuski - Nogomet",
        "hu": "Francia - Futball",
        "hy": "Ֆրանսերեն - Ֆուտբոլ",
        "it": "Francese - Calcio",
        "ja": "フランス語 - サッカー",
        "ko": "프랑스어 - 축구",
        "lt": "Prancūzų k. - Futbolas",
        "no": "Fransk - Fotball",
        "pl": "Francuski - Piłka nożna",
        "pt": "Francês - Futebol",
        "ro": "Franceză - Fotbal",
        "ru": "Французский - Футбол",
        "rw": "Igifaransa - Umupira w'amaguru",
        "sk": "Francúzština - Futbal",
        "sl": "Francoščina - Nogomet",
        "sr": "Francuski - fudbal",
        "sv": "Franska - Fotboll",
        "sw": "Kifaransa - Kandanda",
        "tr": "Fransızca - Futbol",
        "ur": "فرانسیسی - فٹ بال",
        "zh": "法语 - 足球"
    },
    "FrenchIntermediate": {
        "ar": "2 الفرنسية - اساسي",
        "bg": "2 Френски - Елементарно",
        "br": "2 Francês - Básico",
        "ca": "2 Francès - Elemental",
        "cz": "2 Francouzsky - Základní",
        "de": "2 Französisch - Grundlagen",
        "default": "2 French - Elementary",
        "el": "2 Γαλλικά - Στοιχειώδης",
        "en": "2 French - Elementary",
        "er": "2 Francés - Elemental",
        "es": "2 Francés - Elemental",
        "ex": "2 Francés - Elemental",
        "fa": "2 فرانسوی - مقدماتی",
        "hi": "2 फ्रेंच - प्राथमिक",
        "hr": "2 Francuski - Osnovni",
        "hu": "2 Francia - Alapszint",
        "hy": "2 Ֆրանսերեն - Տարրական",
        "it": "2 Francese - Elementare",
        "ja": "2 フランス語 - 初級者",
        "ko": "2 프랑스어 - 초급",
        "lt": "2 Prancūzų k. - Pradinis",
        "nl": "2  Frans - Elementair",
        "no": "2 Fransk - Lett",
        "pl": "2 Francuski - Podstawowy",
        "pt": "2 Francês - Elementar",
        "ro": "2 Franceză - Elementar",
        "ru": "2 Французский - Элементарный уровень",
        "rw": "2 Igifaransa - Uwo ku rugero rwo hasi",
        "sk": "2 Francúzština - Elementárna úroveň",
        "sl": "2 Francoščina - Osnovno znanje",
        "sr": "2 Francuski - Osnovni",
        "sv": "2 Franska - Elementärt",
        "sw": "2 Kifaransa - Ya msingi",
        "tr": "2 Fransızca - Temel",
        "uk": "2 Французька - Елементарно",
        "ur": "2 فرانسیسی - ابتدائی",
        "zh": "2 法语 - 初级"
    },
    "FrenchPIM": {
        "ar": "3 الفرنسية -  قبل االمتوسط",
        "bg": "3 Френски - Предварителен междинен",
        "br": "3 Francês - Pré-intermediário",
        "ca": "3 Francès - Pre-intermedi",
        "cz": "3 Francouzsky - Předběžně",
        "de": "3 Französisch - Erweiterte Grundlagen",
        "default": "3 French - Pre-intermediate",
        "el": "3 Γαλλικά - Προ-ενδιάμεσο",
        "en": "3 French - Pre-intermediate",
        "er": "3 Francés - Preintermedio",
        "es": "3 Francés - Preintermedio",
        "ex": "3 Francés - Preintermedio",
        "fa": "3 فرانسوی - متوسط رو به پایین",
        "hi": "3 फ्रेंच - पूर्व मध्यवर्ती",
        "hr": "3 Francuski - Pred-srednji",
        "hu": "3 Francia - Középhaladó",
        "hy": "3 Ֆրանսերեն - Նախամիջին",
        "it": "3 Francese - Pre-intermedio",
        "ja": "3 フランス語 - 中級下",
        "ko": "3 프랑스어 - 중하급",
        "lt": "3 Prancūzų k. - Žemesnis nei vidutinis",
        "nl": "3  Frans - Pre-intermediate",
        "no": "3 Fransk - Pre-mellomliggende",
        "pl": "3 Francuski - Niższy średnio zaawansowany",
        "pt": "3 Francês - Pré-intermédio",
        "ro": "3 Franceză - Pre-intermediar",
        "ru": "3 Французский - Ниже среднего",
        "rw": "3 Igifaransa - Ikibanziriza icyiciro cyo hagati",
        "sk": "3 Francúzština - Mierne pokročilý",
        "sl": "3 Francoščina - Nižji-srednji",
        "sr": "3 Francuski - Niži-srednji",
        "sv": "3 Franska - Pre-mellanprodukt",
        "sw": "3 Kifaransa - Kabla ya ya kati",
        "tr": "3 Fransızca - Alt orta seviye",
        "uk": "3 Французька -  попередній проміжний",
        "ur": "3 فرانسیسی - قبل از وسط",
        "zh": "3 法语 - 预中间"
    },
    "FrenchPersonal": {
        "default": "Mon français personnel"
    },
    "ItalianBeginner": {
        "ar": "1 الإيطالية - مُبتدىء",
        "bg": "1 Италиански - Начинаещ",
        "br": "1 Italiano - Iniciante",
        "ca": "1 Italià - Principiant",
        "cz": "1 Italsky - Začátečník",
        "de": "1 Italienisch - Anfänger",
        "default": "1 Italian - Beginner",
        "el": "1 Ιταλικά - Αρχάριος",
        "en": "1 Italian - Beginner",
        "er": "1 Italiano - Principiante",
        "es": "1 Italiano - Principiante",
        "ex": "1 Italiano - Principiante",
        "fa": "1 ایتالیایی - مبتدی",
        "fr": "1 Italien - Débutant",
        "hi": "1 इतालवी - नौसिखिया",
        "hr": "1 Talijanski - Početnik",
        "hu": "1 Olasz - Kezdő",
        "hy": "1 Իտալերեն - Սկսնակ",
        "ja": "1 イタリア語 - 初心者",
        "ko": "1 이탈리아어 - 초심자",
        "lt": "1 Italų k. - Pradedantysis",
        "nl": "1 Italiaans - Beginner",
        "no": "1 Italiensk - Begynner",
        "pl": "1 Włoski - Początkujący",
        "pt": "1 Italiano - Principiante",
        "ro": "1 Italiană - Începător",
        "ru": "1 Итальянский - Начинающий",
        "rw": "1 Igitaliyani - Umutangizi",
        "sk": "1 Taliančina - Začiatočník",
        "sl": "1 Italijanščina - Začetnik",
        "sr": "1 Italijanski - Početni",
        "sv": "1 Italienska - Nybörjare",
        "sw": "1 Kiitalia - Ayeanza",
        "tr": "1 İtalyanca - Başlangıç",
        "uk": "1 Італійська - Початківець",
        "ur": "1 اطالوی - نوآموز",
        "zh": "1 意大利语 - 新手"
    },
    "ItalianFootball": {
        "ar": "الإيطالية -  كرة القدم",
        "bg": "Италиански - Футбол",
        "br": "Italiano - Futebol",
        "cz": "Italsky - Fotbal",
        "de": "Italienisch - Fußball",
        "default": "Italian - Football",
        "el": "Ιταλικά - Ποδόσφαιρο",
        "en": "Italian - Football",
        "er": "Italiano - Fútbol",
        "es": "Italiano - Fútbol",
        "ex": "Italiano -  Futbol",
        "fa": "ایتالیایی - فوتبال",
        "fr": "Italien - Football",
        "hi": "इतालवी - फुटबॉल",
        "hr": "Talijanski - Nogomet",
        "hu": "Olasz - Futball",
        "hy": "Իտալերեն - Ֆուտբոլ",
        "ja": "イタリア語 - サッカー",
        "ko": "이탈리아어 - 축구",
        "lt": "Italų k. - Futbolas",
        "no": "Italiensk - Fotball",
        "pl": "Włoski - Piłka nożna",
        "pt": "Italiano - Futebol",
        "ro": "Italiană - Fotbal",
        "ru": "Итальянский - Футбол",
        "rw": "Igitaliyani - Umupira w'amaguru",
        "sk": "Taliančina - Futbal",
        "sl": "Italijanščina - Nogomet",
        "sr": "Italijanski - fudbal",
        "sv": "Italienska - Fotboll",
        "sw": "Kiitalia - Kandanda",
        "tr": "İtalyanca - Futbol",
        "ur": "اطالوی - فٹ بال",
        "zh": "意大利语 - 足球"
    },
    "ItalianIntermediate": {
        "ar": "2 الإيطالية - اساسي",
        "bg": "2 Италиански - Елементарно",
        "br": "2 Italiano - Básico",
        "ca": "2 Italià - Elemental",
        "cz": "2 Italsky - Základní",
        "de": "2 Italienisch - Grundlagen",
        "default": "2 Italian - Elementary",
        "el": "2 Ιταλικά - Στοιχειώδης",
        "en": "2 Italian - Elementary",
        "er": "2 Italiano - Elemental",
        "es": "2 Italiano - Elemental",
        "ex": "2 Italiano - Elemental",
        "fa": "2 ایتالیایی - مقدماتی",
        "fr": "2 Italien - Élémentaire",
        "hi": "2 इतालवी - प्राथमिक",
        "hr": "2 Talijanski - Osnovni",
        "hu": "2 Olasz - Alapszint",
        "hy": "2 Իտալերեն - Տարրական",
        "ja": "2 イタリア語 - 初級者",
        "ko": "2 이탈리아어 - 초급",
        "lt": "2 Italų k. - Pradinis",
        "nl": "2 Italiaans - Elementair",
        "no": "2 Italiensk - Lett",
        "pl": "2 Włoski - Podstawowy",
        "pt": "2 Italiano - Elementar",
        "ro": "2 Italiană - Elementar",
        "ru": "2 Итальянский - Элементарный уровень",
        "rw": "2 Igitaliyani - Uwo ku rugero rwo hasi",
        "sk": "2 Taliančina - Elementárna úroveň",
        "sl": "2 Italijanščina - Osnovno znanje",
        "sr": "2 Italijanski - Osnovni",
        "sv": "2 Italienska - Elementärt",
        "sw": "2 Kiitalia - Ya msingi",
        "tr": "2 İtalyanca - Temel",
        "uk": "2 Італійська - Елементарно",
        "ur": "2 اطالوی - ابتدائی",
        "zh": "2 意大利语 - 初级"
    },
    "ItalianPIM": {
        "ar": "3 الإيطالية -  قبل االمتوسط",
        "bg": "3 Италиански - Предварителен междинен",
        "br": "3 Italiano - Pré-intermediário",
        "ca": "3 Italià - Pre-intermedi",
        "cz": "3 Italsky - Předběžně",
        "de": "3 Italienisch - Erweiterte Grundlagen",
        "default": "3 Italian - Pre-intermediate",
        "el": "3 Ιταλικά - Προ-ενδιάμεσο",
        "en": "3 Italian - Pre-intermediate",
        "er": "3 Italiano - Preintermedio",
        "es": "3 Italiano - Preintermedio",
        "ex": "3 Italiano - Pre-intermedio",
        "fa": "3 ایتالیایی - متوسط رو به پایین",
        "fr": "3 Italien - Pré-intermédiaire",
        "hi": "3 इतालवी - पूर्व मध्यवर्ती",
        "hr": "3 Talijanski - Pred-srednji",
        "hu": "3 Olasz - Középhaladó",
        "hy": "3 Իտալերեն - Նախամիջին",
        "ja": "3 イタリア語 - 中級下",
        "ko": "3 이탈리아어 - 중하급",
        "lt": "3 Italų k. - Žemesnis nei vidutinis",
        "nl": "3 Italiaans - Pre-intermediate",
        "no": "3 Italiensk - Pre-mellomliggende",
        "pl": "3 Włoski - Niższy średnio zaawansowany",
        "pt": "3 Italiano - Pré-intermédio",
        "ro": "3 Italiană - Pre-intermediar",
        "ru": "3 Итальянский - Ниже среднего",
        "rw": "3 Igitaliyani - Ikibanziriza icyiciro cyo hagati",
        "sk": "3 Taliančina - Mierne pokročilý",
        "sl": "3 Italijanščina - Nižji-srednji",
        "sr": "3 Italijanski - Niži-srednji",
        "sv": "3 Italienska - Pre-mellanprodukt",
        "sw": "3 Kiitalia - Kabla ya ya kati",
        "tr": "3 İtalyanca - Alt orta seviye",
        "uk": "3 Італійська - попередній проміжний",
        "ur": "3 اطالوی - قبل از وسط",
        "zh": "3 意大利语 - 预中间"
    },
    "ItalianPersonal": {
        "default": "Il mio italiano personale"
    },
    "JapaneseEdvecMyjtBasic": {
        "de": "MyJT Basic Japanese 100",
        "default": "MyJT Basic Japanese 100",
        "en": "MyJT Basic Japanese 100"
    },
    "JapaneseEdvecMyjtIntermediate": {
        "de": "MyJT Intermediate Japanese 1600",
        "default": "MyJT Intermediate Japanese 1600",
        "en": "MyJT Intermediate Japanese 1600"
    },
    "JapaneseEdvecMyjtIntermediateDEMO": {
        "de": "DEMO MyJT Intermediate Japanese 1600",
        "default": "DEMO MyJT Intermediate Japanese 1600",
        "en": "DEMO MyJT Intermediate Japanese 1600"
    },
    "JapaneseJapanNihongoSampleJapanese": {
        "default": "Nihongo dojo",
        "en": "Nihongo dojo"
    },
    "PortugueseBeginner": {
        "ar": "1 البرتغالية - مُبتدىء",
        "bg": "1 Португалски - Начинаещ",
        "br": "1 Português - Iniciante",
        "ca": "1 Portuguès - Principiant",
        "cz": "1 Portugalsky - Začátečník",
        "de": "1 Portugiesisch - Anfänger",
        "default": "1 Portuguese - Beginner",
        "el": "1 Πορτογαλικά - Αρχάριος",
        "en": "1 Portuguese - Beginner",
        "er": "1 Portugués - Principiante",
        "es": "1 Portugués - Principiante",
        "ex": "1 Portugués - Principiante",
        "fa": "1 پرتغالی - مبتدی",
        "fr": "1 Portugais - Débutant",
        "hi": "1 पुर्तगाली - नौसिखिया",
        "hr": "1 Portugalski - Početnik",
        "hu": "1 Portugál -  Kezdő",
        "hy": "1 Պորտուգալերեն - Սկսնակ",
        "it": "1 Portoghese - Principiante",
        "ja": "1 ポルトガル語 - ",
        "ko": "1 포르투갈어 - 초심자",
        "lt": "1 Portugalų k. - Pradedantysis",
        "nl": "1  Portugees - Beginner",
        "no": "1 Portugisisk - Begynner",
        "pl": "1 Portugalski - Początkujący",
        "ro": "1 Portugheză - Începător",
        "ru": "1 Португальский - Начинающий",
        "rw": "1 Igiporutugali - Umutangizi",
        "sk": "1 Portugalčina - Začiatočník",
        "sl": "1 Portugalščina - Začetnik",
        "sr": "1 Portugalski - Početni",
        "sv": "1 Portugisiska - Nybörjare",
        "sw": "1 Kireno - Ayeanza",
        "tr": "1 Portekizce - Başlangıç",
        "uk": "1 Португальська - Початківець",
        "ur": "1 پرتگالی - نوآموز",
        "zh": "1 葡萄牙语 - 新手"
    },
    "PortugueseFootball": {
        "ar": "البرتغالية - كرة القدم",
        "bg": "Португалски - Футбол",
        "br": "Português - Futebol",
        "cz": "Portugalsky - Fotbal",
        "de": "Portugiesisch - Fußball",
        "default": "Portuguese - Football",
        "el": "Πορτογαλικά - Ποδόσφαιρο",
        "en": "Portuguese - Football",
        "er": "Portugués - Fútbol",
        "es": "Portugués - Fútbol",
        "ex": "Portugués - Fútbol",
        "fa": "پرتغالی - فوتبال",
        "fr": "Portugais - Football",
        "hi": "पुर्तगाली - फुटबॉल",
        "hr": "Portugalski - Nogomet",
        "hu": "Portugál -  Futball",
        "hy": "Պորտուգալերեն - Ֆուտբոլ",
        "it": "Portoghese - Calcio",
        "ja": "ポルトガル語 - サッカー",
        "ko": "포르투갈어 - 축구",
        "lt": "Portugalų k. - Futbolas",
        "no": "Portugisisk - Fotball",
        "pl": "Portugalski - Piłka nożna",
        "ro": "Portugheză - Fotbal",
        "ru": "Португальский - Футбол",
        "rw": "Igiporutugali - Umupira w'amaguru",
        "sk": "Portugalčina - Futbal",
        "sl": "Portugalščina - Nogomet",
        "sr": "Portugalski - Fudbal",
        "sv": "Portugisiska - Fotboll",
        "sw": "Kireno - Kandanda",
        "tr": "Portekizce - Futbol",
        "ur": "پرتگالی - فٹ بال",
        "zh": "葡萄牙语 - 足球"
    },
    "PortugueseIntermediate": {
        "ar": "2 البرتغالية - اساسي",
        "bg": "2 Португалски - Елементарно",
        "br": "2 Português - Básico",
        "ca": "2 Portuguès - Elemental",
        "cz": "2 Portugalsky - Základní",
        "de": "2 Portugiesisch - Grundlagen",
        "default": "2 Portuguese - Elementary",
        "el": "2 Πορτογαλικά - Στοιχειώδης",
        "en": "2 Portuguese - Elementary",
        "er": "2 Portugués - Elemental",
        "es": "2 Portugués - Elemental",
        "ex": "2 Portugués - Elemental",
        "fa": "2 پرتغالی - مقدماتی",
        "fr": "2 Portugais - Élémentaire",
        "hi": "2 पुर्तगाली - प्राथमिक",
        "hr": "2 Portugalski - Osnovni",
        "hu": "2 Portugál - Alapszint",
        "hy": "2 Պորտուգալերեն - Տարրական",
        "it": "2 Portoghese - Elementare",
        "ja": "2 ポルトガル語 - 初級者",
        "ko": "2 포르투갈어 - 초급",
        "lt": "2 Portugalų k. - Pradinis",
        "nl": "2  Portugees - Elementair",
        "no": "2 Portugisisk - Lett",
        "pl": "2 Portugalski - Podstawowy",
        "ro": "2 Portugheză - Elementar",
        "ru": "2 Португальский - Элементарный",
        "rw": "2 Igiporutugali - Uwo ku rugero rwo hasi",
        "sk": "2 Portugalčina - Elementárna úroveň",
        "sl": "2 Portugalščina - Osnovno ",
        "sr": "2 Portugalski - Osnovni",
        "sv": "2 Portugisiska - Elementärt",
        "sw": "2 Kireno - Ya msingi",
        "tr": "2 Portekizce - Temel",
        "uk": "1 Португальська - Елементарно",
        "ur": "2 پرتگالی - ابتدائی",
        "zh": "2 葡萄牙语 - 初级"
    },
    "PortuguesePIM": {
        "ar": "3 البرتغالية -  قبل االمتوسط ",
        "bg": "3 Португалски - Предварителен междинен",
        "br": "3 Português - Pré-intermediário",
        "ca": "3 Portuguès - Pre-intermedi",
        "cz": "3 Portugalsky - Předběžně",
        "de": "3 Portugiesisch - Erweiterte Grundlagen",
        "default": "3 Portuguese - Pre-intermediate",
        "el": "3 Πορτογαλικά - Προ-ενδιάμεσο",
        "en": "3 Portuguese - Pre-intermediate",
        "er": "3 Portugués - Preintermedio",
        "es": "3 Portugués - Preintermedio",
        "ex": "3 Portugués - Preintermedio",
        "fa": "3 پرتغالی - متوسط رو به پایین",
        "fr": "3 Portugais - Pré-intermédiaire",
        "hi": "3 पुर्तगाली - पूर्व मध्यवर्ती",
        "hr": "3 Portugalski - Pred-srednji",
        "hu": "3 Portugál -  Középhaladó",
        "hy": "3 Պորտուգալերեն - Նախամիջին",
        "it": "3 Portoghese - Pre-intermedio",
        "ja": "3 ポルトガル語 - 中級下",
        "ko": "3 포르투갈어 - 중하급",
        "lt": "3 Portugalų k. - Žemesnis nei vidutinis",
        "nl": "3  Portugees - Pre-intermediate",
        "no": "3 Portugisisk - Pre-mellomliggende",
        "pl": "3 Portugalski - Niższy średnio zaawansowany",
        "ro": "3 Portugheză - Pre-intermediar",
        "ru": "3 Португальский - Ниже среднего",
        "rw": "3 Igiporutugali - Ikibanziriza icyiciro cyo hagati",
        "sk": "3 Portugalčina - Mierne pokročilý",
        "sl": "3 Portugalščina - Nižji-srednji",
        "sr": "3 Portugalski - Niži-srednji",
        "sv": "3 Portugisiska - Pre-mellanprodukt",
        "sw": "3 Kireno - Kabla ya ya kati",
        "tr": "3 Portekizce - Alt orta seviye",
        "uk": "3 Португальська - попередній проміжний",
        "ur": "3 پرتگالی - قبل از وسط",
        "zh": "3 葡萄牙语 - 预中间"
    },
    "PortuguesePersonal": {
        "default": "Meu português pessoal"
    },
    "SpanishBeginner": {
        "ar": "1 الإسبانية - مُبتدىء",
        "bg": "1 Испански - Начинаещ",
        "br": "1 Espanhol - Iniciante",
        "ca": "1 Espanyol - Principiant",
        "cz": "1 Španělsky - Začátečník",
        "de": "1 Spanisch - Anfänger",
        "default": "1 Spanish - Beginner",
        "el": "1 Ισπανικά - Αρχάριος",
        "en": "1 Spanish - Beginner",
        "er": "1 Español - Principiante",
        "ex": "1 Español - Principiante",
        "fa": "1 اسپانیایی - مبتدی",
        "fr": "1 Espagnol - Débutant",
        "hi": "1 स्पैनिश - नौसिखिया",
        "hr": "1 Španjolski - Početnik",
        "hu": "1 Spanyol - Kezdő",
        "hy": "1 Իսպաներեն - Սկսնակ",
        "it": "1 Spagnolo - Principiante",
        "ja": "1 スペイン語 - 初心者",
        "ko": "1 스페인어 - 초심자",
        "lt": "1 Ispanų k. - Pradedantysis",
        "nl": "1 Spaans - Beginner",
        "no": "1 Spansk - Begynner",
        "pl": "1 Hiszpański - Początkujący",
        "pt": "1 Espanhol - Principiante",
        "ro": "1 Spaniolă - Începător",
        "ru": "1 Испанский - Начинающий",
        "rw": "1 Icyesipanyoro - Umutangizi",
        "sk": "1 Španielčina - Začiatočník",
        "sl": "1 Španščina - Začetnik",
        "sr": "1 Španski - početni",
        "sv": "1 Spansk - Nybörjare",
        "sw": "1 Kihispania - Ayeanza",
        "tr": "1 İspanyolca - Başlangıç",
        "uk": "1 Iспанська - Початківець",
        "ur": "1 ہسپانوی - نوآموز",
        "zh": "1 西班牙语 - 新手"
    },
    "SpanishFootball": {
        "ar": "الإسبانية -  كرة القدم",
        "bg": "Испански - Футбол",
        "br": "Espanhol - Futebol",
        "cz": "Španělsky - Fotbal",
        "de": "Spanisch - Fußball",
        "default": "Spanish - Football",
        "el": "Ισπανικά - Ποδόσφαιρο",
        "en": "Spanish -Football",
        "er": "Español - Fútbol",
        "ex": "Español -Fútbol",
        "fa": "اسپانیایی - فوتبال",
        "fr": "Espagnol - Football",
        "hi": "स्पैनिश - फुटबॉल",
        "hr": "Španjolski - Nogomet",
        "hu": "Spanyol - Futball",
        "hy": "Իսպաներեն - Ֆուտբոլ",
        "it": "Spagnolo - Calcio",
        "ja": "スペイン語 - サッカー",
        "ko": "스페인어 - 축구",
        "lt": "Ispanų k. - Futbolas",
        "no": "Spansk - Fotball",
        "pl": "Hiszpański - Piłka nożna",
        "pt": "Espanhol - Futebol",
        "ro": "Spaniolă - Fotbal",
        "ru": "Испанский - Футбол",
        "rw": "Icyesipanyoro - Umupira w'amaguru",
        "sk": "Španielčina - Futbal",
        "sl": "Španščina - Nogomet",
        "sr": "Španski - fudbal",
        "sw": "Kihispania - Kandanda",
        "tr": "İspanyolca - Futbol",
        "ur": "ہسپانوی - فٹ بال",
        "zh": "西班牙语 - 足球"
    },
    "SpanishIntermediate": {
        "ar": "2 الإسبانية - اساسي",
        "bg": "2 Испански - Елементарно",
        "br": "2 Espanhol - Básico",
        "ca": "2 Espanyol - Elemental",
        "cz": "2 Španělsky - Základní",
        "de": "2 Spanisch - Grundlagen",
        "default": "2 Spanish - Elementary",
        "el": "2 Ισπανικά - Στοιχειώδης",
        "en": "2 Spanish - Elementary",
        "er": "2 Español - Elemental",
        "ex": "2 Español -Elemental",
        "fa": "2 اسپانیایی - مقدماتی",
        "fr": "2 Espagnol - Élémentaire",
        "hi": "2 स्पैनिश - प्राथमिक",
        "hr": "2 Španjolski - Osnovni",
        "hu": "2 Spanyol - Alapszint",
        "hy": "2 Իսպաներեն - Տարրական",
        "it": "2 Spagnolo - Elementare",
        "ja": "2 スペイン語 - 初級者",
        "ko": "2 스페인어 - 초급",
        "lt": "2 Ispanų k. - Pradinis",
        "nl": "2 Spaans - Elementair",
        "no": "2 Spansk - Lett",
        "pl": "2 Hiszpański - Podstawowy",
        "pt": "2 Espanhol - Elementar",
        "ro": "2 Spaniolă - Elementar",
        "ru": "2 Испанский - Элементарный ",
        "rw": "2 Icyesipanyoro - Uwo ku rugero rwo hasi",
        "sk": "2 Španielčina - Elementárna úroveň",
        "sl": "2 Španščina - Osnovno ",
        "sr": "2 Španski - Osnovni",
        "sv": "2 Spansk - Elementärt",
        "sw": "2 Kihispania - Ya msingi",
        "tr": "2 İspanyolca - Temel",
        "uk": "2 Iспанська - Елементарно",
        "ur": "2 ہسپانوی - ابتدائی",
        "zh": "2 西班牙语 - 初级"
    },
    "SpanishPIM": {
        "ar": "3 الإسبانية -  قبل االمتوسط ",
        "bg": "3 Испански - Предварителен междинен",
        "br": "3 Espanhol - Pré-intermediário",
        "ca": "3 Espanyol - Pre-intermedi",
        "cz": "3 Španělsky - Předběžně",
        "de": "3 Spanisch - Erweiterte Grundlagen",
        "default": "3 Spanish - Pre-intermediate",
        "el": "3 Ισπανικά - Προ-ενδιάμεσο",
        "en": "3 Spanish - Pre-intermediate",
        "er": "3 Español - Preintermedio",
        "ex": "3 Español - Preintermedio",
        "fa": "3 اسپانیایی - متوسط ",
        "fr": "3 Espagnol - Pré-intermédiaire",
        "hi": "3 स्पैनिश - पूर्व मध्यवर्ती",
        "hr": "3 Španjolski - Pred-srednji",
        "hu": "3 Spanyol - Középhaladó",
        "hy": "3 Իսպաներեն - Նախամիջին",
        "it": "3 Spagnolo - Pre-intermedio",
        "ja": "3 スペイン語 - 中級下",
        "ko": "3 스페인어 - 중하급",
        "lt": "3 Ispanų k. - Žemesnis nei vidutinis",
        "nl": "3 Spaans - Pre-intermediate",
        "no": "3 Spansk - Pre-mellomliggende",
        "pl": "3 Hiszpański - Niższy średnio zaawansowany ",
        "pt": "3 Espanhol - Pré-intermédio",
        "ro": "3 Spaniolă - Pre-intermediar ",
        "ru": "3 Испанский - Ниже среднего",
        "rw": "3 Icyesipanyoro - Ikibanziriza icyiciro cyo hagati",
        "sk": "3 Španielčina - Mierne pokročilý",
        "sl": "3 Španščina - Nižji-srednji",
        "sr": "3 Španski - Niži-srednji",
        "sv": "3 Spansk - Pre-mellanprodukt",
        "sw": "3 Kihispania - Kabla ya ya kati",
        "tr": "3 İspanyolca - Alt orta seviye",
        "uk": "3 Iспанська - попередній проміжний",
        "ur": "3 ہسپانوی - قبل از وسط",
        "zh": "3 西班牙语 - 预中间"
    },
    "SpanishPersonal": {
        "default": "Mi español personal"
    }
}

export default CourseNameTranslationsMap;
