
import Variant from './config/kern';
const Constants = {
    ...{
        AES_SALT: "tenKsZVM4EcSSPQKxKt7BnZRPkyaUcb5",
        CODE_TYPE: {
            COMPANY_CODE: 'COMPANY_CODE',
            COURSE_CODE: 'COURSE_CODE'
        }
}   ,
    ...Variant
};




export default Constants;
