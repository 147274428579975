const kern = {
    CONFIG: {
        apiKey: "AIzaSyB0xyrl-sE69ArlTJGowYNlCXC7jGQr8oQ",
        authDomain: "learnmatch-kern-79074.firebaseapp.com",
        databaseURL: "https://learnmatch-kern-79074.firebaseio.com",
        storageBucket: "learnmatch-kern-79074.appspot.com",
        projectId: "learnmatch-kern-79074"
    },
    APP_TITLE: "KERN LearnApp",
    APP_NAME: "KERN LearnApp",
    COMPANY_NAME: "KERN",
    BRANCH_KEY: 'key_live_lcTwqoK4Jb5MFjnihoAI0namuzj3kYG8',
    FALLBACKLINK: "https://lmkern.app.link",
    LOGO: require('../assets/img/kern_logo.png'),

    // links to agbs
    AGBLinks: {
        ar: 'https://www.kerntraining.com/en/terms-and-conditions/',
        bg: 'https://www.kerntraining.com/en/terms-and-conditions/',
        br: 'https://www.kerntraining.com/en/terms-and-conditions/',
        ca: 'https://www.kerntraining.com/en/terms-and-conditions/',
        cz: 'https://www.kerntraining.com/en/terms-and-conditions/',
        de: 'https://www.kerntraining.com/de/agb/',
        el: 'https://www.kerntraining.com/en/terms-and-conditions/',
        en: 'https://www.kerntraining.com/en/terms-and-conditions/',
        er: 'https://www.kerntraining.com/en/terms-and-conditions/',
        es: 'https://www.kerntraining.com/en/terms-and-conditions/',
        ex: 'https://www.kerntraining.com/en/terms-and-conditions/',
        fa: 'https://www.kerntraining.com/en/terms-and-conditions/',
        fr: 'https://www.kerntraining.com/fr/cgv/',
        hi: 'https://www.kerntraining.com/en/terms-and-conditions/',
        hr: 'https://www.kerntraining.com/en/terms-and-conditions/',
        hu: 'https://www.kerntraining.com/en/terms-and-conditions/',
        hy: 'https://www.kerntraining.com/en/terms-and-conditions/',
        it: 'https://www.kerntraining.com/en/terms-and-conditions/',
        ja: 'https://www.kerntraining.com/en/terms-and-conditions/',
        ko: 'https://www.kerntraining.com/en/terms-and-conditions/',
        lt: 'https://www.kerntraining.com/en/terms-and-conditions/',
        nl: 'https://www.kerntraining.com/nl/algemene-voorwaarden/',
        no: 'https://www.kerntraining.com/en/terms-and-conditions/',
        pl: 'https://www.kerntraining.com/en/terms-and-conditions/',
        pt: 'https://www.kerntraining.com/en/terms-and-conditions/',
        ro: 'https://www.kerntraining.com/en/terms-and-conditions/',
        ru: 'https://www.kerntraining.com/en/terms-and-conditions/',
        rw: 'https://www.kerntraining.com/en/terms-and-conditions/',
        sk: 'https://www.kerntraining.com/en/terms-and-conditions/',
        sl: 'https://www.kerntraining.com/en/terms-and-conditions/',
        sq: 'https://www.kerntraining.com/en/terms-and-conditions/',
        sr: 'https://www.kerntraining.com/en/terms-and-conditions/',
        sw: 'https://www.kerntraining.com/en/terms-and-conditions/',
        tr: 'https://www.kerntraining.com/en/terms-and-conditions/',
        uk: 'https://www.kerntraining.com/en/terms-and-conditions/',
        ur: 'https://www.kerntraining.com/en/terms-and-conditions/',
        zh: 'https://www.kerntraining.com/en/terms-and-conditions/',
        zt: 'https://www.kerntraining.com/en/terms-and-conditions/'
    },

    // links to privacy policies
    PrivacyLinks: {
        ar: 'https://www.kerntraining.com/en/data-protection/',
        bg: 'https://www.kerntraining.com/en/data-protection/',
        br: 'https://www.kerntraining.com/en/data-protection/',
        ca: 'https://www.kerntraining.com/en/data-protection/',
        cz: 'https://www.kerntraining.com/en/data-protection/',
        de: 'https://www.kerntraining.com/de/datenschutz/',
        el: 'https://www.kerntraining.com/en/data-protection/',
        en: 'https://www.kerntraining.com/en/data-protection/',
        er: 'https://www.kerntraining.com/en/data-protection/',
        es: 'https://www.kerntraining.com/en/data-protection/',
        ex: 'https://www.kerntraining.com/en/data-protection/',
        fa: 'https://www.kerntraining.com/en/data-protection/',
        fr: 'https://www.kerntraining.com/fr/protection-des-donnees/',
        hi: 'https://www.kerntraining.com/en/data-protection/',
        hr: 'https://www.kerntraining.com/en/data-protection/',
        hu: 'https://www.kerntraining.com/en/data-protection/',
        hy: 'https://www.kerntraining.com/en/data-protection/',
        it: 'https://www.kerntraining.com/en/data-protection/',
        ja: 'https://www.kerntraining.com/en/data-protection/',
        ko: 'https://www.kerntraining.com/en/data-protection/',
        lt: 'https://www.kerntraining.com/en/data-protection/',
        nl: 'https://www.kerntraining.com/nl/gegevensbescherming/',
        no: 'https://www.kerntraining.com/en/data-protection/',
        pl: 'https://www.kerntraining.com/en/data-protection/',
        pt: 'https://www.kerntraining.com/en/data-protection/',
        ro: 'https://www.kerntraining.com/en/data-protection/',
        ru: 'https://www.kerntraining.com/en/data-protection/',
        rw: 'https://www.kerntraining.com/en/data-protection/',
        sk: 'https://www.kerntraining.com/en/data-protection/',
        sl: 'https://www.kerntraining.com/en/data-protection/',
        sq: 'https://www.kerntraining.com/en/data-protection/',
        sr: 'https://www.kerntraining.com/en/data-protection/',
        sw: 'https://www.kerntraining.com/en/data-protection/',
        tr: 'https://www.kerntraining.com/en/data-protection/',
        uk: 'https://www.kerntraining.com/en/data-protection/',
        ur: 'https://www.kerntraining.com/en/data-protection/',
        zh: 'https://www.kerntraining.com/en/data-protection/',
        zt: 'https://www.kerntraining.com/en/data-protection/'
    }
};

export default kern;
