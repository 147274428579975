// @flow
import MatchUserModel from './MatchUserModel';
import GameConstants from '../constants/GameConstants';

export default class MatchModel {

    _gameState: number;
    _serverStartTime: ?number;
    _guestUser: MatchUserModel;
    _homeUser: MatchUserModel;
    _eventPairKey: string;
    _version: number;

    static createFromParams = (gameState: number, guestUser: MatchUserModel,
                               homeUser: MatchUserModel, eventPairKey: string, version: number, serverStartTime: ?number): MatchModel => {
        let matchModel = new MatchModel();
        matchModel._gameState = gameState;
        matchModel._guestUser = guestUser;
        matchModel._homeUser = homeUser;
        matchModel._serverStartTime = serverStartTime;
        matchModel._version = version;
        matchModel._eventPairKey = eventPairKey;
        return matchModel;
    };

    static createFromObject = (matchJsonObject: Object): MatchModel => {
        let guestUser: MatchUserModel = matchJsonObject.guestUser ? MatchUserModel.createFromObject(matchJsonObject.guestUser) : new MatchUserModel();
        let homeUser: MatchUserModel = matchJsonObject.homeUser ? MatchUserModel.createFromObject(matchJsonObject.homeUser) : new MatchUserModel();
        let version : number = matchJsonObject.version ? matchJsonObject.version : 1;

        return MatchModel.createFromParams(
            matchJsonObject.gameState,
            guestUser,
            homeUser,
            matchJsonObject.eventPairKey,
            version,
            matchJsonObject.serverStartTime
        );
    };

    getGameState = (): number => {
        return this._gameState;
    };

    getGuestUser = (): MatchUserModel => {
        return this._guestUser;
    };

    setGuestUser = (guestUser: MatchUserModel) => {
        this._guestUser = guestUser;
    };

    getHomeUser = (): MatchUserModel => {
        return this._homeUser;
    };

    setHomeUser = (homeUser: MatchUserModel) => {
        this._homeUser = homeUser;
    };

    getUserByType = (playerType:string): MatchUserModel =>{
        if(playerType === GameConstants.PLAYER_TYPE.HOME_USER) return this._homeUser;
        else return this._guestUser;
    };

    setGameState = (gameState: number): void => {
        this._gameState = gameState;
    };

    setServerStartTime = (time: ?number): void => {
        this._serverStartTime = time;
    };

    getServerStartTime = (): ?number => {
        return this._serverStartTime;
    };

    getEventPairKey = (): string => {
        return this._eventPairKey;
    };

    getVersion = (): number => {
        return this._version;
    };

    static serialize = (matchModel: MatchModel): Object => {
        return {
            gameState: matchModel.getGameState(),
            homeUser: MatchUserModel.serialize(matchModel.getHomeUser()),
            guestUser: MatchUserModel.serialize(matchModel.getGuestUser()),
            serverStartTime: matchModel.getServerStartTime(),
            eventPairKey: matchModel.getEventPairKey(),
            version: matchModel.getVersion(),
        }
    };

    static getByValue = (match: MatchModel): MatchModel => {
        return MatchModel.createFromObject(JSON.parse(JSON.stringify(MatchModel.serialize(match))));
    }
}
