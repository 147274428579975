
import React, {Component} from 'react';

import ReactAudioPlayer from "react-audio-player";
import MdPlay from 'react-ionicons/lib/MdPlay';
import MdSquare from 'react-ionicons/lib/MdSquare';

type Props = {
    source: string,
    testLanguage: string,
};

type State = {
    playing: boolean
};

class AudioPlayer extends Component<Props, State> {

    state = {
        playing: false
    };

    player: any = null;

    componentDidMount = () => {
    };

    onEnded = () => {
        this.setState({playing: false});
    };

    onPlay = () => {
        this.setState({playing: true});
    };

    play = () => {
        if(this.player && this.player.audioEl){
            this.player.audioEl.play();
        }
    };

    stop = () => {
        if(this.player && this.player.audioEl){
            this.player.audioEl.stop();
        }
    };

    buttonClicked = () => {
        if(this.state.playing){
            try{
                this.stop();
            } catch (e) {
                console.log(e);
            }
        } else {
            try {
                this.play();
            } catch (e){
                console.log(e);
            }
        }
    };

    render() {
        let path = "content/" + this.props.testLanguage + "/audios/";
        return (
            <button onClick={this.buttonClicked} className={"button-container-audio " + (this.state.playing ? "playing" : "")}>
                { this.state.playing ? <MdSquare fontSize="40px" color="#ffffff"/> : <MdPlay fontSize="40px" color="#64d950"/> }
                <ReactAudioPlayer src={path + this.props.source} onEnded={this.onEnded} onPlay={this.onPlay} autoPlay hidden="hidden" ref={player => this.player = player}/>
            </button>
        );
    }
}

export default AudioPlayer;
