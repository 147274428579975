// @flow

import i18next from "i18next";

class UserModel {
    _id: number = 1;
    _firebaseId: string = '';
    _active: boolean = false;
    _character: string = '0-w-1';
    _gender: string = 'w';
    _locale: string = 'de';
    _deviceCountry: string = 'DE';
    _mail: string = '';
    _password: string = '';
    _name: string = '';
    _onboardingCompleted: boolean = false;
    _online: number = 0;
    _provider: string = 'Mail';
    _purchased: boolean = false;
    _stickers: number = 3;
    _emojiName: string = 'smiley';
    _learnMatchLeague: string = '';
    _learnMatchLeaguePoints: number = 0;
    _appReviewGiven: boolean = false;
    _nextAppReviewRequestStage: number = 0;
    _dictionaryUntil: number = 0;
    _expirationDate: ?number = 0;
    _introductions: {[key: string]: boolean} = {};
    _milestonesReached: Array<Object>;
    _isDeepLinkSponsor: boolean;
    _showNewStickerPopUp: Object;
    _subscription: {ios: string, android: string};
    _subscriptionDictionary: {ios: string, android: string};
    _acceptedOptInInfo: boolean = false;

    static createFromParams = (id: number,
                               firebaseId: string,
                               active: boolean,
                               character: string,
                               gender: string,
                               locale: string,
                               deviceCountry: string,
                               mail: string,
                               password: string,
                               name: string,
                               onboardingCompleted: boolean,
                               online: number,
                               provider: string,
                               purchased: boolean,
                               stickers: number,
                               emojiName: string,
                               learnMatchLeague: string,
                               learnMatchLeaguePoints: number,
                               subscription: {ios: string, android: string},
                               subscriptionDictionary: {ios: string, android: string},
                               dictionaryUntil: number = 0,
                               appReviewGiven: ?boolean,
                               nextAppReviewRequestStage: ?number,
                               expirationDate: ?number,
                               introductions: ?{[key: string]: boolean},
                               reachedMilestones: ?Array<Object>,
                               isDeepLinkSponsor: boolean = false,
                               acceptedOptInInfo: boolean = false,
                               showNewStickerPopUp: Object = { friendList: false, match: false, friendsCount: 0 }): UserModel => {
        const userObject = new UserModel();
        userObject.setId(id);
        if (firebaseId) {
            userObject.setFirebaseId(firebaseId);
        }
        userObject.setActive(active);
        userObject.setCharacter(character);
        userObject.setGender(gender);
        userObject.setLocale(locale);
        userObject.setDeviceCountry(deviceCountry);
        userObject.setMail(mail);
        userObject.setPassword(password);
        userObject.setName(name);
        userObject.setOnboardingCompleted(onboardingCompleted);
        userObject.setOnline(online || 0);
        userObject.setProvider(provider);
        userObject.setPurchased(purchased);
        userObject.setStickers(stickers || 3);
        userObject.setEmojiName(emojiName || 'smiley');
        userObject.setLearnMatchLeague(learnMatchLeague || '');
        userObject.setLearnMatchLeaguePoints(typeof learnMatchLeaguePoints !== 'undefined' ? learnMatchLeaguePoints : -1);
        userObject.setAppReviewGiven(appReviewGiven || false);
        userObject.setNextAppReviewRequestStage(nextAppReviewRequestStage || 0);
        userObject.setExpirationDate(expirationDate || null);
        userObject.setIntroductions(introductions || {});
        userObject.setDictionaryUntil(dictionaryUntil);
        userObject.setSubscription(subscription);
        userObject.setSubscriptionDictionary(subscriptionDictionary);
        userObject.setMilestonesReached(reachedMilestones || []);
        userObject.setIsDeepLinkSponsor(isDeepLinkSponsor);
        userObject.setAcceptedOptInInfo(acceptedOptInInfo || false);
        userObject.setShowNewStickerPopUp(showNewStickerPopUp);
        return userObject;
    };

    static createDummyUser = (): UserModel => {
        return UserModel.createFromParams(0, '', false, '0-m-1', 'm',
            i18next.language.substr(0, 2), '', '', '', '', false,
            0, 'Anonymous', false, 0, '', '', 0,
            { ios: '', android: '' }, { ios: '', android: '' }, 0, false,
            0, 0, {}, [], false, false, { friendList: false, match: false, friendsCount: 0 });
    };

    static createFromObject = (userJsonObject: any): UserModel => {
        const subscription = { ios: '', android: '' };
        if (userJsonObject.Subscription) {
            if (userJsonObject.Subscription.ios) {
                subscription.ios = userJsonObject.Subscription.ios;
            }
            if (userJsonObject.Subscription.android) {
                subscription.android = userJsonObject.Subscription.android;
            }
        }
        const subscriptionDictionary = { ios: '', android: '' };
        if (userJsonObject.Subscription) {
            if (userJsonObject.SubscriptionDictionary.ios) {
                subscriptionDictionary.ios = userJsonObject.SubscriptionDictionary.ios;
            }
            if (userJsonObject.SubscriptionDictionary.android) {
                subscriptionDictionary.android = userJsonObject.SubscriptionDictionary.android;
            }
        }
        return UserModel.createFromParams(
            userJsonObject.ID,
            userJsonObject.FirebaseId,
            userJsonObject.Active,
            userJsonObject.Character,
            userJsonObject.Gender,
            userJsonObject.Locale,
            userJsonObject.DeviceCountry,
            userJsonObject.Mail,
            userJsonObject.Password,
            userJsonObject.Name,
            userJsonObject.OnboardingCompleted,
            userJsonObject.Online,
            userJsonObject.Provider,
            userJsonObject.Purchased,
            userJsonObject.Stickers,
            userJsonObject.EmojiName,
            userJsonObject.LearnmatchLeague,
            userJsonObject.LearnmatchLeaguePoints,
            subscription,
            subscriptionDictionary,
            userJsonObject.DictionaryUntil,
            userJsonObject.AppReviewGiven,
            userJsonObject.NextAppReviewRequestStage,
            userJsonObject.ExpirationDate,
            userJsonObject.Introductions,
            userJsonObject.MilestonesReached,
            userJsonObject.IsDeepLinkSponsor,
            userJsonObject.AcceptedOptInInfo,
            userJsonObject.ShowNewStickerPopUp);
    };

    static createFromObjectMultiple = (users: Array<Object>): Array<UserModel> => {
        const userModels: Array<UserModel> = [];
        for (let i = 0; i < users.length; i++) {
            userModels.push(UserModel.createFromObject(users[i]));
        }
        return userModels;
    };

    getId = (): number => {
        return this._id;
    };

    setId = (id: number) => {
        this._id = id;
    };

    getFirebaseId = (): string => {
        return this._firebaseId;
    };

    setFirebaseId = (firebaseId: string) => {
        this._firebaseId = firebaseId;
    };

    getActive = (): boolean => {
        return this._active;
    };

    setActive = (active: boolean) => {
        this._active = active;
    };

    getCharacter = (): string => {
        return this._character;
    };

    setCharacter = (character: string) => {
        this._character = character;
    };

    getGender = (): string => {
        return this._gender;
    };

    setGender = (gender: string) => {
        this._gender = gender;
    };

    getLocale = (): string => {
        return this._locale;
    };

    setLocale = (locale: string) => {
        this._locale = locale;
    };

    getDeviceCountry = (): string => {
        return this._deviceCountry;
    };

    setDeviceCountry = (deviceCountry: string) => {
        this._deviceCountry = deviceCountry;
    };

    getMail = (): string => {
        return this._mail;
    };

    setMail = (mail: string) => {
        this._mail = mail;
    };

    getPassword = (): string => {
        return this._password;
    };

    setPassword = (password: string) => {
        this._password = password;
    };

    getName = (): string => {
        return this._name;
    };

    setName = (name: string) => {
        this._name = name;
    };

    getOnboardingCompleted = (): boolean => {
        return this._onboardingCompleted;
    };

    setOnboardingCompleted = (onboardingCompleted: boolean) => {
        this._onboardingCompleted = onboardingCompleted;
    };

    getOnline = (): number => {
        return this._online;
    };

    setOnline = (online: number) => {
        this._online = online;
    };

    getProvider = (): string => {
        return this._provider;
    };

    setProvider = (provider: string) => {
        this._provider = provider;
    };

    getPurchased = (): boolean => {
        return this._purchased;
    };

    setPurchased = (purchased: boolean) => {
        this._purchased = purchased;
    };

    getStickers = (): number => {
        return this._stickers;
    };

    setStickers = (stickers: number) => {
        this._stickers = stickers;
    };

    getEmojiName = (): string => {
        return this._emojiName;
    };

    setEmojiName = (emojiName: string) => {
        this._emojiName = emojiName;
    };

    getLearnMatchLeague = (): string => {
        return this._learnMatchLeague;
    };

    setLearnMatchLeague = (learnMatchLeague: string) => {
        this._learnMatchLeague = learnMatchLeague;
    };

    getLearnMatchLeaguePoints = (): number => {
        return this._learnMatchLeaguePoints;
    };

    setLearnMatchLeaguePoints = (learnMatchLeaguePoints: number) => {
        this._learnMatchLeaguePoints = learnMatchLeaguePoints;
    };

    getAppReviewGiven = (): boolean => {
        return this._appReviewGiven;
    };

    setAppReviewGiven = (appReviewGiven: boolean) => {
        this._appReviewGiven = appReviewGiven;
    };

    getNextAppReviewRequestStage = (): number => {
        return this._nextAppReviewRequestStage;
    };

    setNextAppReviewRequestStage = (nextAppReviewRequestStage: number) => {
        this._nextAppReviewRequestStage = nextAppReviewRequestStage;
    };

    getExpirationDate = (): ?number => {
        return this._expirationDate;
    };

    setExpirationDate = (expirationDate: ?number) => {
        this._expirationDate = expirationDate;
    };

    setIntroductions = (introductions: {[key: string]: boolean}) => {
        this._introductions = introductions;
    };

    setIntroduction = (key: string, checked: boolean) => {
        this._introductions[key] = checked;
    };

    getIntroductions = (): {[key: string]: boolean} => {
        return this._introductions;
    };

    getIntroduction = (key: string): boolean => {
        return this._introductions[key] ? this._introductions[key] : false;
    };

    getDictionaryUntil = (): number => {
        return this._dictionaryUntil;
    };

    setDictionaryUntil = (dictionaryUntil: number): void => {
        this._dictionaryUntil = dictionaryUntil;
    };

    getSubscription = (): {ios: string, android: string} => {
        return this._subscription;
    };

    setSubscription = (subscription: {ios: string, android: string}): void => {
        this._subscription = subscription;
    };

    getSubscriptionDictionary = (): {ios: string, android: string} => {
        return this._subscriptionDictionary;
    };

    setSubscriptionDictionary = (subscriptionDictionary: {ios: string, android: string}): void => {
        this._subscriptionDictionary = subscriptionDictionary;
    };

    getMilestonesReached = (): Array<Object> => {
        return this._milestonesReached;
    };

    setMilestonesReached = (reachedMilestones: Array<Object>): void => {
        this._milestonesReached = reachedMilestones;
    };

    setIsDeepLinkSponsor = (isDeepLinkSponsor: boolean) => {
        this._isDeepLinkSponsor = isDeepLinkSponsor;
    };

    getIsDeepLinkSponsor = (): boolean => {
        return this._isDeepLinkSponsor;
    };

    setAcceptedOptInInfo = (acceptedOptInInfo: boolean) => {
        this._acceptedOptInInfo = acceptedOptInInfo;
    };

    getAcceptedOptInInfo = () => {
        return this._acceptedOptInInfo;
    };

    setShowNewStickerPopUp = (showNewStickerPopUp: Object) => {
        this._showNewStickerPopUp = showNewStickerPopUp;
    };

    getShowNewStickerPopUp = (): Object => {
        return this._showNewStickerPopUp;
    };

    static serialize = (user: UserModel): any => {
        return {
            ID: user.getId(),
            FirebaseId: user.getFirebaseId(),
            Active: user.getActive(),
            Character: user.getCharacter(),
            Gender: user.getGender(),
            Locale: user.getLocale(),
            DeviceCountry: user.getDeviceCountry(),
            Mail: user.getMail(),
            Password: user.getPassword(),
            Name: user.getName(),
            OnboardingCompleted: user.getOnboardingCompleted(),
            Online: user.getOnline(),
            Provider: user.getProvider(),
            Purchased: user.getPurchased(),
            Stickers: user.getStickers(),
            EmojiName: user.getEmojiName(),
            LearnmatchLeague: user.getLearnMatchLeague(),
            LearnmatchLeaguePoints: user.getLearnMatchLeaguePoints(),
            AppReviewGiven: user.getAppReviewGiven(),
            NextAppReviewRequestStage: user.getNextAppReviewRequestStage(),
            ExpirationDate: user.getExpirationDate(),
            Introductions: user.getIntroductions(),
            DictionaryUntil: user.getDictionaryUntil(),
            MilestonesReached: user.getMilestonesReached(),
            IsDeepLinkSponsor: user.getIsDeepLinkSponsor(),
            ShowNewStickerPopUp: user.getShowNewStickerPopUp(),
            Subscription: user.getSubscription(),
            SubscriptionDictionary: user.getSubscriptionDictionary(),
            AcceptedOptInInfo: user.getAcceptedOptInInfo()
        };
    };

    static serializeWithoutId = (user: UserModel): any => {
        return {
            FirebaseId: user.getFirebaseId(),
            Active: user.getActive(),
            Character: user.getCharacter(),
            Gender: user.getGender(),
            Locale: user.getLocale(),
            DeviceCountry: user.getDeviceCountry(),
            Mail: user.getMail(),
            Password: user.getPassword(),
            Name: user.getName(),
            OnboardingCompleted: user.getOnboardingCompleted(),
            Online: user.getOnline(),
            Provider: user.getProvider(),
            Purchased: user.getPurchased(),
            Stickers: user.getStickers(),
            EmojiName: user.getEmojiName(),
            LearnmatchLeague: user.getLearnMatchLeague(),
            LearnmatchLeaguePoints: user.getLearnMatchLeaguePoints(),
            AppReviewGiven: user.getAppReviewGiven(),
            NextAppReviewRequestStage: user.getNextAppReviewRequestStage(),
            ExpirationDate: user.getExpirationDate(),
            Introductions: user.getIntroductions(),
            DictionaryUntil: user.getDictionaryUntil(),
            MilestonesReached: user.getMilestonesReached(),
            IsDeepLinkSponsor: user.getIsDeepLinkSponsor(),
            ShowNewStickerPopUp: user.getShowNewStickerPopUp(),
            Subscription: user.getSubscription(),
            SubscriptionDictionary: user.getSubscriptionDictionary(),
            AcceptedOptInInfo: user.getAcceptedOptInInfo()
        };
    };

    static serializeMultiple = (users: Array<UserModel>): any => {
        const usersJsonArray = [];
        users.forEach((user) => {
            usersJsonArray.push(UserModel.serialize(user));
        });
        return usersJsonArray;
    };

    static serializeWithoutIdMultiple = (users: Array<UserModel>): any => {
        const usersJsonArray = [];
        users.forEach((user) => {
            usersJsonArray.push(UserModel.serializeWithoutId(user));
        });
        return usersJsonArray;
    };

    static getByValue = (user: UserModel): UserModel => {
        return UserModel.createFromObject(JSON.parse(JSON.stringify(UserModel.serialize(user))));
    }
}

export default UserModel;
