// @flow
import React, {Component} from 'react';
import 'react-circular-progressbar/dist/styles.css';
import i18next from 'i18next';

import {Circle} from 'rc-progress';

type Props = {
    time: number,
    percentage: number
};

class MatchWaiting extends Component<Props> {

    render() {
        return (
            <div className="get-ready-container">
                <h1>{i18next.t("LIVEMATCH__STARTING_SOON")}</h1>
                <p>{i18next.t("MESSAGE_MATCH__GET READY!")}</p>
                <div className="get-ready-circle">
                    <Circle
                        percent={ this.props.percentage  }
                        strokeWidth={12}
                        trailWidth={8}
                        strokeLinecap="round"
                        trailColor='#eeeeee'
                        strokeColor='#64d950' />
                    <p className="get-ready-time-text">
                        {
                            this.props.time
                        }
                    </p>
                </div>
            </div>
        );
    }
}

export default MatchWaiting;
