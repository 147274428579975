import React, { Component } from 'react';
import i18next from 'i18next';
import Select from 'react-select';
import LanguageTranslationsMap from '../maps/LanguageTranslationsMap';

class LanguageTestInfo extends Component {

    props: {
        startLanguageTest: (languageTest: string) => mixed,
        goOnWithoutLanguageTest: () => mixed,
        sponsorLanguageTests: Array<string>
    };

    state = {
        languageTest: null
    }

    renderLanguageTestChoices = () => {
        const languagesOutput = [];
        const possibleLanguages = ['English', 'Deutsch', 'French', 'Italian', 'Portuguese', 'Spanish', 'Japanese'];
        if (this.props.sponsorLanguageTests.length > 1) {
            for (let i = 0; i < this.props.sponsorLanguageTests.length; i++) {
                for (let k = 0; k < possibleLanguages.length; k++) {
                    if (this.props.sponsorLanguageTests[i].includes(possibleLanguages[k])) {
                        const translatedLanguage = LanguageTranslationsMap[possibleLanguages[k]][i18next.language.substr(0, 2)] || LanguageTranslationsMap[possibleLanguages[k]].default;
                        languagesOutput.push({
                            value: this.props.sponsorLanguageTests[i],
                            label: translatedLanguage
                        });
                    }
                }
            }
            return (
                <div>
                    <p>{i18next.t('MESSAGE__PLEASE_CHOOSE_LANGUAGE_TEST')}</p>
                <Select value={this.state.languageTest} options={languagesOutput}
                        onChange={(languageTest) => this.setState({ languageTest: languageTest })}/>
                </div>
            )
        } else {
            let language = '';
            for (let k = 0; k < possibleLanguages.length; k++) {
                if (this.props.sponsorLanguageTests[0].includes(possibleLanguages[k])) {
                    language = LanguageTranslationsMap[possibleLanguages[k]][i18next.language.substr(0, 2)] || LanguageTranslationsMap[possibleLanguages[k]].default;
                    break;
                }
            }
            return (
                <div>
                    <p>{ i18next.t('TITLE__TARGET_LANGUAGE') + ': ' + language }</p>
                </div>
            )
        }
    }

    startLanguageTest = () => {
        if (this.state.languageTest) {
            this.props.startLanguageTest(this.state.languageTest.value)
        } else if (this.props.sponsorLanguageTests.length === 1) {
            this.props.startLanguageTest(this.props.sponsorLanguageTests[0]);
        } else {
            alert(i18next.t('MESSAGE__PLEASE_CHOOSE_LANGUAGE_TEST'));
        }
    }

    render () {
        return (
            <div className="content-container">
                <div className="welcome-text">
                    <h3>{i18next.t('TITLE__LANGUAGE_TEST')} </h3>
                </div>
                {
                    this.renderLanguageTestChoices()
                }
                <div className="button-area">
                    <button onClick={this.startLanguageTest}>
                        <p>
                            {i18next.t('BUTTON__START_LANGUAGE_TEST')}
                        </p>
                    </button>

                    <button onClick={this.props.goOnWithoutLanguageTest}>
                        <p>
                            {i18next.t('BUTTON__SKIP_LANGUAGE_TEST')}
                        </p>
                    </button>
                </div>
            </div>
        )
    }
}

export default LanguageTestInfo;
