import Constants from "../Constants";
const CryptoJS = require('crypto-js');

class DataHelper {

    static encryptPassword = (password) => {
        return CryptoJS.AES.encrypt(password, Constants.AES_SALT).toString();
    };

    static decryptPassword = (encryptedPassword) => {
        return CryptoJS.AES.decrypt(encryptedPassword, Constants.AES_SALT).toString(CryptoJS.enc.Utf8);
    };

    static verifyMail = (mail) => {
        let pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
        return mail && mail.match(pattern) !== null;
    };
}

export default DataHelper;
