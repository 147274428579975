import React from 'react';
import i18next from "i18next";
import PromiseComponent from "../PromiseComponent";
import UserModel from "../models/UserModel";
import FirebaseDatabaseService from "../services/FirebaseDatabaseService";
import DataHelper from "../helpers/DataHelper";
import Constants from '../Constants';
import { Sentry } from 'react-activity';

class Registration extends PromiseComponent {


    props: {
        mail: string,
        company: Object,
        locales: Object,
        goOnWithCode: () => mixed,
        goToLogin: () => mixed,
        codeType: string
    };

    state : {
        mail: string,
        password: string,
        passwordConfirmation: string,
        name: string,
        languageSelectionOpen: boolean,
        registering: boolean
    } = {
       mail: "",
        password: "",
        passwordConfirmation: "",
        name: "",
        languageSelectionOpen: false,
        registering: false
    };


    changeMailText = (event) => {
        let text = event.target.value.replace(/\s/g, "");
        this.setState({mail: text});
    };

    changeNameText = (event) => {
        let text = event.target.value.replace(/\s/g, "");
        this.setState({name: text});
    };

    changePasswordText = (event) => {
        let text = event.target.value.replace(/\s/g, "");
        this.setState({password: text});
    };

    changePasswordConfirmationText = (event) => {
        let text = event.target.value.replace(/\s/g, "");
        this.setState({passwordConfirmation: text});
    };

    registerWithMail = async () => {
        //some plausibility checks first
        if (!DataHelper.verifyMail(this.state.mail)) {
            alert(
                i18next.t('ALERT__ERROR_INVALID_EMAIL_TITLE')
            );
        } else if (this.state.password.length < 6) {
            alert(
                i18next.t('ALERT__ERROR_WEAK_PASSWORD_TITLE')
            );
        } else if (this.state.passwordConfirmation !== this.state.password) {
            alert(
                i18next.t("ALERT__ERROR_PASSWORD_CONFIRMATION_WRONG")
            )
        } else if (this.state.name.length < 3) {
            alert(
                i18next.t('ALERT_CREATE_PLAYER__NAME_MESSAGE')
            );
        } else {
            this.setState({ registering: true })
            //create user with dummy data
            let user: UserModel = UserModel.createDummyUser();
            user.setName(this.state.name);
            user.setGender('w');

            user.setDeviceCountry(i18next.language.toUpperCase());

            const firebaseDatabaseService = new FirebaseDatabaseService();
            const avatar = await firebaseDatabaseService.getAvatar(this.props.company.SponsorID);
            if (avatar) {
                user.setCharacter(avatar);
            } else {
                user.setCharacter('lea1f.png');
            }
            const userCredentials: Object = await firebaseDatabaseService.registerWithMailAuth(this.state.mail, this.state.password);
            if (userCredentials === "auth/email-already-in-use") {
                alert(userCredentials);
                this.setState({ registering: false })
            } else {
                user.setFirebaseId(userCredentials.uid);
                user.setMail(this.state.mail);
                user.setPassword(this.state.password);
                user.setProvider('Mail');

                await firebaseDatabaseService.saveUserInitial(user, this.props.company);
                await this.props.goOnWithCode(user.getFirebaseId(), this.state.mail, DataHelper.encryptPassword(this.state.password), false, user);
            }
        }
    };

    renderPhase6Terms = () => {
        let urls = ["https://www.kerntraining.com/de/agb", "https://www.kerntraining.com/de/datenschutz"];
        return (
            <div className="terms-container">
                {this.renderTermsAndDataLinks(i18next.t("DESCRIPTION_REGISTRATION__TERMS_AND_CONDITION_NEW"), urls)}
            </div>
        )
    };

    renderCompanyTerms = () => {
        if (this.props.company && this.props.company.PrivacyLink && this.props.company.PrivacyLink.text && this.props.company.PrivacyLink.urls) {
            let text = this.props.company.PrivacyLink.text[i18next.language.substr(0, 2)] || this.props.company.PrivacyLink.text.default;
            let urls = this.props.company.PrivacyLink.urls[i18next.language.substr(0, 2)] || this.props.company.PrivacyLink.urls.default;
            if (text && urls.length > 0) {
                return (
                    <div className="terms-container">
                        {this.renderTermsAndDataLinks(text, urls)}
                    </div>
                );
            }
        }
    };

    renderTermsAndDataLinks = () => {
        let translation = i18next.t("DESCRIPTION_REGISTRATION__TERMS_AND_CONDITION_NEW");
        let language = Constants.AGBLinks[i18next.language.substr(0,2)] ? i18next.language.substr(0,2) : 'en';
        let urls = [Constants.AGBLinks[language], Constants.PrivacyLinks[language]];
        let splittedText = translation.split('@link@');
        let parts = [];
        for (let i = 0; i < splittedText.length; i++) {
            if (i === 1) {
                parts.push(
                    <a key={"link1"} href={urls[0]} rel="noopener noreferrer" target="_blank">
                        <p>{splittedText[i] + " "}</p>
                    </a>
                );
            } else if (i === 3) {
                parts.push(
                    <a key={"link2"} href={urls[1]} rel="noopener noreferrer" target="_blank">
                        <p>{splittedText[i] + " "}</p>
                    </a>
                );
            } else if (i === 5) {
                parts.push(
                    <a key={"link3"} href={urls[2]} rel="noopener noreferrer" target="_blank">
                        <p>{splittedText[i] + " "}</p>
                    </a>
                );
            } else {
                let splittedPart = splittedText[i].trim().split(' ');
                for (let j = 0; j < splittedPart.length; j++) {
                    parts.push(
                        <p key={"text" + i + j}>{splittedPart[j] + " "}</p>
                    );
                }
            }
        }

        return parts;
    };

    renderLanguageInput = () => {
        return (
            <div className="language-container"
                 onClick={async () => {
                     await this.promisedSetState({languageSelectionOpen: true});
                 }}>
                <img className="language-flag"
                     alt={'flag-' + i18next.language.substr(0, 2)}
                     src={this.props.locales[i18next.language.substr(0, 2)].imageUrl}/>
                <p className="language-label">
                    {this.props.locales[i18next.language.substr(0, 2)].label}
                </p>
                <p className="language-selection-icon">
                    >
                </p>
            </div>
        )
    };

    renderLanguageSelection = () => {
        if (this.state.languageSelectionOpen) {
            let options = [];
            for (let key in this.props.locales) {
                if (this.props.locales.hasOwnProperty(key)) {
                    options.push(
                        <div className="language-container-list-item"
                             key={key} onClick={async () => {
                            await i18next.changeLanguage(key);
                            await this.promisedSetState({languageSelectionOpen: false});
                        }}>
                            <img className="language-flag"
                                 alt={'flag-' + key}
                                 src={this.props.locales[key].imageUrl}/>
                            <p className="language-label">
                                {this.props.locales[key].label}
                            </p>
                        </div>
                    );
                }
            }

            return (
                <div className="language-container-list">
                    {
                        options
                    }
                </div>
            );
        }
    };

    renderGoToLogin = () => {
        if (this.props.codeType === Constants.CODE_TYPE.COURSE_CODE) {
            return(
                <button className="go-to-login-text" onClick={this.props.goToLogin}>
                    <p>
                        {i18next.t("BUTTON_TEXT__ALREADY_HAVE_ACCOUNT")}
                    </p>
                </button>
            )
        }
    };

    renderRegisterButtonText = () => {
        if (this.state.registering) {
            return (
                <Sentry size={12}/>
            );
        } else {
            return (
                <p>
                    {i18next.t("BUTTON__ACCEPT_AND_START")}
                </p>
            )
        }
    }

    render () {
        return (
            <div className="content-container">
                <p>
                    {
                        i18next.t('WEB_INFO__PLEASE_REGISTER')
                    }
                </p>
                <input
                    type="email"
                    onChange={this.changeMailText}
                    value={this.state.mail}
                    placeholder={i18next.t("FORM_PLACEHOLDER__EMAIL_ADRESS")}
                />
                <input
                    type="name"
                    onChange={this.changeNameText}
                    value={this.state.name}
                    placeholder={i18next.t("FORM_PLACEHOLDER__PLAYER_NAME")}
                />
                <input
                    type="password"
                    onChange={this.changePasswordText}
                    value={this.state.password}
                    placeholder={i18next.t("FORM_PLACEHOLDER__PASSWORD")}
                />
                <input
                    type="password"
                    onChange={this.changePasswordConfirmationText}
                    value={this.state.passwordConfirmation}
                    placeholder={i18next.t("FORM_PLACEHOLDER__PASSWORD_CONFIRMATION")}
                />
                {
                    this.renderLanguageInput()
                }
                {
                    this.renderGoToLogin()
                }
                <div className="terms-outer-container">
                    <div className="terms-container">
                        {this.renderTermsAndDataLinks()}
                    </div>
                </div>
                <button
                    disabled={this.state.registering}
                    onClick={this.registerWithMail}>
                    {this.renderRegisterButtonText()}
                </button>
                {
                    this.renderLanguageSelection()
                }
            </div>
        );
    }

}

export default Registration;
