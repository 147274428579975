import React, {Component} from 'react';
import i18next from "i18next";
import FirebaseDatabaseService from "../services/FirebaseDatabaseService";
import DataHelper from "../helpers/DataHelper";

class Login extends Component {

    firebaseDatabaseService = new FirebaseDatabaseService();

    props: {
        goToCode: () => mixed,
        goBackToRegistration: () => mixed,
        sponsorId: number
    };

    state : {
        mail: string,
        password: string
    } = {
        mail: "",
        password: ""
    };

    changeMailText = (event) => {
        let text = event.target.value.replace(/\s/g, "");
        this.setState({mail: text});
    };

    changePasswordText = (event) => {
        let text = event.target.value.replace(/\s/g, "");
        this.setState({password: text});
    };

    checkSponsor = async (uid) => {
        let userSponsors = await this.firebaseDatabaseService.getUserSponsors(uid);
        let validCommunity = false;
        for (let i = 0; i < userSponsors.length; i++) {
            if (userSponsors[i].SponsorID === this.props.sponsorId) {
                validCommunity = true;
                break;
            }
        }
        return validCommunity;
    }

    loginWithMail = async () => {
        if (!DataHelper.verifyMail(this.state.mail)) {
            alert(
                i18next.t('ALERT__ERROR_INVALID_EMAIL_TITLE')
            );
        }
        try {
            const authUser = await this.firebaseDatabaseService.signInWithMail(this.state.mail, this.state.password);
            if (await this.checkSponsor(authUser.uid)) {
                await this.props.goToCode(authUser.uid, this.state.mail, DataHelper.encryptPassword(this.state.password), true);
            } else {
                alert(
                    i18next.t('MESSAGE__COMMUNITY_NOT_VALID')
                )
            }

        } catch (e) {
            alert(e);
        }
    };

    render () {

        return (
            <div className="content-container">

                <div className="info-text">
                    <p>{i18next.t("DESCRIPTION_LOGIN__LOGIN_WITH_MAIL")}</p>
                </div>
                <input
                    type="email"
                    onChange={this.changeMailText}
                    value={this.state.mail}
                    placeholder={i18next.t("FORM_PLACEHOLDER__EMAIL_ADRESS")}
                />
                <input
                    type="password"
                    onChange={this.changePasswordText}
                    value={this.state.password}
                    placeholder={i18next.t("FORM_PLACEHOLDER__PASSWORD")}
                />

                <button onClick={this.loginWithMail}>
                    <p>
                        {i18next.t("BUTTON__LOGIN")}
                    </p>
                </button>
                <button onClick={this.props.goBackToRegistration}>
                    <p>
                        {i18next.t("GLOBAL__BACK")}
                    </p>
                </button>
            </div>
        )

    }

}

export default Login;
