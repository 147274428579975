
import React, {Component} from 'react';
import Card from '../components/matchComponents/Card';
import WordModel from '../models/WordModel';
import AudioPlayer from "../components/matchComponents/AudioPlayer";
import AnswerFourWords from "../components/matchComponents/AnswerFourWords";

type Props = {
    matchWord: { word: WordModel, possibleAnswers: Array<WordModel> },
    testLanguage: string,
    correctAnswerGiven: () => mixed,
    wrongAnswerGiven: () => mixed
};

type State = {
    show: string,
    chosenAnswer: ?number
};

class AudioGivenChooseWord extends Component<Props, State> {

    state = {
        show: 'none',
        chosenAnswer: null
    };

    shouldComponentUpdate = (nextProps: Props, nextState: State) => {
          return this.props.matchWord !== nextProps.matchWord || this.state.show !== nextState.show || this.state.chosenAnswer !== nextState.chosenAnswer;
    };

    card = null;
    clickable: boolean = true;

    correctAnswerGiven = () => {
        if(this.card){
            this.card.swipeCardOut();
        }
        setTimeout(() => {
            if(this.props.correctAnswerGiven){
                this.props.correctAnswerGiven();
            }
        }, 250);
    };

    wrongAnswerGiven = () => {
        if(this.card){
            this.card.swipeCardOut();
        }
        setTimeout(() => {
            if(this.props.wrongAnswerGiven){
                this.props.wrongAnswerGiven();
            }
        }, 250);
    };

    renderWord = () => {
        return (
            <div className="word-container">
                <AudioPlayer source={this.props.matchWord.word.getSoundfile()} testLanguage={this.props.testLanguage}/>
            </div>
        )
    };

    renderContent = () => {
        return (
            <div className="content-container-exercise">
                {
                    this.renderWord()
                }
                <AnswerFourWords
                    matchWord={this.props.matchWord}
                    correctAnswerGiven={this.correctAnswerGiven}
                    wrongAnswerGiven={this.wrongAnswerGiven}/>
            </div>
        )
    };

    render() {
        return (
            <div className="match-container">
                <Card front={this.renderContent()} ref={c => this.card = c}/>
            </div>
        );
    }
}

export default AudioGivenChooseWord;
