// @flow
import React, {Component} from 'react';
import posed from 'react-pose';

type Props = {
    front: any,
    back?: any,
    cardFlipped?: () => mixed,
    cardSwipedIn?: () => mixed,
    cardSwipedOut?: () => mixed,
};

type State = {
    flipped: boolean,
    flipping: boolean,
    move: string
};

const CardContainer = posed.div({
    front: {rotateY: "0deg", transition: {duration: 250}},
    flipping: {rotateY: "90deg", transition: {duration: 250}},
    back: {rotateY: "180deg", transition: {duration: 250}},
    pressable: true
});

const CardMoveContainer = posed.div({
    in: {x: "100%", y: -20, opacity: 0, rotate: "8deg", transition: {duration: 250}},
    normal: {x: 0, y: 0,  rotate: "0deg", opacity: 1, transition: {duration: 250}},
    out: {x: "-100%", y: 20,  rotate: "-8deg", opacity: 0, transition: {duration: 250}},
    initialPose: "in"
});

class Card extends Component<Props, State> {

    state = {
        flipping: false,
        flipped: false,
        move: "in"
    };

    componentDidMount = () => {
        this.swipeCardIn();
    };

    renderCardContent = () => {
        if (this.state.flipped) {
            return this.props.back;
        } else {
            return this.props.front;
        }
    };

    flipCard = () => {
        console.log("flip");
        console.log(this.state.flipped);
        this.setState({flipping: true});
        setTimeout(() => {
            this.setState({flipping: false, flipped: !this.state.flipped});
            setTimeout(() => {
                if(typeof this.props.cardFlipped !== 'undefined'){
                    this.props.cardFlipped();
                }
            }, 250);
        }, 250);
    };

    swipeCardIn = () => {
        this.setState({move: "normal"});
        setTimeout(() => {
            if(typeof this.props.cardSwipedIn !== 'undefined'){
                this.props.cardSwipedIn();
            }
        }, 250);
    };

    swipeCardOut = () => {
        this.setState({move: "out"});
        setTimeout(() => {
            this.setState({move: "in", flipped: false});
            if(typeof this.props.cardSwipedOut !== 'undefined'){
                this.props.cardSwipedOut();
            }
        }, 250);
    };

    onCardClicked = () => {
        if(this.state.move === "in"){
            this.swipeCardIn();
        } else if (this.state.move === "normal"){
            if(!this.state.flipped){
                this.flipCard();
            } else {
                this.swipeCardOut();
            }
        }
    };

    render() {
        return (
            <CardMoveContainer className="card-move-container" pose={this.state.move}>
                <CardContainer className="card-container"
                               pose={this.state.flipping ? 'flipping' : this.state.flipped ? 'back' : 'front'}>
                    {
                        this.renderCardContent()
                    }
                </CardContainer>
            </CardMoveContainer>
        );
    }
}

export default Card;
