
import React, {Component} from 'react';
import MatchModel from '../../models/MatchModel';
import GameConstants from '../../constants/GameConstants';
import i18next from 'i18next';

type Props = {
    match: MatchModel,
    onlineState: string,
    avatarImageSource: string
};

class Standing extends Component<Props> {

    renderOnlineState = () => {
        switch (this.props.onlineState) {
            case GameConstants.ONLINE_STATES.ONLINE:
                return (
                    <div className="online-state-container">
                        <div className="online-bubble online"></div>
                        <div className="online-bubble online"></div>
                        <div className="online-bubble online"></div>
                    </div>
                );
            case GameConstants.ONLINE_STATES.LOW_CONNECTION:
                return (
                    <div className="online-state-container">
                        <div className="online-bubble low"></div>
                        <div className="online-bubble low"></div>
                        <div className="online-bubble disabled"></div>
                    </div>
                );
            case GameConstants.ONLINE_STATES.OFFLINE:
                return (
                    <div className="online-state-container">
                        <div className="online-bubble offline"></div>
                        <div className="online-bubble disabled"></div>
                        <div className="online-bubble disabled"></div>
                    </div>
                );
            default:
                return (<div></div>)
        }
    };

    renderUsername = (username: string) => {
        if(username === "You"){
            return i18next.t("WEB_LIVE_MATCH__YOU");
        } else {
            return username;
        }
    };

    render() {
        return (
            <div className="standing-outer-container">
                <div className="home-user">
                    <img src={this.props.avatarImageSource} className="trikot" alt="LearnMatch Trikot"/>
                    <p className="username">{this.renderUsername(this.props.match.getHomeUser().getUserName())}</p>
                </div>
                <div className="standings">
                    <p className="standings-text">
                        {
                            this.props.match.getHomeUser().getScore() + ":" + this.props.match.getGuestUser().getScore()
                        }
                    </p>

                    {
                        this.renderOnlineState()
                    }
                </div>
                <div className="guest-user">
                    <img src={require('../../assets/img/Girl.png')} className="trikot" alt="LearnMatch Trikot"/>
                    <p className="username">{this.renderUsername(this.props.match.getGuestUser().getUserName())}</p>
                </div>
            </div>
        );
    }
}

export default Standing;
