import React, {Component} from 'react';
import i18next from "i18next";


class BranchLink extends Component {

    props: {
        branchLink: string,
        userLoggedIn: boolean
    };

    render () {
        const successMessage = this.props.userLoggedIn ? i18next.t("WEB__CODE_SUCCESSFULLY_REDEEMED") : i18next.t("WEB__SUCCESSFULLY_REGISTERED");
        return (
            <div className="content-container">
                <p>{successMessage}</p>
                <p><a href={this.props.branchLink}>{ this.props.branchLink }</a></p>
                <p>{i18next.t("GLOBAL__OR")}</p>
                <div className="download-images">
                    <a href={this.props.branchLink}><img src={require('../assets/img/google-play-badge.png')} className="download-link-image" alt="Download App on Google Play"/></a>
                    <a href={this.props.branchLink}><img src={require('../assets/img/download-app-store.svg')} className="download-link-image" alt="Download App on Apple AppStore"/></a>
                </div>
            </div>
        )
    }
}

export default BranchLink;
