// @flow
// Careful: Do not use for data transfer since this object does not contain all translations
export default class WordModel {

    _id: number = 1;
    _word: string = "";
    _phrase: string = "";
    _phraseWord: string = "";
    _wordPackage: number = 1;
    _topic: string = "";
    _link: string = "";
    _level: number = 1;
    _soundfile: string = "";
    _soundfileSentence: string = "";
    _picture: string = "";
    _translation: string = "";
    _phraseTranslation: string = "";
    _phase: number = 1;
    _misc: string = "";
    _grammar: string = "";
    _linguistikContext: string = "";

    static _createFromParams = (id: number, word: string, phrase: string, phraseWord: string,
                                wordPackage: number, topic: string, link: string, level: number,
                                soundfile: string, soundfileSentence: string, picture: string, translation: string,
                                phraseTranslation: string, phase: number, misc: string, grammar: string, linguisticContext: string): WordModel => {
        let wordObject = new WordModel();
        wordObject._setId(id);
        wordObject._setWord(word);
        wordObject._setPhrase(phrase);
        wordObject._setPhraseWord(phraseWord);
        wordObject._setWordPackage(wordPackage);
        wordObject._setTopic(topic);
        wordObject._setLink(link);
        wordObject._setLevel(level);
        wordObject._setSoundfile(soundfile);
        wordObject._setSoundfileSentence(soundfileSentence);
        wordObject._setPicture(picture);
        wordObject._setTranslation(translation);
        wordObject._setPhraseTranslation(phraseTranslation);
        wordObject.setPhase(phase);
        wordObject._setMisc(misc);
        wordObject._setGrammar(grammar);
        wordObject._setLinguistikContext(linguisticContext);
        return wordObject;
    };

    static createFromObject = (wordJsonObject: any, locale: string): WordModel => {
        let translation = "";
        let phraseTranslation = "";
        for (let key in wordJsonObject) {
            if (wordJsonObject.hasOwnProperty(key)) {
                if (key.indexOf("Trans" + WordModel.mapLocale(locale)) > -1) {
                    translation = wordJsonObject[key];
                } else if (key.indexOf("Phrase" + WordModel.mapLocale(locale)) > -1) {
                    phraseTranslation = wordJsonObject[key];
                }
            }
        }
        let phase: number = isNaN(wordJsonObject.Phase) ? 1 : wordJsonObject.Phase;
        let misc: string = wordJsonObject.Misc ? wordJsonObject.Misc : '';
        let grammar: string = wordJsonObject.Grammar ? wordJsonObject.Grammar : '';
        let linguistikContext: string = wordJsonObject.LinguistikContext ? wordJsonObject.LinguistikContext : '';
        return WordModel._createFromParams(+wordJsonObject.ID, wordJsonObject.Word, wordJsonObject.Phrase,
            wordJsonObject.PhraseWord, +wordJsonObject.Package, wordJsonObject.Topic, wordJsonObject.Link,
            +wordJsonObject.Level, wordJsonObject.Soundfile, wordJsonObject.SoundfileSentence,
            wordJsonObject.Picture, translation, phraseTranslation, phase, misc, grammar, linguistikContext);
    };

    static createFromObjectMultiple = (wordsJsonArray: Array<Object>, locale: string): Array<WordModel> => {
        let wordsArray = [];
        for (let i = 0; i < wordsJsonArray.length; i++) {
            wordsArray.push(WordModel.createFromObject(wordsJsonArray[i], locale));
        }
        return wordsArray;
    };

    getId = (): number => {
        return this._id;
    };

    _setId = (id: number) => {
        this._id = id;
    };

    getWord = (): string => {
        return this._word;
    };

    _setWord = (word: string) => {
        this._word = word;
    };

    getPhrase = () => {
        return this._phrase;
    };

    _setPhrase = (phrase: string) => {
        this._phrase = phrase;
    };

    getPhraseWord = (): string => {
        return this._phraseWord;
    };

    _setPhraseWord = (phraseWord: string) => {
        this._phraseWord = phraseWord;
    };

    getWordPackage = (): number => {
        return this._wordPackage;
    };

    _setWordPackage = (wordPackage: number) => {
        this._wordPackage = wordPackage;
    };

    getTopic = (): string => {
        return this._topic;
    };

    _setTopic = (topic: string) => {
        this._topic = topic;
    };

    getLink = (): string => {
        return this._link;
    };

    _setLink = (link: string) => {
        this._link = link;
    };

    getLevel = (): number => {
        return this._level;
    };

    _setLevel = (level: number) => {
        this._level = level;
    };

    getSoundfile = (): string => {
        return this._soundfile;
    };

    _setSoundfile = (soundfile: string) => {
        this._soundfile = soundfile;
    };

    getSoundfileSentence = (): string => {
        return this._soundfileSentence;
    };

    _setSoundfileSentence = (soundfileSentence: string) => {
        this._soundfileSentence = soundfileSentence;
    };

    getPicture = (): string => {
        return this._picture;
    };

    _setPicture = (picture: string) => {
        this._picture = picture;
    };

    getTranslation = (): string => {
        return this._translation;
    };

    _setTranslation = (translation?: string) => {
        if(translation) {
            this._translation = translation;
        }
    };

    getPhraseTranslation = (): string => {
        return this._phraseTranslation;
    };

    _setPhraseTranslation = (phraseTranslation?: string) => {
        if(phraseTranslation) {
            this._phraseTranslation = phraseTranslation;
        }
    };

    getPhase = (): number => {
        return this._phase;
    };

    setPhase = (phase: number) => {
        this._phase = phase;
    };

    getMisc = (): string => {
        return this._misc;
    };

    _setMisc = (misc: string) => {
        this._misc = misc;
    };

    getGrammar = (): string => {
        return this._grammar;
    };

    _setGrammar = (grammar: string) => {
        this._grammar = grammar;
    };

    getLinguistikContext = (): string => {
        return this._linguistikContext;
    };

    _setLinguistikContext = (linguistikContext: string) => {
        this._linguistikContext = linguistikContext;
    };

    static serialize = (word: WordModel, locale: string): any => {
        let returnWord = {
            ID: word.getId(),
            Word: word.getWord(),
            Phrase: word.getPhrase(),
            PhraseWord: word.getPhraseWord(),
            Package: word.getWordPackage(),
            Topic: word.getTopic(),
            Link: word.getLink(),
            Level: word.getLevel(),
            Soundfile: word.getSoundfile(),
            SoundfileSentence: word.getSoundfileSentence(),
            Picture: word.getPicture(),
            Phase: word.getPhase(),
            Misc: word.getMisc(),
            Grammar: word.getGrammar(),
            LinguistikContext: word.getLinguistikContext(),
        };
        returnWord["Trans" + WordModel.mapLocale(locale)] = word.getTranslation();
        returnWord["Phrase" + WordModel.mapLocale(locale)] = word.getPhraseTranslation();
        return returnWord;
    };

    static serializeMultiple = (words: Array<WordModel>, locale: string): any => {
        let wordsJsonArray = [];
        words.forEach((word) => {
            wordsJsonArray.push(WordModel.serialize(word, locale));
        });
        return wordsJsonArray;
    };

    static mapLocale = (locale: string): string => {
        switch(locale){
            case "er":
                return "esar";
            case "ex":
                return "esmx";
            case "br":
                return "ptbr";
            default:
                return locale;
        }
    }
}
