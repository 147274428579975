// @flow
class StatisticsModel {
    _id: number = 1;
    _score: number = 0;
    _timePlayed: number = 0;
    _matchesPlayed: number = 0;
    _trainingsScore: number = 0;
    _matchesScore: number = 0;
    _sessionsCount: number = 0;

    static createFromParams = (score: number, timePlayed: number, matchesPlayed: number, trainingsScore: number,
        matchesScore: number, sessionsCount: number): StatisticsModel => {
        const statisticsObject = new StatisticsModel();
        statisticsObject.setScore(score);
        statisticsObject.setTimePlayed(timePlayed);
        statisticsObject.setMatchesPlayed(matchesPlayed);
        statisticsObject.setTrainingsScore(trainingsScore);
        statisticsObject.setMatchesScore(matchesScore);
        statisticsObject.setSessionsCount(sessionsCount);
        return statisticsObject;
    };

    static createFromObject = (statisticsJsonObject: any): StatisticsModel => {
        return StatisticsModel.createFromParams(statisticsJsonObject.Score, statisticsJsonObject.TimePlayed, statisticsJsonObject.MatchesPlayed,
            statisticsJsonObject.TrainingsScore, statisticsJsonObject.MatchesScore, statisticsJsonObject.SessionsCount);
    };

    getId = (): number => {
        return this._id;
    };

    getScore = (): number => {
        return this._score;
    };

    setScore = (score: number) => {
        this._score = score;
    };

    getTimePlayed = (): number => {
        return this._timePlayed;
    };

    setTimePlayed = (timePlayed: number) => {
        this._timePlayed = timePlayed;
    };

    getMatchesPlayed = (): number => {
        return this._matchesPlayed;
    };

    setMatchesPlayed = (matchesPlayed: number) => {
        this._matchesPlayed = matchesPlayed;
    };

    getTrainingsScore = (): number => {
        return this._trainingsScore;
    };

    setTrainingsScore = (trainingsScore: number) => {
        this._trainingsScore = trainingsScore;
    };

    getMatchesScore = (): number => {
        return this._matchesScore;
    };

    setMatchesScore = (matchesScore: number) => {
        this._matchesScore = matchesScore;
    };

    getSessionsCount = (): number => {
        return this._sessionsCount;
    };

    setSessionsCount = (sessionsCount: number) => {
        this._sessionsCount = sessionsCount;
    };

    static serialize = (statistics: StatisticsModel): any => {
        return {
            ID: statistics.getId(),
            Score: statistics.getScore(),
            TimePlayed: statistics.getTimePlayed(),
            MatchesPlayed: statistics.getMatchesPlayed(),
            TrainingsScore: statistics.getTrainingsScore(),
            MatchesScore: statistics.getMatchesScore(),
            SessionsCount: statistics.getSessionsCount()
        };
    };
}

export default StatisticsModel;
