// @flow
import SkillsModel from './SkillsModel';

class UserLanguageModel {
    _id: number = 1;
    _language: string = '';
    _title: string = '';
    _validUntil: ?number = null;
    _fitness: number = 1;
    _skills: SkillsModel = SkillsModel.createFromParams(0, 1);
    _sessionCount: number = 1;
    _newSetsCount: number = 1;
    _availableLanguages: Array<string> = [];

    static createFromParams = (id: number, language: string, title: string, sessionCount: number,
        newSetsCount: number, validUntil?: ?number): UserLanguageModel => {
        const userLanguageObject = new UserLanguageModel();
        userLanguageObject.setId(id);
        userLanguageObject.setLanguage(language);
        userLanguageObject.setTitle(title);
        userLanguageObject.setSessionCount(sessionCount);
        userLanguageObject.setNewSetsCount(newSetsCount);
        userLanguageObject.setValidUntil(validUntil);
        return userLanguageObject;
    };

    static createFromObject = (userLanguageJsonObject: any): UserLanguageModel => {
        return UserLanguageModel.createFromParams(userLanguageJsonObject.ID, userLanguageJsonObject.Language,
            userLanguageJsonObject.Title, userLanguageJsonObject.SessionCount, userLanguageJsonObject.NewSetsCount, userLanguageJsonObject.ValidUntil);
    };

    static createMultipleFromObject = (userLanguagesJsonArray: Array<any>): Array<UserLanguageModel> => {
        const userLanguageArray = [];
        for (let i = 0; i < userLanguagesJsonArray.length; i++) {
            userLanguageArray.push(UserLanguageModel.createFromObject(userLanguagesJsonArray[i]));
        }
        return userLanguageArray;
    };

    getId = (): number => {
        return this._id;
    };

    setId = (id: number) => {
        this._id = id;
    };

    getLanguage = (): string => {
        return this._language;
    };

    setLanguage = (language: string) => {
        this._language = language;
    };

    getTitle = (): string => {
        return this._title;
    };

    getCleanedTitle = (): string => {
        return this._title.replace(/__[^_]*__/g, '');
    };

    setTitle = (title: string) => {
        this._title = title;
    };

    getSessionCount = (): number => {
        return this._sessionCount;
    };

    setSessionCount = (sessionCount: number) => {
        this._sessionCount = sessionCount;
    };

    getNewSetsCount = (): number => {
        return this._newSetsCount;
    };

    setNewSetsCount = (newSetsCount: number) => {
        this._newSetsCount = newSetsCount;
    };

    getFitness = (): number => {
        return this._fitness;
    };

    setFitness = (fitness: number) => {
        this._fitness = fitness;
    };

    getSkills = (): SkillsModel => {
        return this._skills;
    };

    setSkills = (skills: SkillsModel) => {
        this._skills = skills;
    };

    getAvailableLanguages = (): Array<string> => {
        return this._availableLanguages;
    };

    setAvailableLanguages = (availableLanguages: Array<string>) => {
        this._availableLanguages = availableLanguages;
    };

    getValidUntil = (): ?number => {
        return this._validUntil;
    };

    setValidUntil = (validUntil: ?number) => {
        this._validUntil = validUntil;
    }

    static serialize = (userLanguage: UserLanguageModel): any => {
        return {
            ID: userLanguage.getId(),
            Language: userLanguage.getLanguage(),
            Title: userLanguage.getTitle(),
            SessionCount: userLanguage.getSessionCount(),
            NewSetsCount: userLanguage.getNewSetsCount(),
            ValidUntil: userLanguage.getValidUntil()
        };
    };

    static serializeWithoutId = (userLanguage: UserLanguageModel): any => {
        return {
            Language: userLanguage.getLanguage(),
            Title: userLanguage.getTitle(),
            SessionCount: userLanguage.getSessionCount(),
            NewSetsCount: userLanguage.getNewSetsCount(),
            ValidUntil: userLanguage.getValidUntil()
        };
    };

    static serializeMultiple = (userLanguages: Array<UserLanguageModel>): any => {
        const userLanguagesJsonArray = [];
        userLanguages.forEach((userLanguage) => {
            userLanguagesJsonArray.push(UserLanguageModel.serialize(userLanguage));
        });
        return userLanguagesJsonArray;
    };

    static serializeWithoutIdMultiple = (userLanguages: Array<UserLanguageModel>): any => {
        const userLanguagesJsonArray = [];
        userLanguages.forEach((userLanguage) => {
            userLanguagesJsonArray.push(UserLanguageModel.serializeWithoutId(userLanguage));
        });
        return userLanguagesJsonArray;
    };
}

export default UserLanguageModel;
