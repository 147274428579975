import React, {Component} from 'react';
import WordModel from '../../models/WordModel';
import posed from "react-pose";

type Props = {
    matchWord: { word: WordModel, possibleAnswers: Array<WordModel> },
    correctAnswerGiven: () => mixed,
    wrongAnswerGiven: () => mixed
};

type State = {
    show: string,
    chosenAnswer: ?number
};

const Answer = posed.div({
    normal: {background: "#ffffff", scale: 1, transition: {duration: 250}},
    error: {background: "#B20000", scale: 1.1, transition: {duration: 250}},
    correct: {background: "#009900", scale: 1, transition: {duration: 250}},
    initialPose: "normal"
});

class AnswerFourWords extends Component<Props, State> {

    state = {
        show: 'none',
        chosenAnswer: null
    };

    shouldComponentUpdate = (nextProps: Props, nextState: State) => {
          return this.props.matchWord !== nextProps.matchWord || this.state.show !== nextState.show || this.state.chosenAnswer !== nextState.chosenAnswer;
    };

    card = null;
    clickable: boolean = true;

    correctAnswerGiven = () => {
        if(this.props.correctAnswerGiven){
            this.props.correctAnswerGiven();
        }
    };

    wrongAnswerGiven = () => {
        if(this.props.wrongAnswerGiven){
            this.props.wrongAnswerGiven();
        }
    };

    clickAnswer = (id: number) => {
        if(this.clickable){
            this.clickable = false;
            if(this.props.matchWord.word.getId() === id){
                this.setState({show: "correct"});
                this.correctAnswerGiven();
            } else {
                console.log("set state to: ", id);
                this.setState({chosenAnswer: id, show: "error"});
                setTimeout(() => {
                    this.setState({show: "correct"});
                }, 250);
                setTimeout(() => {
                    this.wrongAnswerGiven();
                }, 1000);
            }
        }
    };

    render = () => {
        let answerLinks = [];
        for(let i = 0; i < this.props.matchWord.possibleAnswers.length; i++){
            let showCorrect: boolean = this.state.show === "correct" && this.props.matchWord.possibleAnswers[i].getId() === this.props.matchWord.word.getId();
            let showError: boolean = this.state.show === "error" && this.state.chosenAnswer === this.props.matchWord.possibleAnswers[i].getId();

            answerLinks.push(
                <Answer
                    onClick={() => this.clickAnswer(this.props.matchWord.possibleAnswers[i].getId())}
                    className="answer-button"
                    key={"answer_" + i}
                    pose={showCorrect ? "correct" : showError ? "error" : "normal"}>
                    <p>
                        {
                            this.props.matchWord.possibleAnswers[i].getWord()
                        }
                    </p>
                </Answer>
            )
        }
        return (
            <div className="answers-container">
                {
                    answerLinks
                }
            </div>
        );

    };
}

export default AnswerFourWords;
