import {Component} from 'react';

class PromiseComponent extends Component {

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    };
}

export default PromiseComponent;