// @flow


export default class DataFunctions {


    static shuffle(array: Array<any>): Array<any>{
        let counter = array.length, temp, index;

        // While there are elements in the array
        while (counter > 0) {
            // Pick a random index
            index = Math.floor(Math.random() * counter);

            // Decrease counter by 1
            counter--;

            // And swap the last element with it
            temp = array[counter];
            array[counter] = array[index];
            array[index] = temp;
        }
        return array;
    }

    static createAlmostUniqueId(): string {

        let auid: string = "";
        let charArray = [];
        for(let i = 0; i < 10; i++) {
            charArray.push(i);
        }
        for(let i = 0; i < 25; i++) {
            let charNumber = 65 + i;
            let char = String.fromCharCode(charNumber);
            charArray.push(char);
        }
        for(let i = 0; i < 25; i++) {
            let charNumber = 97 + i;
            let char = String.fromCharCode(charNumber);
            charArray.push(char);
        }

        for(let k = 1; k < 9; k++) {
            let len = charArray.length;
            let index = Math.floor(Math.random() * len);
            auid += charArray[index];
        }

        return auid;
    }


}

