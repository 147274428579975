
import React from 'react';
import MatchModel from '../models/MatchModel';
import WordModel from '../models/WordModel';
import UserModel from '../models/UserModel';
import GameConstants from '../constants/GameConstants';
import Config from '../constants/Config';
import PromiseComponent from '../PromiseComponent';
import ExerciseFunctions from '../helpers/ExerciseFunctions';
import Bot from '../helpers/Bot';
import Loading from '../components/Loading';
import Match from '../components/Match';
import LocalMatchService from "../services/LocalMatchService";
import Progress from "../components/matchComponents/Progress";
import Standing from "../components/matchComponents/Standing";
import MatchWaiting from "../components/MatchWaiting";
import MatchEndResult from "../components/MatchEndResult";
import DataFunctions from "../helpers/DataFunctions";
import TestingContentMap from "../maps/TestingContentMap";
import RecommendedCoursesMap from "../maps/RecommendedCoursesMap";
import SharedConstants from "../constants/SharedConstants";
import i18next from 'i18next';
import FirebaseDatabaseService from '../services/FirebaseDatabaseService';


class LanguageTest extends PromiseComponent {

    props: {
        deviceLanguage: string,
        testCourse: string,
        user: UserModel,
        goOnWithTest: (language: string, recommendedTest: string) => mixed
    };

    state = {
        loading: false,
        matchId: "",
        match: MatchModel.createFromObject(GameConstants.ASYNC_RACE_MATCH),
        content: [],
        matchWordsIndex: 0,
        user: {},
        userType: Config.TYPE === "LOCAL" ? GameConstants.PLAYER_TYPE.HOME_USER : GameConstants.PLAYER_TYPE.GUEST_USER,
        opponentType: Config.TYPE === "LOCAL" ? GameConstants.PLAYER_TYPE.GUEST_USER : GameConstants.PLAYER_TYPE.HOME_USER,
        lastPings: {homeUser: Date.now(), guestUser: Date.now()},
        onlineState: GameConstants.ONLINE_STATES.ONLINE,
        now: Date.now(),
        adKey: "",
        sponsorID: "",
        invitorData: "",
        invitorMatchID: "",
        userId: "",
        eventPairKey: "",
        campaign: "",
        timeToEndMatch: SharedConstants.TIME_TO_END_MATCH,
        botPoints: Math.round(Math.random() * 50),
        urlParams: {},
        avatarImageSource: ''
    };

    matchService: LocalMatchService = new LocalMatchService();
    match: MatchModel = MatchModel.createFromObject(GameConstants.ASYNC_RACE_MATCH);

    timer: any = -1;
    ping: number = 10;
    botFreeze: number = 0;
    position: number = 0;
    lastUserMove: number = Date.now();

    componentWillMount = async () => {
        this.setState({user: this.props.user});
        await this.loadData();
        await this.onStartClicked();
    };

    loadData = async () => {
        await this.promisedSetState({loading: true});

        this.match = MatchModel.createFromObject(GameConstants.ASYNC_RACE_MATCH);

        // load content
        let content: Array<{ word: WordModel, possibleAnswers: Array<WordModel> }> = await this.preloadWordsTesting();

        this.matchService = new LocalMatchService();
        this.matchService.updateMatch(this.state.match);

        await this.promisedSetState({
            content: content,
            matchWordsIndex: 0
        });

        // join match
        let {user, match} = await this.matchService.joinMatch(this.state.user);
        const firebaseDatabaseService = new FirebaseDatabaseService();
        let avatarImageSource = await firebaseDatabaseService.getDownloadUrl('Images/SponsorTrikots/' + user.getCharacter());
        this.match = match;
        await this.promisedSetState({loading: false, user: user, match: match, avatarImageSource: avatarImageSource});

        // add match change handler for incoming match changes
        this.matchService.addMatchChangeHandler(this.handleMatchUpdate);
        this.matchService.addLastPingChangeHandler(this.handleMatchPingUpdate);
    };

    preloadWordsTesting = async (): Promise<Array<{ word: WordModel, possibleAnswers: Array<WordModel> }>> => {

        let contentPath = TestingContentMap[this.props.testCourse];

        // get words for content
        let words: Array<WordModel> = WordModel.createFromObjectMultiple(contentPath, this.props.deviceLanguage);
        let matchWordsTmp: Array<{ word: WordModel, possibleAnswers: Array<WordModel> }> = [];
        for (let i = this.position; i < words.length * 2 + this.position; i++) {
            let wordPosition = i % words.length;

            let matchWord: { word: WordModel, possibleAnswers: Array<WordModel> } = {
                word: words[wordPosition],
                possibleAnswers: []
            };

            let wordPositions: Array<number> = [];
            wordPositions.push(wordPosition);
            let possibleAnswers: Array<WordModel> = [];
            possibleAnswers.push(matchWord.word);

            for (let j = 0; j < 3; j++) {
                let wordPosition = Math.floor(Math.random() * words.length);
                let wordOk = true;
                // compare with already saved words for same id and to big similarity
                for (let u = 0; u < wordPositions.length; u++) {
                    if (wordPositions[u] === wordPosition || ExerciseFunctions.getLeveshteinDistance(words[wordPosition].getWord(), words[wordPositions[u]].getWord()) <= 1) {
                        wordOk = false;
                        break;
                    }
                }
                if (wordOk) {
                    wordPositions.push(wordPosition);
                    possibleAnswers.push(words[wordPosition]);
                } else {
                    j--;
                }
            }
            matchWord.possibleAnswers = DataFunctions.shuffle(possibleAnswers);
            matchWordsTmp.push(matchWord);
        }
        this.preloadContent(matchWordsTmp);
        return matchWordsTmp;
    };

    preloadContent = (matchWordsTmp: Array<{ word: WordModel, possibleAnswers: Array<WordModel> }>) => {
        let images = [];
        let audios = [];
        for (let i = 0; i < matchWordsTmp.length; i++) {
            if (matchWordsTmp[i].word.getPicture()) {
                images[i] = new Image();
                images[i].src = "/content/" + this.props.testCourse + "/pictures/" + matchWordsTmp[i].word.getPicture();
            }
            if (matchWordsTmp[i].word.getSoundfile()) {
                audios[i] = new Audio();
                audios[i].src = "/content/" + this.props.testCourse + "/audios/" + matchWordsTmp[i].word.getSoundfile();
            }
        }
    };

    startTimer = async () => {
        let duration = RecommendedCoursesMap[this.props.testCourse].Duration;
        let timeToEndMatch = (GameConstants.READY_PHASE_LENGTH + duration) * 1000;
        this.setState({timeToEndMatch: timeToEndMatch});
        this.timer = setInterval(async () => {
            // write Ping
            if (!this.match.getUserByType(this.state.userType).hasCollectedPoints() || this.state.lastPings[this.state.userType] < this.match.getServerStartTime() + timeToEndMatch) {
                if (this.ping < 15) {
                    this.ping++;
                } else {
                    this.ping = 0;
                    this.matchService.setLastPing(this.state.userType);
                    // write bot ping if local match
                    if (Config.TYPE === "LOCAL") {
                        this.matchService.setLastPing(this.state.opponentType);
                    }
                }
            }

            let userDeviceStartTime = this.match.getUserByType(this.state.userType).getUserDeviceStartTime();
            if (userDeviceStartTime) {
                // both players are ready
                let timePassed = this.state.now - userDeviceStartTime;

                // handle Bot
                if (Config.TYPE === "LOCAL" && !this.match.getGuestUser().hasCollectedPoints()) {
                    // is bot match and bot not finished
                    if (((timePassed > GameConstants.READY_PHASE_LENGTH * 1000 && timePassed < timeToEndMatch)) && this.botFreeze > 10) {
                        this.botFreeze = 0;
                        // is during match
                        if (this.botMove()) {
                            this.addScore(GameConstants.PLAYER_TYPE.GUEST_USER);
                        } else if (Math.random() < GameConstants.MATCH_V2_BOT_ERROR_THRESHOLD) {
                            this.addError(GameConstants.PLAYER_TYPE.GUEST_USER)
                        }
                    } else {
                        this.botFreeze++;
                    }
                    if (timePassed > timeToEndMatch) {
                        // game ended => set bot points collected
                        await this.matchService.collectPoints(this.match, this.state.opponentType, this.state.userType);
                    }
                }

                // handle match over
                if ((this.state.lastPings[this.state.opponentType] > this.state.match.getServerStartTime() + timeToEndMatch ||
                    this.state.match.getUserByType(this.state.opponentType).hasCollectedPoints()) &&
                    this.state.match.getUserByType(this.state.userType).getUserDeviceStartTime() + timeToEndMatch < this.state.now) {
                    this.matchService.setLastPing(this.state.userType);
                    clearInterval(this.timer);
                    if (!this.state.match.getUserByType(this.state.userType).hasCollectedPoints() && !this.collectedPoints) {
                        try {
                            this.collectedPoints = true;
                            this.match = await this.matchService.syncMatch(this.state.match, this.state.userType, this.state.opponentType, this.state.timeToEndMatch);
                        } catch (e) {
                            this.collectedPoints = false;
                        }
                    }
                }
                await this.promisedSetState({now: Date.now(), match: this.match});
            }
        }, GameConstants.MATCH_REFRESH_TIMER_MS);

    };


    /**
     * We write our updates locally, so we only handle updates by server or opponent here
     * @param match: match with remote updates from MatchService
     */
    handleMatchUpdate = async (match: MatchModel) => {
        let matchServerStartTime = match.getServerStartTime();

        // check both rdy and server time set
        if (!this.match.getUserByType(this.state.userType).getUserDeviceStartTime() &&
            match.getUserByType(this.state.userType).isReady() && match.getUserByType(this.state.opponentType).isReady() &&
            matchServerStartTime) {
            this.match.setServerStartTime(matchServerStartTime);
            let deviceStartTime = await this.matchService.getDeviceStartTime(matchServerStartTime);
            this.match.getUserByType(this.state.userType).setUserDeviceStartTime(deviceStartTime);
        }

        // check opponentScored
        if (this.match.getUserByType(this.state.opponentType).getScore() < match.getUserByType(this.state.opponentType).getScore()) {
            this.match.getUserByType(this.state.opponentType).setScore(match.getUserByType(this.state.opponentType).getScore());
        }

        // check opponentError
        if (this.match.getUserByType(this.state.opponentType).getErrors() < match.getUserByType(this.state.opponentType).getErrors()) {
            this.match.getUserByType(this.state.opponentType).setErrors(match.getUserByType(this.state.opponentType).getErrors());
        }

        // check opponentChanged Sticker
        if (this.match.getUserByType(this.state.opponentType).getSticker() < match.getUserByType(this.state.opponentType).getSticker()) {
            this.match.getUserByType(this.state.opponentType).setSticker(match.getUserByType(this.state.opponentType).getSticker());
        }

        // update opponent ready state
        if (this.match.getUserByType(this.state.opponentType).isReady() !== match.getUserByType(this.state.opponentType).isReady()) {
            this.match.getUserByType(this.state.opponentType).setReady(match.getUserByType(this.state.opponentType).isReady());
        }

        // update opponent has collected points & result
        if (match.getUserByType(this.state.opponentType).hasCollectedPoints()) {
            this.match.getUserByType(this.state.opponentType).setCollectedPoints(true);
            this.match.getUserByType(this.state.opponentType).setResult(match.getUserByType(this.state.opponentType).getResult());
        }
    };

    handleMatchPingUpdate = (ping: { homeUser: number, guestUser: number }) => {
        // check opponent present by Ping Offset
        this.setState({
            lastPings: ping,
            onlineState: this.checkOnlineState(ping)
        });
    };

    botMove = () => {
        let hasScored = Math.random() * Bot.getLevelByScore(this.state.match.getGuestUser().getUserScore());
        // dont let bot score if more than 2 points ahead of user and user has scored in last 10 seconds
        if (this.match.getGuestUser().getScore() - this.match.getHomeUser().getScore() >= 2 && Date.now() - this.lastUserMove < 10 * 1000) return false;
        return hasScored > 0.3;
    };

    addScore = async (userType: string) => {
        this.match.getUserByType(userType).setScore(this.match.getUserByType(userType).getScore() + 1);
        if (userType === GameConstants.PLAYER_TYPE.HOME_USER) this.position++;
        this.lastUserMove = Date.now();
        await this.matchService.setScore(userType, this.match.getUserByType(userType).getScore());
    };

    addError = async (userType: string) => {
        this.match.getUserByType(userType).setErrors(this.match.getUserByType(userType).getErrors() + 1);
        if (userType === GameConstants.PLAYER_TYPE.HOME_USER) this.position++;
        this.lastUserMove = Date.now();
        await this.matchService.setErrors(userType, this.match.getUserByType(userType).getErrors());
    };

    checkOnlineState = (ping: { homeUser: number, guestUser: number }): string => {
        let opponentsDiff = Math.abs(ping[GameConstants.PLAYER_TYPE.HOME_USER] - ping[GameConstants.PLAYER_TYPE.GUEST_USER]);
        if (opponentsDiff > 1000 * 15) {
            return GameConstants.ONLINE_STATES.OFFLINE;
        } else if (opponentsDiff > 1000 * 10) {
            return GameConstants.ONLINE_STATES.LOW_CONNECTION;
        } else {
            return GameConstants.ONLINE_STATES.ONLINE;
        }
    };

    onStartClicked = async () => {
        this.match.getUserByType(this.state.userType).setReady(true);
        await this.matchService.matchSetUserReady(this.state.userType, true);

        this.match.getUserByType(this.state.opponentType).setReady(true);
        await this.matchService.matchSetUserReady(this.state.opponentType, true);

        this.startTimer();
    };

    correctAnswerGiven = async () => {
        this.addScore(this.state.userType);
        await this.promisedSetState({loading: true});
        await this.promisedSetState({loading: false, matchWordsIndex: this.state.matchWordsIndex + 1});
    };

    wrongAnswerGiven = async () => {
        this.addError(this.state.userType);
        await this.promisedSetState({loading: true});
        await this.promisedSetState({loading: false, matchWordsIndex: this.state.matchWordsIndex + 1});
    };

    renderContent = () => {
        let userDeviceStartTime: number = 0;
        let tempUserDeviceStartTime: ?number = this.state.match.getUserByType(this.state.userType).getUserDeviceStartTime();
        if (typeof tempUserDeviceStartTime !== 'undefined' && tempUserDeviceStartTime !== null) {
            userDeviceStartTime = tempUserDeviceStartTime;
        }

        if (this.state.loading) {
            return <Loading />;
        }
        if (this.state.now - userDeviceStartTime < GameConstants.READY_PHASE_LENGTH * 1000) {
            let time = Math.round(Math.max((userDeviceStartTime + GameConstants.READY_PHASE_LENGTH * 1000 - this.state.now) / 1000, 0));
            let percentage = time / GameConstants.READY_PHASE_LENGTH * 100;
            return <MatchWaiting time={time} percentage={percentage}/>;
        }

        if (this.state.now - userDeviceStartTime < this.state.timeToEndMatch) {
            let timeRemaining = Math.round(Math.max((userDeviceStartTime + this.state.timeToEndMatch - this.state.now) / 1000, 0));
            return (
                <div className="match-outer-container">
                    <Standing onlineState={this.state.onlineState} match={this.state.match} avatarImageSource={this.state.avatarImageSource}/>
                    <Progress desc={i18next.t("DESCRIPTION_MATCH_STANDINGS__TIME_COLON")}
                        duration={RecommendedCoursesMap[this.props.testCourse].Duration}
                        time={timeRemaining}/>
                    <Match match={this.state.match}
                           type="image"
                           testLanguage={this.props.testCourse}
                           matchWord={this.state.content[this.state.matchWordsIndex]}
                           correctAnswerGiven={this.correctAnswerGiven}
                           wrongAnswerGiven={this.wrongAnswerGiven}
                           onClick={this.onStartClicked}/>
                </div>
            );
        }
        return (
            <MatchEndResult match={this.state.match}
                            sponsorID={this.state.sponsorID}
                            avatarImageSource={this.state.avatarImageSource}
                            goOnWithTest={this.props.goOnWithTest}
                            testLanguage={this.props.testCourse}/>
        );
    };


    render() {
        return (
            <div className="language-test">
                <div className="language-test-inner-container">
                    {
                        this.renderContent()
                    }
                </div>
            </div>
        );
    }
}

export default LanguageTest;
