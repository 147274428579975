// @flow
class SkillsModel {
    _gainedPoints: number = 1;
    _totalPoints: number = 1;

    static createFromParams = (gainedPoints: number, totalPoints: number): SkillsModel => {
        const skills = new SkillsModel();
        skills.setGainedPoints(gainedPoints);
        skills.setTotalPoints(totalPoints);
        return skills;
    };

    static createFromObject = (skillJsonObject: Object): SkillsModel => {
        return SkillsModel.createFromParams(skillJsonObject.gainedPoints, skillJsonObject.totalPoints);
    };

    setGainedPoints = (gainedPoints: number) => {
        this._gainedPoints = gainedPoints;
    };

    getGainedPoints = (): number => {
        return this._gainedPoints;
    };

    getTotalPoints = (): number => {
        return this._totalPoints;
    };

    setTotalPoints = (totalPoints: number) => {
        this._totalPoints = totalPoints;
    };

    static serialize = (skills: SkillsModel): { gainedPoints: number, totalPoints: number } => {
        return {
            gainedPoints: skills.getGainedPoints(),
            totalPoints: skills.getTotalPoints()
        };
    }
}

export default SkillsModel;
