// @flow
class SettingsModel {
    _id: number = 1;
    _vibration: boolean = false;
    _reminder: boolean = false;
    _autoPlayAudio: boolean = false;
    _backgroundAudio: boolean = false;
    _autoSync: boolean = false;
    _colorScheme: string = '#ffffff';
    _silentMode: boolean = false;
    _blankMode: boolean = false;
    _reportContent: boolean = false;

    static createFromParams = (vibration: boolean, reminder: boolean, autoPlayAudio: boolean, backgroundAudio: boolean,
        autoSync: boolean, colorScheme: string, silentMode: boolean, blankMode: boolean, reportContent: boolean): SettingsModel => {
        const settingsObject = new SettingsModel();
        settingsObject.setVibration(vibration);
        settingsObject.setReminder(reminder);
        settingsObject.setAutoPlayAudio(autoPlayAudio);
        settingsObject.setBackgroundAudio(backgroundAudio);
        settingsObject.setAutoSync(autoSync);
        settingsObject.setColorScheme(colorScheme);
        settingsObject.setSilentMode(silentMode);
        settingsObject.setBlankMode(blankMode);
        settingsObject.setReportContent(reportContent);
        return settingsObject;
    };

    static createFromObject = (settingsJsonObject: any): SettingsModel => {
        return SettingsModel.createFromParams(settingsJsonObject.Vibration, settingsJsonObject.Reminder, settingsJsonObject.AutoPlayAudio,
            settingsJsonObject.BackgroundAudio, settingsJsonObject.AutoSync, settingsJsonObject.ColorScheme, settingsJsonObject.SilentMode,
            settingsJsonObject.BlankMode, settingsJsonObject.ReportContent);
    };

    getId = (): number => {
        return this._id;
    };

    getVibration = (): boolean => {
        return this._vibration;
    };

    setVibration = (vibration: boolean) => {
        this._vibration = vibration;
    };

    getReminder = (): boolean => {
        return this._reminder;
    };

    setReminder = (reminder: boolean) => {
        this._reminder = reminder;
    };

    getAutoPlayAudio = (): boolean => {
        return this._autoPlayAudio;
    };

    setAutoPlayAudio = (autoPlayAudio: boolean) => {
        this._autoPlayAudio = autoPlayAudio;
    };

    getBackgroundAudio = (): boolean => {
        return this._backgroundAudio;
    };

    setBackgroundAudio = (backgroundAudio: boolean) => {
        this._backgroundAudio = backgroundAudio;
    };

    getAutoSync = (): boolean => {
        return this._autoSync;
    };

    setAutoSync = (autoSync: boolean) => {
        this._autoSync = autoSync;
    };

    getColorScheme = (): string => {
        return this._colorScheme;
    };

    setColorScheme = (colorScheme: string) => {
        this._colorScheme = colorScheme;
    };

    getSilentMode = (): boolean => {
        return this._silentMode;
    };

    setSilentMode = (silentMode: boolean) => {
        this._silentMode = silentMode;
    };

    getBlankMode = (): boolean => {
        return this._blankMode;
    };

    setBlankMode = (blankMode: boolean) => {
        this._blankMode = blankMode;
    };

    getReportContent = (): boolean => {
        return this._reportContent;
    };

    setReportContent = (reportContent: boolean) => {
        this._reportContent = reportContent;
    };

    static serialize = (settings: SettingsModel): any => {
        return {
            ID: settings.getId(),
            Vibration: settings.getVibration(),
            Reminder: settings.getReminder(),
            AutoPlayAudio: settings.getAutoPlayAudio(),
            BackgroundAudio: settings.getBackgroundAudio(),
            AutoSync: settings.getAutoSync(),
            ColorScheme: settings.getColorScheme(),
            SilentMode: settings.getSilentMode(),
            BlankMode: settings.getBlankMode(),
            ReportContent: settings.getReportContent()
        };
    };
}

export default SettingsModel;
